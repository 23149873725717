<template>
    <div id="BuildingPage" class="h-screen">
        <BaseLayout>
            <template #leftButtons>
                <BackToMapButton />
            </template>
            <template #mainContent>
                <div class="p4 mt-4 rounded-xl px-8 w-full">
                    <!--Header-->
                    <header class="w-full shadow-sm body-font">
                        <div class="items-start  pb-6 mx-auto md:flex-row mb-4">
                            <div class="flex justify-between mb-4">
                                <h1 class="flex mb text-xl text-secondary md:mb-0 uppercase font-bold">
                                    {{ $t("message.partners") }}
                                </h1>
                            </div>
                        </div>

                    </header>

                    <div class="flex md:flex-row md:columns-2 flex-col w-full ">
                        <div
                            class="flex flex-row md:flex-col im-sidemenu-contanier rounded-xl mb-4 md:mb-0 flex-wrap md:flex-nowrap w-full md:md:w-auto justify-center">
                            <div class="flex-row mb-4" v-if="state.tab == 1">
                                <label class="tooltip tooltip-bottom">
                                    <a @click="() => { }"
                                        class="btn btn-md btn-secondary btn-circle normal-case text-sm "
                                        :class="{ 'im-button-active': state.tab == 1 }">
                                        <div class="im-btn-icon">
                                            <SVGRenderer svgKey="partner_icon"></SVGRenderer>
                                        </div>
                                        <span class="im-btn-text">{{ $t("message.partners") }}</span>
                                    </a>
                                </label>
                            </div>

                            <div class="flex-row mb-4" v-if="state.tab != 1">
                                <label class="tooltip tooltip-bottom">
                                    <a @click="() => { state.tab = 1; }"
                                        class="btn btn-md btn-secondary btn-circle normal-case text-sm ">
                                        <div class="im-btn-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                                            </svg>
                                        </div>
                                        <span class="im-btn-text">{{ $t("message.back") }}</span>
                                    </a>
                                </label>
                            </div>

                            <div class="flex-row mb-4" v-if="state.tab != 1 && state.partnerId != 0">
                                <label class="tooltip tooltip-bottom">
                                    <a @click="() => { state.tab = 3; }"
                                        class="btn btn-md btn-secondary btn-circle normal-case text-sm ">
                                        <div class="im-btn-icon">
                                            <SVGRenderer svgKey="building2_icon"></SVGRenderer>
                                        </div>
                                        <span class="im-btn-text">{{ $t("message.addresses") }}</span>
                                    </a>
                                </label>
                            </div>

                            <div class="flex-row mb-4" v-if="state.tab != 1 && state.partnerId != 0">
                                <label class="tooltip tooltip-bottom">
                                    <a @click="() => { state.tab = 5; }"
                                        class="btn btn-md btn-secondary btn-circle normal-case text-sm ">
                                        <div class="im-btn-icon">
                                            <SVGRenderer svgKey="partner_icon"></SVGRenderer>
                                        </div>
                                        <span class="im-btn-text">{{ $t("message.contacts") }}</span>
                                    </a>
                                </label>
                            </div>

                            <div class="flex-row mb-4" v-if="User.hasPermission('SettlementAdmin') && state.tab == 1">
                                <label class="tooltip tooltip-bottom">
                                    <a @click="() => { state.tab = 7; }"
                                        class="btn btn-md btn-secondary btn-circle normal-case text-sm ">
                                        <div class="im-btn-icon">
                                            <SVGRenderer svgKey="building3_icon"></SVGRenderer>
                                        </div>
                                        <span class="im-btn-text">{{ $t("message.settlement") }}</span>
                                    </a>
                                </label>
                            </div>


                        </div>

                        <TransitionGroup name="fade">
                            <!-------------------------Partner ------------>


                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 1">
                                <div>
                                    <h2 class="text-xl text-secondary w-full font-bold mb-8">
                                        {{ $t("message.partners") }}
                                    </h2>
                                    <button @click="
                                        state.tab = 2;
                                    state.partnerId = 0;
                                    state.partnerEdit = true;
                                    clearData(state.partnerData);
                                    " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                                        {{ $t("message.addNew") }}
                                    </button>
                                    <hr class="mb-8" />
                                    <div class="im-card-form">
                                        <RobberVueGrid ref="partnerGrid" :config="partnerGridConfigComputed" />
                                    </div>
                                </div>
                            </div>



                            <!------------------ Partner - szerkesztés ---------------->

                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 2">
                                <div>

                                    <h2 class="text-xl text-secondary w-full font-bold">
                                        {{ state.partnerData[0].value }}
                                    </h2>

                                    <div class="im-card-form">
                                        <div v-for="item in state.partnerData" v-bind:key="item.model" class="mb-8">

                                            <div v-if="state.partnerEdit == false">
                                                <ImFormLabel v-if="(item.model != 'taxId' && item.model != 'taxNumber')
                                                    || ((item.model == 'taxId' && state.isPerson == true)
                                                        || ((item.model == 'taxNumber' && state.isPerson == false)))"
                                                    :item="item"></ImFormLabel>
                                            </div>
                                            <div v-if="state.partnerEdit == true">
                                                <ImFormInput v-if="(item.model != 'taxId' && item.model != 'taxNumber')
                                                    || ((item.model == 'taxId' && state.isPerson == true)
                                                        || ((item.model == 'taxNumber' && state.isPerson == false)))"
                                                    v-model="item.value" :title="$t('message.' + item.title)"
                                                    :type="item.type" :valueLabel="item.valueLabel"
                                                    :optionValue="item.optionValue" :endPoint="item.endPoint"
                                                    :displayValue="item.displayValue" :searchable="item.searchable"
                                                    :onchangeMethod="item.onchangeMethod"
                                                    :validationMethod="item.validationMethod">
                                                </ImFormInput>
                                            </div>
                                        </div>

                                        <button v-if="state.partnerEdit == true" @click="savePartner"
                                            class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                                $t("message.save") }}</button>
                                        <button v-if="state.partnerEdit == false" @click="state.partnerEdit = true"
                                            class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                                $t("message.edit") }}</button>
                                    </div>
                                </div>
                            </div>

                            <!-------------------------Cím ------------>


                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 3 && state.partnerId != 0">
                                <div>
                                    <h2 class="text-xl text-secondary w-full font-bold mb-8">
                                        {{ state.partnerId == 0 ? 'Új Partner' : state.partnerData[0].value }} : {{
                                            $t("message.address") }}
                                    </h2>
                                    <button @click="
                                        state.tab = 4;
                                    state.addressId = 0;
                                    state.addressEdit = true;
                                    clearData(state.addressData);
                                    " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                                        {{ $t("message.addNew") }}
                                    </button>
                                    <hr class="mb-8" />
                                    <div class="im-card-form">
                                        <RobberVueGrid ref="partnerGrid" :config="partnerAddressGridConfigComputed" />
                                    </div>
                                </div>
                            </div>



                            <!------------------ Cím - szerkesztés ---------------->
                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 4 && state.partnerId != 0">
                                <div>

                                    <h2 class="text-xl text-secondary w-full font-bold">
                                        {{ state.contactId == 0 ? 'Új kontakt' : state.contactData[0].value == 0 }} : {{
                                            $t("message.address") }}
                                    </h2>
                                    <div class="im-card-form">
                                        <div v-for="item in state.addressData" v-bind:key="item.model" class="mb-8">
                                            <div v-if="state.addressEdit == false && state.addressId != 0">
                                                <ImFormLabel :item="item"></ImFormLabel>
                                            </div>
                                            <div v-if="state.addressEdit == true">
                                                <ImFormInput v-model="item.value" :title="$t('message.' + item.title)"
                                                    :type="item.type" :valueLabel="item.valueLabel"
                                                    :optionValue="item.optionValue" :endPoint="item.endPoint"
                                                    :displayValue="item.displayValue" :searchable="item.searchable"
                                                    :onchangeMethod="item.onchangeMethod"
                                                    :validationMethod="item.validationMethod">
                                                </ImFormInput>
                                            </div>
                                        </div>

                                        <button v-if="state.addressEdit == true" @click="saveAddress"
                                            class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                                $t("message.save") }}</button>
                                        <button v-if="state.addressEdit == false" @click="state.addressEdit = true"
                                            class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                                $t("message.edit") }}</button>
                                    </div>
                                </div>
                            </div>

                            <!-------------------------contact  ------------>


                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 5 && state.partnerId != 0">
                                <div>
                                    <h2 class="text-xl text-secondary w-full font-bold mb-8">
                                        {{ state.partnerData[0].value }} : {{ $t("message.contacts") }}
                                    </h2>
                                    <button @click="
                                        state.tab = 6;
                                    state.contactId = 0;
                                    state.contactEdit = true;
                                    clearData(state.contactData);
                                    " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                                        {{ $t("message.addNew") }}
                                    </button>
                                    <hr class="mb-8" />
                                    <div class="im-card-form">
                                        <RobberVueGrid ref="partnerGrid" :config="partnerContactGridConfigComputed" />
                                    </div>
                                </div>
                            </div>



                            <!------------------ Contact - szerkesztés ---------------->
                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 6 && state.partnerId != 0">
                                <div>

                                    <h2 class="text-xl text-secondary w-full font-bold">
                                        {{ state.partnerData[0].value }} : {{ $t("message.contacts") }}
                                    </h2>
                                    <div class="im-card-form">
                                        <div v-for="item in state.contactData" v-bind:key="item.model" class="mb-8">
                                            <div v-if="state.contactEdit == false ">
                                                <ImFormLabel :item="item"></ImFormLabel>
                                            </div>
                                            <div v-if="state.contactEdit == true ">
                                                <ImFormInput v-model="item.value" :title="$t('message.' + item.title)"
                                                    :type="item.type" :valueLabel="item.valueLabel"
                                                    :optionValue="item.optionValue" :endPoint="item.endPoint"
                                                    :displayValue="item.displayValue" :searchable="item.searchable"
                                                    :onchangeMethod="item.onchangeMethod" :validationMethod="item.validationMethod">
                                                </ImFormInput>
                                            </div>
                                        </div>

                                        <button v-if="state.contactEdit == true "
                                            @click="saveContact"
                                            class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                                $t("message.save") }}</button>
                                        <button v-if="state.contactEdit == false "
                                            @click="state.contactEdit = true"
                                            class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                                $t("message.edit") }}</button>
                                    </div>
                                </div>
                            </div>


                            <!------------------------- SETTLEMENT  ------------>


                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 7 && User.hasPermission('SettlementAdmin')">
                                <div>
                                    <h2 class="text-xl text-secondary w-full font-bold mb-8">
                                        {{ $t("message.settlement") }}
                                    </h2>
                                    <button @click="
                                        state.tab = 8;
                                    state.settlementId = 0;
                                    state.settlementEdit = true;
                                    clearData(state.settlementData);
                                    " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                                        {{ $t("message.addNew") }}
                                    </button>
                                    <hr class="mb-8" />
                                    <div class="im-card-form">
                                        <RobberVueGrid ref="settlementGrid"
                                            :config="partnerSettlementGridConfigComputed" />
                                    </div>
                                </div>
                            </div>



                            <!------------------ SETTLEMENT - EDITOR ---------------->
                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 8 && User.hasPermission('SettlementAdmin')">
                                <div>

                                    <h2 class="text-xl text-secondary w-full font-bold">
                                        {{ $t("message.settlement") }}
                                    </h2>
                                    <div class="im-card-form">
                                        <div v-for="item in state.settlementData" v-bind:key="item.model" class="mb-8">
                                            <div v-if="state.settlementEdit == false ">
                                                <ImFormLabel :item="item"></ImFormLabel>
                                            </div>
                                            <div v-if="state.settlementEdit == true">
                                                <ImFormInput v-model="item.value" :title="$t('message.' + item.title)"
                                                    :type="item.type" :valueLabel="item.valueLabel"
                                                    :optionValue="item.optionValue" :endPoint="item.endPoint"
                                                    :displayValue="item.displayValue" :searchable="item.searchable"
                                                    :onchangeMethod="item.onchangeMethod">
                                                </ImFormInput>
                                            </div>
                                        </div>

                                        <button v-if="state.settlementEdit == true "
                                            @click="saveSettlement"
                                            class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                                $t("message.save") }}</button>
                                        <button v-if="state.settlementEdit == false "
                                            @click="state.settlementEdit = true"
                                            class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                                $t("message.edit") }}</button>
                                    </div>
                                </div>
                            </div>


                        </TransitionGroup>
                    </div>
                </div>
            </template>
        </BaseLayout>
    </div>
</template>

<script setup>

// Components
import BaseLayout from "../layouts/BaseLayout.vue";
import BackToMapButton from "../components/BackToMapButton.vue";
//import ImForm from "../components/ImForm.vue";
import ImFormInput from "../components/ImFormInput.vue";
import ImFormLabel from "../components/ImFormLabel.vue";
import RobberVueGrid from "@/components/RVG/RobberVueGrid.vue";
import SVGRenderer from "../components/SVGRenderer.vue";

//Utils
import User from "../misc/User.js";
import { onMounted, ref, watch, /*onMounted, reactive,*/ computed } from "vue";
import store from "@/misc/vuex-store";
import { useI18n } from "vue-i18n";
import formData from "./PartnerPageComponents/FormData.js";
import partnerUtils from "./PartnerPageComponents/partnerUtils.js";

// RVGs

import partnerGridConfig from "../misc/gridConfigs/partnerPage/partnerGridConfig.js";
import partnerAddressGridConfig from "../misc/gridConfigs/partnerPage/partnerAddressGridConfig.js";
import partnerContactGridConfig from "../misc/gridConfigs/partnerPage/partnerContactGridConfig.js";
import partnerSettlementGridConfig from "../misc/gridConfigs/partnerPage/partnerSettlementGridConfig.js";



/**
 * Setup
 */
const { t } = useI18n();

const language = computed(() => store.getters.getLanguage);

const state = ref({
    tab: 1,
    partnerId: 0,
    partnerEdit: false,
    partnerData: formData.partnerData,
    addressId: 0,
    addressEdit: false,
    addressData: formData.addressData,
    contactId: 0,
    contactEdit: false,
    contactData: formData.contactData,
    settlementId: 0,
    settlementEdit: false,
    settlementData: formData.settlementData,
    isPerson: false
});


onMounted(() => {

    partnerUtils.setup(state)

});

/**
 *  RVGs
 */

const partnerGridConfigComputed = computed(() => {
    return partnerGridConfig(state, t);
});

const partnerAddressGridConfigComputed = computed(() => {
    return partnerAddressGridConfig(state, t);
});

const partnerContactGridConfigComputed = computed(() => {
    return partnerContactGridConfig(state, t);
});

const partnerSettlementGridConfigComputed = computed(() => {
    return partnerSettlementGridConfig(state, t);
});

/**
 *  Watchers
 */


watch(() => state.value.partnerData[1].value,
    () => {
        if (state.value.tab == 2 && state.value.partnerEdit == true) {
            setTimeout(() => {
                state.value.partnerData[2].value =
                    "CHANGECODE###" + state.value.partnerData[1].value;
            }, 700);
        }

    });

watch(() => state.value.partnerData[3].value,
    () => {
        if (state.value.tab == 2 && state.value.partnerEdit == true) {
            setTimeout(() => {
                state.value.isPerson =
                    state.value.partnerData[3].value == "true" ? true : false;
                if (state.value.isPerson == true) {
                    state.value.partnerData[5].value = "";
                } else {
                    state.value.partnerData[4].value = "";
                }
            }, 700);
        }

    });

watch(() => state.value.addressData[1].value,
    () => {
        console.log(state.value.tab)
        console.log(state.value.addressEdit)
        if (state.value.tab == 4 && state.value.addressEdit == true) {
            console.log(state.value.addressData[1].value)
            setTimeout(() => {
                state.value.addressData[3].value =
                    "CHANGECODE###" + state.value.addressData[1].value;
                state.value.addressData[4].value =
                    "CHANGECODE###" + state.value.addressData[1].value;

            }, 700);
        }
    });



watch(
    () => language.value,
    () => {
        //premiseUtils.asyncGet(state, premiseId);
    }
);


/**
 * Savers
 */

function savePartner() {
    partnerUtils.savePartner(state, t)
}

function saveAddress() {
    partnerUtils.saveAddress(state, t)
}

function saveContact() {
    partnerUtils.saveContact(state, t)
}

function saveSettlement() {
    partnerUtils.saveSettlement(state, t)
}

function clearData(vArray) {
    vArray.forEach(element => {
        element.value = "";
    });
}



</script>

<style scoped>
.cardBg {
    background-color: hsl(220, 17.647%, 20%);
}

.im-main-card {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
    padding: 3rem;
    width: 100%;
    max-width: 100%;
    max-height: 75vh;
    overflow: hidden;
}

@media screen and (max-width: 600px) {
    .im-main-card {
        margin-bottom: 30px;
    }


}

.im-main-card-starter {
    animation: 0.5s ease-out 0s 1 scaleupY;
    overflow: auto;
}

.im-main-card-starter div {
    animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
    0% {
        transform-origin: left top;
        transform: scaleY(0);
    }

    100% {
        transform: scaleY(1);
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

.im-sidemenu-contanier {
    animation: 0.5s ease-out 0s 1 scaleupY;
    padding: 1rem;

    margin-right: 2rem;
    height: fit-content;
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

.im-sidemenu-contanier div {
    animation: 1.5s ease-out 0s 1 fadein;
}

.im-card-form {
    padding-top: 2rem;
}

.fade-enter-active {
    transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
    transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    padding: 0;
    width: 0;
    opacity: 0;
}

.im-round-btn {
    border-radius: 9999px;
}



/* button */

.btn-circle {
    transition: width 0.5s;
}


.im-sidemenu-contanier:hover .btn-circle {

    width: 15rem;
}



@keyframes fadein {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

.im-btn-text {
    display: none;
}

.im-sidemenu-contanier:hover .im-btn-text {
    animation: .5s ease-out 0s 1 fadein;
    display: inline;
}

.im-btn-icon {
    display: inline;
}

.im-button-active {
    border-color: lighter hsl(var(--s));
    ;
}

.im-sidemenu-contanier:hover .im-btn-icon {
    display: none;
}
</style>