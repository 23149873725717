
import TenantConfigHandler from '@/misc/TenantConfigHandler';

export default {
  premiseData: [{
    title: "function",
    value: "",
    valueLabel: "",
    model: "premiseFunction",
    type: "option",
    optionValue: "PREMISE_FUNCTION"
  },{
    title: "cremClassification",
    value: "",
    valueLabel: "",
    model: "cremClass",
    type: "option",
    optionValue: "CREM_CLASSIFICATION"
  },{
    title: "identifier",
    value: "",
    model: "identifier",
    type: "text",
  },{
    title: "premiseNumber",
    value: "",
    model: "premiseNumber",
    type: "text",
  },{
    title: "floorArea",
    value: "",
    model: "floorSpace",
    type: "number",
  },{
    title: "isAccessible",
    value: "",
    model: "accessibility",
    type: "checkbox",
  },{
    title: "stairway",
    type: "label",
  },{
    title: "stairwayId",
    value: "",
    model: "stairwayIdentifier",
    type: "text",
  },{
    title: "stairwayType",
    value: "",
    model: "stairwayType",
    type: "option",
    optionValue: "STAIRWAY_TYPE"
  },{
    title: "isSmokeFree",
    value: "",
    model: "smokeFree",
    type: "checkbox",
  },{
    title: "isEscapeRoute",
    value: "",
    model: "fireRoute",
    type: "checkbox",
  },{
    title: "elevator",
    type: "label",
  },{
    title: "numberOfPerson",
    value: "",
    model: "elevatorNumberPersons",
    type: "text",
  },{
    title: "loadCapacity",
    value: "",
    model: "elevatorLoadCapacity",
    type: "text",
  },{
    title: "elevatorType",
    value: "",
    valueLabel: "",
    model: "elevatorType",
    type: "option",
    optionValue: "ELEVATOR_TYPE"
  },{
    title: "isEmergencyElevator",
    value: "",
    model: "emergencyElevator",
    type: "checkbox",
  }],
  legalsitData: [
    {
      title: "legalSituation",
      value: "",
      valueLabel: "",
      model: "legalSituationType",
      type: "option",
      optionValue: "LEGAL_SITUATION",
    },
    {
      title: "legalRelationship",
      value: "",
      valueLabel: "",
      model: "legalRelationshipType",
      type: "option",
      optionValue: "LEGAL_RELATIONSHIP_TYPE",
    },
    {
      title: "ownership",
      value: "",
      model: "ownership",
      type: "text",
    },
    {
      title: "legalRelationshipStart",
      value: "",
      model: "legalRelationshipStart",
      type: "date",
    },
    {
      title: "legalRelationshipEnd",
      value: "",
      model: "legalRelationshipEnd",
      type: "date",
    },
    {
      title: "areaSize",
      value: "",
      model: "squareMeter",
      type: "number",
    },
  ],
  utilizationData: [
    {
      title: "floorSpace",
      value: "",
      model: "floorSpace",
      type: "number",
    },
    {
      title: "legalRelationship",
      value: "",
      model: "legalRelationship",
      type: "option",
      optionValue: "UTILIZATION_LEGAL_RELATIONSHIP",
    },
    {
      title: "legalRelationshipStart",
      value: "",
      model: "legalRelationshipStart",
      type: "date",
    },
    {
      title: "legalRelationshipEnd",
      value: "",
      model: "legalRelationshipEnd",
      type: "date",
    },
    {
      title: "partner",
      value: "",
      model: "partnerId",
      type: "optionapi",
      endPoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner",
      displayValue: "name",
    },
  ],
  meterData: [
    {
      title: "gist",
      value: "",
      model: "gistId",
      optionSearchEndpoint: "gist",
      type: "optionSearchEndpoint",
    },
    {
      title: "meterType",
      value: "",
      model: "type",
      type: "option",
      optionValue: "METER_TYPE",
    },
    {
      title: "publicUtilityType",
      value: "",
      model: "utilityType",
      type: "option",
      optionValue: "METER_UTILITY_TYPE",
    },
    {
      title: "identifier",
      value: "",
      model: "identifier",
      type: "text",
    },
    {
      title: "placement",
      value: "",
      model: "placement",
      type: "text",
    },
    {
      title: "readings",
      value: "",
      model: "readings",
      type: "text",
    },
    {
      title: "readingFrequency",
      value: "",
      model: "readingFrequency",
      type: "option",
      optionValue: "METER_READING_FREQUENCY",
    },
    {
      title: "commType",
      value: "",
      model: "commType",
      type: "option",
      optionValue: "METER_COMM_TYPE",
    },
    {
      title: "expirationDate",
      value: "",
      model: "expirationDate",
      type: "date",
    },
    {
      title: "contractingParty",
      value: "",
      model: "partnerId",
      type: "optionapi",
      endPoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner",
      displayValue: "name",
    },
  ],
};
