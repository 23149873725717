<template>
  <div id="BuildingPage" class="h-screen">
    <BaseLayout>
      <template #mainContent>
        <div class="p4 mt-4 rounded-xl px-8 w-full">
          <!--Header-->
          <header class="w-full shadow-sm body-font">
            <div class="items-start pb-6 mx-auto md:flex-row mb-4">
              <div class="flex justify-between mb-4">
                <div class="flex flex-row">
                  <h1
                    class="flex mb text-xl text-secondary md:mb-0 uppercase font-bold"
                  >
                    {{ $t("message.building") }} -
                    {{
                      state.building?.siteDesignName
                        ? state.building.siteDesignName
                        : "N/A"
                    }}
                  </h1>
                  <span @click="toLevelSelector">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-8 h-8 pl-2 pb-1 text-magenta hover:text-gray-200 hover:cursor-pointer"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
                      />
                    </svg>
                  </span>
                </div>

                <RemUpButton remType="building" />
              </div>
              <hr class="mb-8 mt-2" />
              <div class="flex">
                <button
                  @click="router.push({ name: 'energeticsPage' })"
                  class="flex btn btn-md btn-secondary normal-case text-sm im-round-btn"
                >
                  {{ $t("message.energetics") }}
                </button>
              </div>
            </div>
          </header>

          <div class="flex md:flex-row md:columns-2 flex-col w-full">
            <!--SIDEMENU-->
            <div
              class="flex flex-row md:flex-col im-sidemenu-contanier rounded-xl mb-4 md:mb-0 flex-wrap md:flex-nowrap w-full md:md:w-auto justify-center"
            >
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a
                    @click="
                      () => {
                        state.tab = 1;
                      }
                    "
                    class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 1 }"
                  >
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="premise_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.premises")
                    }}</span>
                  </a>
                </label>
              </div>

              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a
                    @click="
                      () => {
                        state.tab = 2;
                      }
                    "
                    class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 2 }"
                  >
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="legal_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.legalSituations")
                    }}</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a
                    @click="
                      () => {
                        state.tab = 3;
                      }
                    "
                    class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 3 }"
                  >
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="system_settings_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.utilizations")
                    }}</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a
                    @click="
                      () => {
                        state.tab = 4;
                      }
                    "
                    class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 4 }"
                  >
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="meter_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{ $t("message.meters") }}</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a
                    @click="
                      () => {
                        state.tab = 5;
                      }
                    "
                    class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 5 }"
                  >
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="data_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.baseDatas")
                    }}</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a
                    @click="
                      () => {
                        state.tab = 6;
                      }
                    "
                    class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 6 }"
                  >
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="telekom_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.mtResponsibles")
                    }}</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a
                    @click="
                      () => {
                        state.tab = 7;
                      }
                    "
                    class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 7 }"
                  >
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="document_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.documents")
                    }}</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a
                    @click="
                      () => {
                        state.tab = 8;
                      }
                    "
                    class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 8 }"
                  >
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="struct_types_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.structureTypes")
                    }}</span>
                  </a>
                </label>
              </div>
            </div>
            <TransitionGroup name="fade">
              <!--LISTING PREMISES-->
              <div
                v-if="state.tab == 1"
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
              >
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.premises") }}
                  </h2>
                  <button
                    @click="
                      state.tab = 10;
                      state.premiseId = 0;
                      clearData(state.premiseData);
                    "
                    class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8"
                  >
                    {{ $t("message.addNew") }}
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid
                      ref="premiseGrid"
                      :config="premiseGridConfigComputed"
                    />
                  </div>
                  <input
                    type="checkbox"
                    class="modal-toggle"
                    v-model="state.showRemoveConfirmation"
                  />
                  <div class="modal">
                    <div class="modal-box">
                      <h3
                        class="text-lg text-left font-normal hover:text-gray-100 text-gray-100"
                      >
                        {{ $t("message.confirmRemovePremise") }}
                      </h3>
                      <div class="modal-action">
                        <label
                          class="btn btn-sm text-white font-light text-xs"
                          @click="
                            () => {
                              state.showRemoveConfirmation = false;
                            }
                          "
                          >{{ $t("message.deny") }}</label
                        >
                        <label
                          class="btn btn-sm bg-magenta text-white font-light text-xs"
                          @click="
                            () => {
                              deletePremise(state.premiseId);
                              state.showRemoveConfirmation = false;
                            }
                          "
                          >{{ $t("message.confirm") }}</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--Alapadatok-->
              <div
                v-if="state.tab == 5"
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
              >
                <PropertyLists
                  title="buildingBaseData"
                  :building="state.building"
                />
              </div>
              <!--Dokumentumok-->
              <div
                v-if="state.tab == 7"
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
              >
                <PropertyLists title="documents" :building="state.building" />
              </div>
              <!--MT Felelősök-->
              <div
                v-if="state.tab == 6"
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
              >
                <PropertyLists
                  title="mtResponsibles"
                  :building="state.building"
                />
              </div>
              <!--STRUCTURE TYPES-->
              <div
                v-if="state.tab == 8"
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
              >
                <PropertyLists
                  title="structureTypes"
                  :building="state.building"
                />
              </div>
              <!--Jogi helyzet-->
              <div
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                v-if="state.tab == 2"
              >
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.legalSituations") }}
                  </h2>
                  <button
                    @click="
                      state.tab = 9;
                      state.legalsitId = 0;
                      clearData(state.legalsitData);
                    "
                    class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8"
                  >
                    {{ $t("message.addNew") }}
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid
                      ref="legalSituationsGrid"
                      :config="legalSituationsGridConfigComputed"
                    />
                    <input
                      type="checkbox"
                      class="modal-toggle"
                      v-model="state.showRemoveConfirmation"
                    />
                    <div class="modal">
                      <div class="modal-box">
                        <h3
                          class="text-lg text-left font-normal hover:text-gray-100 text-gray-100"
                        >
                          {{ $t("message.deleteLegalSituationConfirm") }}
                        </h3>
                        <div class="modal-action">
                          <label
                            class="btn btn-sm text-white font-light text-xs"
                            @click="
                              () => {
                                state.showRemoveConfirmation = false;
                              }
                            "
                            >{{ $t("message.deny") }}</label
                          >
                          <label
                            class="btn btn-sm bg-magenta text-white font-light text-xs"
                            @click="
                              () => {
                                deleteLegalSituation(state.legalsitIdId);
                                state.showRemoveConfirmation = false;
                              }
                            "
                            >{{ $t("message.confirm") }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--ADD/EDIT PREMISE-->
              <div
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                v-if="state.tab == 10"
              >
                <div>
                  <button
                    class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="state.tab = 1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                      />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ $t("message.premise") + ' ' + state.premiseId }}
                  </h2>
                  <div class="im-card-form">
                    <div
                      v-for="item in state.premiseData"
                      v-bind:key="item.model"
                      class="mb-8"
                    >
                      <ImFormInput
                        v-model="item.value"
                        :title="$t('message.' + item.title)"
                        :type="item.type"
                        :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue"
                        :endPoint="item.endPoint"
                        :displayValue="item.displayValue"
                        :searchable="item.searchable"
                      >
                      </ImFormInput>
                    </div>

                    <button
                      @click="savePremise"
                      class="btn btn-md btn-secondary normal-case text-sm im-round-btn"
                    >
                      {{ $t("message.save") }}
                    </button>
                  </div>
                </div>
              </div>
              <!--ADD/EDIT LEGAL SITUATION-->
              <div
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                v-if="state.tab == 9"
              >
                <div>
                  <button
                    class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="state.tab = 2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                      />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ $t("message.legalSituation") }}
                  </h2>
                  <div class="im-card-form">
                    <div
                      v-for="item in state.legalsitData"
                      v-bind:key="item.model"
                      class="mb-8"
                    >
                      <ImFormInput
                        v-model="item.value"
                        :title="$t('message.' + item.title)"
                        :type="item.type"
                        :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue"
                        :endPoint="item.endPoint"
                        :displayValue="item.displayValue"
                        :searchable="item.searchable"
                      >
                      </ImFormInput>
                    </div>

                    <button
                      @click="saveLegalsit"
                      class="btn btn-md btn-secondary normal-case text-sm im-round-btn"
                    >
                      {{ $t("message.save") }}
                    </button>
                  </div>
                </div>
              </div>

              <!--UTILIZATIONS-->
              <div
                v-if="state.tab == 3"
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
              >
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.utilizations") }}
                  </h2>
                  <button
                    @click="
                      state.tab = 11;
                      state.utilizationId = 0;
                      clearData(state.utilizationData);
                    "
                    class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8"
                  >
                    {{ $t("message.addNew") }}
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid
                      ref="utilizationGrid"
                      :config="utilizationGridConfigComputed"
                    />
                  </div>
                  <input
                    type="checkbox"
                    class="modal-toggle"
                    v-model="state.showRemoveConfirmation"
                  />
                  <div class="modal">
                    <div class="modal-box">
                      <h3
                        class="text-lg text-left font-normal hover:text-gray-100 text-gray-100"
                      >
                        {{ $t("message.deleteUtilizationConfirm") }}
                      </h3>
                      <div class="modal-action">
                        <label
                          class="btn btn-sm text-white font-light text-xs"
                          @click="
                            () => {
                              state.showRemoveConfirmation = false;
                            }
                          "
                          >{{ $t("message.deny") }}</label
                        >
                        <label
                          class="btn btn-sm bg-magenta text-white font-light text-xs"
                          @click="
                            () => {
                              deleteUtilization(state.utilizationId);
                              state.showRemoveConfirmation = false;
                            }
                          "
                          >{{ $t("message.confirm") }}</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <UtilizationsList /> -->
              </div>

              <!--ADD/EDIT UTILIZATION-->
              <div
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                v-if="state.tab == 11"
              >
                <div>
                  <button
                    class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="state.tab = 3"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                      />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ $t("message.editUtilization") }}
                  </h2>
                  <div class="im-card-form">
                    <div
                      v-for="item in state.utilizationData"
                      v-bind:key="item.model"
                      class="mb-8"
                    >
                      <ImFormInput
                        v-model="item.value"
                        :title="$t('message.' + item.title)"
                        :type="item.type"
                        :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue"
                        :endPoint="item.endPoint"
                        :displayValue="item.displayValue"
                        :searchable="item.searchable"
                      >
                      </ImFormInput>
                    </div>

                    <button
                      @click="saveUtilization"
                      class="btn btn-md btn-secondary normal-case text-sm im-round-btn"
                    >
                      {{ $t("message.save") }}
                    </button>
                  </div>
                </div>
              </div>

              <!--METERS-->
              <div
                v-if="state.tab == 4"
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
              >
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.meters") }}
                  </h2>
                  <button
                    @click="
                      state.tab = 12;
                      state.meterId = 0;
                      clearData(state.meterData);
                    "
                    class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8"
                  >
                    {{ $t("message.addNew") }}
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid
                      ref="meterGrid"
                      :config="meterGridConfigComputed"
                    />
                  </div>
                  <input
                    type="checkbox"
                    class="modal-toggle"
                    v-model="state.showRemoveConfirmation"
                  />
                  <div class="modal">
                    <div class="modal-box">
                      <h3
                        class="text-lg text-left font-normal hover:text-gray-100 text-gray-100"
                      >
                        {{ $t("message.deleteMeterConfirm") }}
                      </h3>
                      <div class="modal-action">
                        <label
                          class="btn btn-sm text-white font-light text-xs"
                          @click="
                            () => {
                              state.showRemoveConfirmation = false;
                            }
                          "
                          >{{ $t("message.deny") }}</label
                        >
                        <label
                          class="btn btn-sm bg-magenta text-white font-light text-xs"
                          @click="
                            () => {
                              deleteMeter(state.meterId);
                              state.showRemoveConfirmation = false;
                            }
                          "
                          >{{ $t("message.confirm") }}</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--ADD/EDIT METER-->
              <div
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                v-if="state.tab == 12"
              >
                <div>
                  <button
                    class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="state.tab = 4"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                      />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ $t("message.editMeter") }}
                  </h2>
                  <div class="im-card-form">
                    <div
                      v-for="item in state.meterData"
                      v-bind:key="item.model"
                      class="mb-8"
                    >
                      <ImFormInput
                        v-model="item.value"
                        :title="$t('message.' + item.title)"
                        :type="item.type"
                        :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue"
                        :endPoint="item.endPoint"
                        :displayValue="item.displayValue"
                        :searchable="item.searchable"
                        :optionSearchEndpoint="item.optionSearchEndpoint"
                      >
                      </ImFormInput>
                    </div>

                    <button
                      @click="saveMeter"
                      class="btn btn-md btn-secondary normal-case text-sm im-round-btn"
                    >
                      {{ $t("message.save") }}
                    </button>
                  </div>
                </div>
              </div>
            </TransitionGroup>
          </div>
        </div>
      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
import { onMounted, reactive, computed } from "vue";
import BaseLayout from "../../layouts/BaseLayout.vue";
import SVGRenderer from "../../components/SVGRenderer.vue";
import PropertyLists from "../../components/REM/RemLists.vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import router from "@/misc/router.js";
import Utils from "../../misc/Utils";
import store from "@/misc/vuex-store.js";
import RemUpButton from "../../components/REM/RemUpButton.vue";
import formdata from "./FormData.js";
import legalSituationsGridConfig from "@/misc/gridConfigs/buildingPage/legalSituationsGridConfig.js";
import premiseGridConfig from "@/misc/gridConfigs/buildingPage/premiseGridConfig.js";
import RobberVueGrid from "../../components/RVG/RobberVueGrid.vue";
import ImFormInput from "../../components/ImFormInput.vue";
import buildingUtils from "./buildingUtils.js";
import utilizationsGridConfig from "../../misc/gridConfigs/buildingPage/utilizationsGridConfig.js";
import meterGridConfig from "@/misc/gridConfigs/buildingPage/metersGridConfig.js";

const state = reactive({
  tab: 1,
  building: null,
  legalsitData: formdata.legalsitData,
  legalsitId: 0,
  showRemoveConfirmation: false,
  premiseData: formdata.premiseData,
  premiseId: 0,
  utilizationData: formdata.utilizationData,
  utilizationId: 0,
  meterData: formdata.meterData,
  meterId: 0,
});

const { t } = useI18n();
const route = useRoute();
const buildingId = route.params.buildingId;

const meterGridConfigComputed = computed(() => {
  return meterGridConfig(
    t,
    state,
    buildingId,
    setRemoveConfirmation,
  );
});

const utilizationGridConfigComputed = computed(() => {
  return utilizationsGridConfig(t, state, buildingId, setRemoveConfirmation);
});

const legalSituationsGridConfigComputed = computed(() => {
  return legalSituationsGridConfig(state, t, buildingId, setRemoveConfirmation);
});

const premiseGridConfigComputed = computed(() => {
  return premiseGridConfig(
    state,
    t,
    buildingId,
    buildingUtils.toPremise,
    setRemoveConfirmation
  );
});

const setRemoveConfirmation = (id) => {
  if (state.tab == 2) {
    state.legalsitId = id;
  }
  if (state.tab == 1) {
    state.premiseId = id;
  }
  if (state.tab == 3) {
    state.utilizationId = id;
  }
  if (state.tab == 4){
    state.meterId = id;
  }
  state.showRemoveConfirmation = true;
};

const saveMeter = () => {
  buildingUtils.saveMeter(state, buildingId, t)
}

const saveUtilization = () => {
  buildingUtils.saveUtilization(state, buildingId, t);
};

const saveLegalsit = () => {
  buildingUtils.saveLegalsit(state, t, buildingId);
};

const savePremise = () => {
  buildingUtils.savePremise(state, t, buildingId);
};

const getBuilding = async () => {
  const response = await Utils.fetchBuildingById(buildingId);
  state.building = response;
};

const toLevelSelector = () => {
  const siteDesign = Utils.getSiteDesignById(state.building.siteDesignId)
  
  store.commit("setSelectedSiteDesign", siteDesign);
  store.commit("setSiteDesignSidebarCurrentTab", "levels");
  store.commit("setIsFromRem", true);

  router.push({
    name: "siteDesignSelector",
    params: { siteDesignId: state.building.siteDesignId },
  });
};

const deletePremise = () => {
  buildingUtils.deletePremise(state.premiseId, t);
};

const deleteUtilization = () => {
  buildingUtils.deleteUtilization(state.utilizationId, t);
};

const deleteMeter = () => {
  buildingUtils.deleteMeter(state.meterId, t);
};

function clearData(vArray) {
  vArray.forEach((element) => {
    element.value = "";
  });
}

onMounted(() => {
  getBuilding();
});
</script>

<style scoped>
.cardBg {
  background-color: hsl(220, 17.647%, 20%);
}

.im-main-card {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  padding: 3rem;
  width: 100%;
  max-width: 100%;
  max-height: 75vh;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .im-main-card {
    margin-bottom: 30px;
  }
}

.im-main-card-starter {
  animation: 0.5s ease-out 0s 1 scaleupY;
  overflow: auto;
}

.im-main-card-starter div {
  animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
  0% {
    transform-origin: left top;
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-sidemenu-contanier {
  animation: 0.5s ease-out 0s 1 scaleupY;
  padding: 1rem;

  margin-right: 2rem;
  height: fit-content;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

.im-sidemenu-contanier div {
  animation: 1.5s ease-out 0s 1 fadein;
}

.im-card-form {
  padding-top: 2rem;
}

.fade-enter-active {
  transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
  transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  padding: 0;
  width: 0;
  opacity: 0;
}

.im-round-btn {
  border-radius: 9999px;
}

/* button */

.btn-circle {
  transition: width 0.5s;
}

.im-sidemenu-contanier:hover .btn-circle {
  width: 15rem;
}

@keyframes fadeinbtn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-btn-text {
  display: none;
}

.im-sidemenu-contanier:hover .im-btn-text {
  animation: 0.5s ease-out 0s 1 fadeinbtn;
  display: inline;
}

.im-btn-icon {
  display: inline;
}
.im-button-active {
  border-color: lighter hsl(var(--s));
}

.im-sidemenu-contanier:hover .im-btn-icon {
  display: none;
}
</style>
