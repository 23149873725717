let uniqueField = "premiseGrid";

import TenantConfigHandler from '@/misc/TenantConfigHandler';

export default (state, t, buildingId, toPremiseFn, deletePremiseFn) => {
  return {
    endpoint:
    TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/building/${buildingId}/premises`,
    idkey: "id",
    defaultOrderKey: "id",
    defaultOrderDirection: "desc",
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      // possiblePageSizes: [10, 20, 50, 100],
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false,
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: "server", // server or local/null
      serverGridKey: "worksGridColumns",
    },
    xlsxExport: {
      active: true,
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [{
        key: '30sec',
        refreshInterval: 30,
        title: t('message.rvg-automatic-list-refresh-30-sec'),
        default: true
      }, {
        key: '1min',
        refreshInterval: 60,
        title: t('message.rvg-automatic-list-refresh-1-min'),
      }, {
        key: '5min',
        refreshInterval: 300,
        title: t('message.rvg-automatic-list-refresh-5-min'),
      }]
    },
    mapping: {
      $operations: {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: "50px",
        formatter: {
          type: "Operations",
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: '',
                tooltip: t('message.open'),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12,8V4L20,12L12,20V16H4V8H12Z" /></svg>',
                classList: 'btn btn-secondary btn-sm hover:!opacity-80 btn-circle',
                testValueAttribute: 'buildingForwardButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  toPremiseFn(record.id)
                }
              })

              buttons.push({
                title: '',
                tooltip: t('message.edit'),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12 3L2 12H5V20H10V14H14V15.11L19.43 9.68L12 3M21.04 11.14C20.9 11.14 20.76 11.2 20.65 11.3L19.65 12.3L21.7 14.35L22.7 13.35C22.91 13.14 22.91 12.79 22.7 12.58L21.42 11.3C21.32 11.2 21.18 11.14 21.04 11.14M19.06 12.88L13 18.94V21H15.06L21.11 14.93L19.06 12.88Z" /></svg>',
                classList: 'btn btn-secondary btn-sm hover:!opacity-80 btn-circle',
                testValueAttribute: 'premiseEditButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  console.log(record);
                  
                  state.premiseId = record.id;
                    state.premiseData.forEach((e) => {
                      if (e.type == "label") return;
                      if (e.type == "option") {          
                        e.value = record["basicData"+e.model.charAt(0).toUpperCase()+e.model.slice(1) + "Id"];
                        e.valueLabel = record["basicData"+e.model.charAt(0).toUpperCase()+e.model.slice(1) + "Value"];
                      } else {
                        e.value = record["basicData"+e.model.charAt(0).toUpperCase()+e.model.slice(1)];
                      }
                    });
                    state.tab = 10;
                }
              })

              buttons.push({
                title: "",
                tooltip: t('message.delete'),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" /></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "premiseDeleteButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  deletePremiseFn(record.id);
                },
              });

              return buttons;
            },
          },
        },
      },
      id: {
        title: "ID",
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
      },
      "basicDataPremiseFunctionValue": {
        title: t("message.function"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataPremiseFunctionValue ? record.basicDataPremiseFunctionValue : "N/A";
        },
      },
      "basicDataCremClassValue": {
        title: t("message.cremClassification"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataCremClassValue ? record.basicDataCremClassValue : "N/A";
        },
      },
      "basicDataIdentifier": {
        title: t("message.identifier"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataIdentifier ? record.basicDataIdentifier : "N/A";
        },
      },
      "basicDataPremiseNumber": {
        title: t("message.premiseNumber"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataPremiseNumber ? record.basicDataPremiseNumber : "N/A";
        },
      },
      "basicDataFloorSpace": {
        title: t("message.floorArea"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataFloorSpace ? record.basicDataFloorSpace : "N/A";
        },
      },
      "basicDataStairwayIdentifier": {
        title: t("message.stairwayId"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataStairwayIdentifier ? record.basicDataStairwayIdentifier : "N/A";
        },
      },
      "basicDataStairwayTypeValue": {
        title: t("message.stairwayType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataStairwayTypeValue ? record.basicDataStairwayTypeValue : "N/A";
        },
      },
      "basicDataSmokeFree": {
        title: t("message.isSmokeFree"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Status", //boolean
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataSmokeFree ? t("message.yes") : t("message.no");
        },
      },
      "basicDataFireRoute": {
        title: t("message.isEscapeRoute"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Status", //boolean
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataFireRoute ? t("message.yes") : t("message.no");
        },
      },
      "basicDataElevatorNumberPersons": {
        title: t("message.numberOfPerson"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataElevatorNumberPersons ? record.basicDataElevatorNumberPersons : "N/A";
        },
      },
      "basicDataElevatorLoadCapacity": {
        title: t("message.loadCapacity"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataElevatorLoadCapacity? record.basicDataElevatorLoadCapacity : "N/A";
        },
      },
      "basicDataElevatorTypeValue": {
        title: t("message.elevatorType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataElevatorTypeValue ? record.basicDataElevatorTypeValue : "N/A";
        },
      },
      "basicDataEmergencyElevator": {
        title: t("message.isEmergencyElevator"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Status", //boolean,
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataEmergencyElevator ? t("message.yes") : t("message.no");
        },
      },
      "basicDataAccessibility": {
        title: t("message.isAccessible"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Status", //boolean
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataAccessibility ? t("message.yes") : t("message.no");
        },
      },
    },
  };
};
