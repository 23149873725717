<template>
    <div id="BuildingPage" class="h-screen">
        <BaseLayout>
            <template #mainContent>
                <div class="p4 mt-4 rounded-xl px-8 w-full">
                    <!--Header-->
                    <header class="w-full shadow-sm body-font">
                        <div class="items-start px-6 pb-6 mx-auto md:flex-row mb-8">
                            <div class="flex justify-between">
                                <h1 class="flex mb-4 text-xl text-secondary md:mb-0 uppercase font-bold">
                                    {{ $t("message.building") }} {{ state.buildingId }} / {{ $t("message.energetics") }}
                                </h1>


                                <RemUpButton remType="energetics" />
                            </div>
                        </div>
                    </header>

                    <div class="flex md:flex-row md:columns-2 flex-col w-full">
                        <div class="im-sidemenu-contanier rounded-xl m-0 w-full grid"
                            :class="{ 'im-w-auto': state.tab != 0 }">

                            <div v-for="item in state.basicData" v-bind:key="item.title"
                                class="flex flex-row columns-2 mb-4 justify-between justify-self-center im-sidemnu-row">
                                <ImFormInput v-model="item.value" :title="item.title" type="toggle" class="w-32">
                                </ImFormInput>

                                <label class="tooltip tooltip-bottom self-center">
                                    <a @click="() => { if (item.value == true) state.tab = item.tab; }"
                                        class="btn btn-sm btn-secondary btn-circle normal-case text-sm "
                                        :class="{ 'im-button-active': state.tab == item.tab, 'im-button-disabled': item.value == false }">
                                        <SVGRenderer svgKey="arrow_icon"></SVGRenderer>
                                    </a>
                                </label>
                            </div>

                        </div>
                        <TransitionGroup name="fade">

                            <!---   HŐSZIGETELÉS - Lista  -->
                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 1">
                                <div>
                                    <h2 class="text-xl text-secondary w-full font-bold mb-8">
                                        {{  $t('message.insulation') }}
                                    </h2>
                                    <button @click="
                                        state.tab = 2;
                                    state.insulationId = 0;
                                    clearData(state.insulation);
                                    " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                                        {{ $t("message.addNew") }}
                                    </button>
                                    <hr class="mb-8" />
                                    <div class="im-card-form">
                                        <RobberVueGrid ref="partnerGrid" :config="insulationConfigComputed" />
                                    </div>
                                </div>
                            </div>


                            <!---   HŐSZIGETELÉS   -->
                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 2">
                                <h2 class="text-xl text-secondary w-full font-bold">
                                    {{  $t('message.insulation') }}
                                </h2>
                                <div class="im-card-form">

                                    <div v-for="item in state.insulation" v-bind:key="item.model" class="mb-8">
                                        <div v-if="state.insulationEdit == false && state.insulationId != 0">
                                            <ImFormLabel :item="item"></ImFormLabel>
                                        </div>
                                        <div v-if="state.insulationEdit == true || state.insulationId == 0">
                                            <ImFormInput v-model="item.value" :title="t(`message.${item.title}`)" :type="item.type"
                                                :valueLabel="item.valueLabel" :optionValue="item.optionValue"
                                                :endPoint="item.endPoint" :displayValue="item.endPoint"
                                                :searchable="item.searchable">
                                            </ImFormInput>
                                        </div>
                                    </div>
                                    <button v-if="state.insulationEdit == true || state.insulationId == 0" @click="saveInsulation"
                                        class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                            $t("message.save") }}</button>
                                    <button v-if="state.insulationEdit == false && state.insulationId != 0" @click="state.insulationEdit = true"
                                        class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                            $t("message.edit") }}</button>
                                </div>
                            </div>

                            <!---   NAPELEM - Lista  -->
                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 3">
                                <div>
                                    <h2 class="text-xl text-secondary w-full font-bold mb-8">
                                        {{  $t('message.solarPanel') }}
                                    </h2>
                                    <button @click="
                                        state.tab = 4;
                                    state.solarPanelId = 0;
                                    clearData(state.solarPanel);
                                    " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                                        {{ $t("message.addNew") }}
                                    </button>
                                    <hr class="mb-8" />
                                    <div class="im-card-form">
                                        <RobberVueGrid ref="partnerGrid" :config="solarPanelGridConfigComputed" />
                                    </div>
                                </div>
                            </div>

                            <!---   NAPELEM   -->
                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 4">
                                <h2 class="text-xl text-secondary w-full font-bold">
                                    {{  $t('message.solarPanel') }}
                                </h2>
                                <div class="im-card-form">
                                    <div v-for="item in state.solarPanel" v-bind:key="item.model" class="mb-8">
                                        <div v-if="state.solarPanelEdit == false && state.solarPanelId != 0">
                                            <ImFormLabel :item="item"></ImFormLabel>
                                        </div>
                                        <div v-if="state.solarPanelEdit == true || state.solarPanelId == 0">
                                            <ImFormInput v-model="item.value" :title="t(`message.${item.title}`)" :type="item.type"
                                                :valueLabel="item.valueLabel" :optionValue="item.optionValue"
                                                :endPoint="item.endPoint" :displayValue="item.endPoint"
                                                :searchable="item.searchable">
                                            </ImFormInput>
                                        </div>
                                    </div>
                                    <button v-if="state.solarPanelEdit == true || state.solarPanelId == 0" @click="saveSolarPanel"
                                        class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                            $t("message.save") }}</button>
                                    <button v-if="state.solarPanelEdit == false && state.solarPanelId != 0" @click="state.solarPanelEdit = true"
                                        class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                            $t("message.edit") }}</button>
                                </div>
                            </div>

                            <!---   KÖZPONTI KLÍMA - Lista  -->
                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 5">
                                <div>
                                    <h2 class="text-xl text-secondary w-full font-bold mb-8">
                                        {{ $t('message.centralAirConditioner') }}
                                    </h2>
                                    <button @click="
                                        state.tab = 6;
                                    state.centralAirConditionerId = 0;
                                    clearData(state.centralAirConditioner);
                                    " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                                        {{ $t("message.addNew") }}
                                    </button>
                                    <hr class="mb-8" />
                                    <div class="im-card-form">
                                        <RobberVueGrid ref="partnerGrid"
                                            :config="centralAirConditionerGridConfigComputed" />
                                    </div>
                                </div>
                            </div>

                            <!---   Központi Klíma   -->
                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 6">
                                <h2 class="text-xl text-secondary w-full font-bold">
                                    {{ $t('message.centralAirConditioner') }}
                                </h2>
                                <div class="im-card-form">
                                    <div v-for="item in state.centralAirConditioner" v-bind:key="item.model"
                                        class="mb-8">
                                        <div v-if="state.centralAirConditionerEdit == false && state.centralAirConditionerId != 0">
                                            <ImFormLabel :item="item"></ImFormLabel>
                                        </div>
                                        <div v-if="state.centralAirConditionerEdit == true || state.centralAirConditionerId == 0">
                                            <ImFormInput v-model="item.value" :title="t(`message.${item.title}`)" :type="item.type"
                                                :valueLabel="item.valueLabel" :optionValue="item.optionValue"
                                                :endPoint="item.endPoint" :displayValue="item.endPoint"
                                                :searchable="item.searchable">
                                            </ImFormInput>
                                        </div>
                                    </div>
                                    <button v-if="state.centralAirConditionerEdit == true || state.centralAirConditionerId == 0"
                                        @click="saveCentralAirConditioner"
                                        class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                            $t("message.save") }}</button>
                                    <button v-if="state.centralAirConditionerEdit == false && state.centralAirConditionerId != 0"
                                        @click="state.centralAirConditionerEdit = true"
                                        class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                            $t("message.edit") }}</button>
                                </div>
                            </div>

                            <!---   KÖZPONTI KLÍMA KÜLTÉRI EGYSÉG - Lista  -->
                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 7">
                                <div>
                                    <h2 class="text-xl text-secondary w-full font-bold mb-8">
                                        {{  $t('message.airConditionerOutdoorUnit') }}
                                    </h2>
                                    <button @click="
                                        state.tab = 8;
                                    state.airConditionerOutdoorUnitId = 0;
                                    clearData(state.airConditionerOutdoorUnit);
                                    " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                                        {{ $t("message.addNew") }}
                                    </button>
                                    <hr class="mb-8" />
                                    <div class="im-card-form">
                                        <RobberVueGrid ref="partnerGrid"
                                            :config="airConditionerOutdoorUnitGridConfigComputed" />
                                    </div>
                                </div>
                            </div>

                            <!---   Központi Klíma Kültéri egység  -->
                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 8">
                                <h2 class="text-xl text-secondary w-full font-bold">
                                    {{  $t('message.airConditionerOutdoorUnit') }}
                                </h2>
                                <div class="im-card-form">
                                    <div v-for="item in state.airConditionerOutdoorUnit" v-bind:key="item.model"
                                        class="mb-8">
                                        <div v-if="state.airConditionerOutdoorUnitEdit == false  && state.airConditionerOutdoorUnitId != 0">
                                            <ImFormLabel :item="item"></ImFormLabel>
                                        </div>
                                        <div v-if="state.airConditionerOutdoorUnitEdit == true || state.airConditionerOutdoorUnitId == 0">
                                            <ImFormInput v-model="item.value" :title="t(`message.${item.title}`)" :type="item.type"
                                                :valueLabel="item.valueLabel" :optionValue="item.optionValue"
                                                :endPoint="item.endPoint" :displayValue="item.endPoint"
                                                :searchable="item.searchable">

                                            </ImFormInput>
                                        </div>
                                    </div>
                                    <button v-if="state.airConditionerOutdoorUnitEdit == true || state.airConditionerOutdoorUnitId == 0"
                                        @click="saveAirConditionerOutdoorUnit"
                                        class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                            $t("message.save") }}</button>
                                    <button v-if="state.airConditionerOutdoorUnitEdit == false  && state.airConditionerOutdoorUnitId != 0"
                                        @click="state.airConditionerOutdoorUnitEdit = true"
                                        class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                            $t("message.edit") }}</button>
                                </div>
                            </div>

                            <!---   GENERÁTOR - Lista  -->
                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 9">
                                <div>
                                    <h2 class="text-xl text-secondary w-full font-bold mb-8">
                                        {{ $t('message.fuelCellGeneratorTitle') }}
                                    </h2>
                                    <button @click="
                                        state.tab = 10;
                                    state.pfuelCellGeneratorId = 0;
                                    clearData(state.fuelCellGenerator);
                                    " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                                        {{ $t("message.addNew") }}
                                    </button>
                                    <hr class="mb-8" />
                                    <div class="im-card-form">
                                        <RobberVueGrid ref="partnerGrid"
                                            :config="fuelCellGeneratorGridConfigComputed" />
                                    </div>
                                </div>
                            </div>

                            <!---   Generátor   -->
                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 10">
                                <h2 class="text-xl text-secondary w-full font-bold">
                                   {{ $t('message.fuelCellGeneratorTitle') }}
                                </h2>
                                <div class="im-card-form">
                                    <div v-for="item in state.fuelCellGenerator" v-bind:key="item.model" class="mb-8">
                                        <div v-if="state.fuelCellGeneratorEdit == false && state.fuelCellGeneratorId != 0">
                                            <ImFormLabel :item="item"></ImFormLabel>
                                        </div>
                                        <div v-if="state.fuelCellGeneratorEdit == true || state.fuelCellGeneratorId == 0">
                                            <ImFormInput v-model="item.value" :title="t(`message.${item.title}`)" :type="item.type"
                                                :valueLabel="item.valueLabel" :optionValue="item.optionValue"
                                                :endPoint="item.endPoint" :displayValue="item.endPoint"
                                                :searchable="item.searchable">
                                            </ImFormInput>
                                        </div>
                                    </div>
                                    <button v-if="state.fuelCellGeneratorEdit == true || state.fuelCellGeneratorId == 0" @click="saveFuelCellGenerator"
                                        class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                            $t("message.save") }}</button>
                                    <button v-if="state.fuelCellGeneratorEdit == false && state.fuelCellGeneratorId != 0"
                                        @click="state.fuelCellGeneratorEdit = true"
                                        class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                            $t("message.edit") }}</button>
                                </div>
                            </div>

                            <!---   DIESEL GENERÁTOR - Lista  -->
                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 11">
                                <div>
                                    <h2 class="text-xl text-secondary w-full font-bold mb-8">
                                        {{ $t('message.dieselGeneratorTitle') }}
                                    </h2>
                                    <button @click="
                                        state.tab = 12;
                                    state.dieselGeneratorId = 0;
                                    clearData(state.dieselGenerator);
                                    " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                                        {{ $t("message.addNew") }}
                                    </button>
                                    <hr class="mb-8" />
                                    <div class="im-card-form">
                                        <RobberVueGrid ref="partnerGrid" :config="dieselGeneratorGridConfigComputed" />
                                    </div>
                                </div>
                            </div>

                            <!---   Diesel Generátor   -->
                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 12">
                                <h2 class="text-xl text-secondary w-full font-bold">
                                    {{ $t('message.dieselGeneratorTitle') }}
                                </h2>
                                <div class="im-card-form">
                                    <div v-for="item in state.dieselGenerator" v-bind:key="item.model" class="mb-8">
                                        <div v-if="state.dieselGeneratorEdit == false && state.dieselGeneratorId != 0">
                                            <ImFormLabel :item="item"></ImFormLabel>
                                        </div>
                                        <div v-if="state.dieselGeneratorEdit == true || state.dieselGeneratorId == 0">
                                            <ImFormInput v-model="item.value" :title="t(`message.${item.title}`)" :type="item.type"
                                                :valueLabel="item.valueLabel" :optionValue="item.optionValue"
                                                :endPoint="item.endPoint" :displayValue="item.endPoint"
                                                :searchable="item.searchable">
                                            </ImFormInput>
                                        </div>
                                    </div>
                                    <button v-if="state.dieselGeneratorEdit == true || state.dieselGeneratorId == 0" @click="saveDieselGenerator"
                                        class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                            $t("message.save") }}</button>
                                    <button v-if="state.dieselGeneratorEdit == false"
                                        @click="state.dieselGeneratorEdit == false && state.dieselGeneratorId != 0"
                                        class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                            $t("message.edit") }}</button>
                                </div>
                            </div>

                            <!---   SZÜNETMENTES TÁP - Lista  -->
                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 13">
                                <div>
                                    <h2 class="text-xl text-secondary w-full font-bold mb-8">
                                        {{ $t('message.ups') }}
                                    </h2>
                                    <button @click="
                                        state.tab = 14;
                                    state.upsId = 0;
                                    clearData(state.ups);
                                    " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                                        {{ $t("message.addNew") }}
                                    </button>
                                    <hr class="mb-8" />
                                    <div class="im-card-form">
                                        <RobberVueGrid ref="partnerGrid" :config="upsGridConfigComputed" />
                                    </div>
                                </div>
                            </div>

                            <!---   Szünetmentes táp   -->
                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 14">
                                <h2 class="text-xl text-secondary w-full font-bold">
                                    {{ $t('message.ups') }}
                                </h2>
                                <div class="im-card-form">
                                    <div v-for="item in state.ups" v-bind:key="item.model" class="mb-8">
                                        <div v-if="state.upsEdit == false && state.upsId != 0">
                                            <ImFormLabel :item="item"></ImFormLabel>
                                        </div>
                                        <div v-if="state.upsEdit == true || state.upsId == 0">
                                            <ImFormInput v-model="item.value" :title="t(`message.${item.title}`)" :type="item.type"
                                                :valueLabel="item.valueLabel" :optionValue="item.optionValue"
                                                :endPoint="item.endPoint" :displayValue="item.endPoint"
                                                :searchable="item.searchable">
                                            </ImFormInput>
                                        </div>
                                    </div>
                                    <button v-if="state.upsEdit == true || state.upsId == 0" @click="saveUps"
                                        class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                            $t("message.save") }}</button>
                                    <button v-if="state.upsEdit == false && state.upsId != 0" @click="state.upsEdit = true"
                                        class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                            $t("message.edit") }}</button>
                                </div>
                            </div>

                            <!---   KÖZPONTI FŰTÉS - Lista  -->
                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 15">
                                <div>
                                    <h2 class="text-xl text-secondary w-full font-bold mb-8">
                                        {{ $t('message.centralHeating') }}
                                    </h2>
                                    <button @click="
                                        state.tab = 16;
                                    state.centralHeatingId = 0;
                                    clearData(state.centralHeatingData);
                                    " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                                        {{ $t("message.addNew") }}
                                    </button>
                                    <hr class="mb-8" />
                                    <div class="im-card-form">
                                        <RobberVueGrid ref="partnerGrid" :config="centralHeatingGridConfigComputed" />
                                    </div>
                                </div>
                            </div>

                            <!---   Központi fűtés   -->
                            <div class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                                v-if="state.tab == 16">
                                <h2 class="text-xl text-secondary w-full font-bold">
                                    {{ $t('message.centralHeating') }}
                                </h2>
                                <div class="im-card-form">
                                    <div v-for="item in state.centralHeating" v-bind:key="item.model" class="mb-8">
                                        <div v-if="state.centralHeatingEdit == false && state.centralHeatingId != 0">
                                            <ImFormLabel :item="item"></ImFormLabel>
                                        </div>
                                        <div v-if="state.centralHeatingEdit == true || state.centralHeatingId == 0">
                                            <ImFormInput v-model="item.value" :title="t(`message.${item.title}`)" :type="item.type"
                                                :valueLabel="item.valueLabel" :optionValue="item.optionValue"
                                                :endPoint="item.endPoint" :displayValue="item.endPoint"
                                                :searchable="item.searchable">
                                            </ImFormInput>
                                        </div>
                                    </div>
                                    <button v-if="state.centralHeatingEdit == true || state.centralHeatingId == 0" @click="saveCentralHeating"
                                        class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                            $t("message.save") }}</button>
                                    <button v-if="state.centralHeatingEdit == false && state.centralHeatingId != 0"
                                        @click="state.centralHeatingEdit = true"
                                        class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                                            $t("message.edit") }}</button>
                                </div>
                            </div>
                        </TransitionGroup>



                    </div>
                </div>
            </template>
        </BaseLayout>
    </div>
</template>

<script setup>
// Components
import ImFormInput from "../components/ImFormInput.vue";
import ImFormLabel from "../components/ImFormLabel.vue";
import BaseLayout from "../layouts/BaseLayout.vue";
import RemUpButton from "../components/REM/RemUpButton.vue";
import SVGRenderer from "../components/SVGRenderer.vue";
import RobberVueGrid from "../components/RVG/RobberVueGrid.vue";

//Utils

import { onMounted, ref, watch, /*onMounted, reactive,*/ computed } from "vue";
import { useRoute } from "vue-router";
import store from "@/misc/vuex-store";
import { useI18n } from "vue-i18n";
import FormData from "./EnergeticsPageComponents/FormData";
import energeticsUtils from "./EnergeticsPageComponents/energeticsUtils";

//RVGs

import universalGridConfig from "../misc/gridConfigs/energeticsPage/universalGridConfig";


/**
 * Setup
 */
const { t } = useI18n();
//console.log(t)

const language = computed(() => store.getters.getLanguage);

const route = useRoute();

const state = ref({});
state.value.buildingId = route.params.buildingId;
state.value.tab = 0;
state.value.basicData = {
    insulation: {
        tab: 1,
        title: t('message.insulation'),
        value: false
    }, // hőszigetelés
    solarPanel: {
        tab: 3,
        title:  t('message.solarPanel'),
        value: false
    },
    centralAirConditioner: {
        tab: 5,
        title: t('message.centralAirConditioner'),
        value: false
    },
    airConditionerOutdoorUnit: {
        tab: 7,
        title: t('message.airConditionerOutdoorUnit'),
        value: false
    },
    fuelCellGenerator: {
        tab: 9,
        title: t('message.fuelCellGeneratorTitle'),
        value: false
    },
    dieselGenerator: {
        tab: 11,
        title: t('message.dieselGeneratorTitle'),
        value: false
    },
    ups: {
        tab: 13,
        title: t('message.ups'),
        value: false
    }, // szünetmentes táp
    centralHeating: {
        tab: 15,
        title: t('message.centralHeating'),
        value: false
    }

}

state.value.energeticsId = 0;
state.value.insulation = FormData.insulation;
state.value.insulationId = 0;
state.value.insulationEdit = false;
state.value.solarPanel = FormData.solarPanel
state.value.solarPanelId = 0;
state.value.solarPanelEdit = false;
state.value.centralAirConditioner = FormData.centralAirConditioner
state.value.centralAirConditionerId = 0;
state.value.centralAirConditionerEdit = false;
state.value.airConditionerOutdoorUnit = FormData.airConditionerOutdoorUnit;
state.value.airConditionerOutdoorUnitId = 0;
state.value.airConditionerOutdoorUnitEdit = false;
state.value.fuelCellGenerator = FormData.fuelCellGenerator;
state.value.fuelCellGeneratorId = 0
state.value.fuelCellGeneratorEdit = false;
state.value.dieselGenerator = FormData.dieselGenerator
state.value.dieselGeneratorId = 0
state.value.dieselGeneratorEdit = false;
state.value.ups = FormData.ups
state.value.upsId = 0;
state.value.upsEdit = false;
state.value.centralHeating = FormData.centralHeating
state.value.centralHeatingId = 0;
state.value.centralHeatingEdit = false;

onMounted(() => {
    energeticsUtils.getMainEnergetics(state, t)
});

/**
 *  RVGs
 */
const insulationConfigComputed = computed(() => {
    return universalGridConfig(state, 'insulation', 'insulation', 2, t);
});

const solarPanelGridConfigComputed = computed(() => {
    return universalGridConfig(state, 'solarPanel', 'solar-panel', 4, t);
});


const centralAirConditionerGridConfigComputed = computed(() => {
    return universalGridConfig(state, 'centralAirConditioner', 'central-ac', 8, t);
});

const airConditionerOutdoorUnitGridConfigComputed = computed(() => {
    return universalGridConfig(state, 'airConditionerOutdoorUnit', 'ac-outdoor-unit', 6, t);
});

const fuelCellGeneratorGridConfigComputed = computed(() => {
    return universalGridConfig(state, 'fuelCellGenerator', 'fc-generator', 10, t);
});

const dieselGeneratorGridConfigComputed = computed(() => {
    return universalGridConfig(state, 'dieselGenerator', 'diesel-generator', 12, t);
});

const upsGridConfigComputed = computed(() => {
    return universalGridConfig(state, 'ups', 'ups', 14, t);
});

const centralHeatingGridConfigComputed = computed(() => {
    return universalGridConfig(state, 'centralHeating', 'central-heating', 16, t);
});




/**
 *  Watchers
 */

watch(
    () => language.value,
    () => {
    }
);





/**
 * Savers
 */

function saveInsulation() {
    energeticsUtils.saveUniversal(state, 'insulation', 'insulation', 1, t)
}

function saveSolarPanel() {
    energeticsUtils.saveUniversal(state, 'solarPanel', 'solar-panel', 3, t)
}

function saveCentralAirConditioner() {
    energeticsUtils.saveUniversal(state, 'centralAirConditioner', 'central-ac', 5, t)
}

function saveAirConditionerOutdoorUnit() {
    energeticsUtils.saveUniversal(state, 'airConditionerOutdoorUnit', 'ac-outdoor-unit', 7, t)
}

function saveFuelCellGenerator() {
    energeticsUtils.saveUniversal(state, 'fuelCellGenerator', 'fc-generator', 9, t)
}
function saveDieselGenerator() {
    energeticsUtils.saveUniversal(state, 'dieselGenerator', 'diesel-generator', 11, t)
}
function saveUps() {
    energeticsUtils.saveUniversal(state, 'ups', 'ups', 13, t)
}
function saveCentralHeating() {
    energeticsUtils.saveUniversal(state, 'centralHeating', 'central-heating', 15, t)
}



function clearData(vArray) {
    vArray.forEach(element => {
        element.value = "";
    });
}

</script>

<style scoped>
.cardBg {
    background-color: hsl(220, 17.647%, 20%);
}

.im-main-card {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
    padding: 3rem;
    width: 100%;
    max-width: 100%;
    max-height: 75vh;
    overflow: hidden;
}

.im-main-card-starter {
    animation: 0.5s ease-out 0s 1 scaleupY;
    overflow: auto;
}

.im-main-card-starter div {
    animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
    0% {
        transform-origin: left top;
        transform: scaleY(0);
    }

    100% {
        transform: scaleY(1);
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

@keyframes fadeinbtn {
    0% {
        opacity: 0;
        display: flex;

    }

    50% {
        opacity: 0;

    }

    100% {
        opacity: 100%;

    }
}

@keyframes fadeoutbtn {
    0% {
        opacity: 100%;
        display: flex;

    }

    100% {
        opacity: 0;
        display: none;

    }
}

@keyframes widenbtn {
    0% {
        width: 10rem;
    }

    50% {
        width: 10rem;
    }

    100% {
        width: 18rem;
    }
}

.im-sidemenu-contanier {
    transition: width 0.5s;
    animation: 0.5s ease-out 0s 1 scaleupY;
    padding: 1rem;
    height: fit-content;
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

.im-sidemnu-row {
    width: 14rem;
}

.im-w-auto {
    margin-right: 2rem;
    width: 10rem;
}

.im-w-auto .tooltip-bottom {

    display: none;
}

.im-w-auto:hover {

    width: 18rem;

    .tooltip-bottom {
        animation: 0.5s ease-out 0s 1 fadeinbtn;
        display: flex;
    }
}


.im-sidemenu-contanier div {
    animation: 1.5s ease-out 0s 1 fadein;
}

.im-card-form {
    padding-top: 2rem;
}

.fade-enter-active {
    transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
    transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    padding: 0;
    width: 0;
    opacity: 0;
}

.im-round-btn {
    border-radius: 9999px;
}

/* button */


.im-button-active {
    border-color: lighter hsl(var(--s));
    ;
}

.im-button-disabled {
    border-color: #a6adbb;
    color: #a6adbb;
    background-color: rgba(0, 0, 0, 0);
}
</style>