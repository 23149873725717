import Axios from "@/misc/axios.js";
import toast from "@/misc/toast.js";
import router from "@/misc/router.js";
import TenantConfigHandler from '@/misc/TenantConfigHandler';

export default {
  uploadFile: async function (state, docsInput, t, buildingId) {
    const uri =
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        `/rem/building/${buildingId}/document`;
  
    const formData = new FormData();
    formData.append("mainType", state.selectedMainType);
    formData.append("subType", state.selectedSubType);
    formData.append("document", docsInput.value.files[0]);
    formData.append("name", state.docuName);
    formData.append("dateOfOrigin", state.docuOriginDate);
  
    try {
      await Axios.post(uri, formData);
      (state.docuName = "");
      (state.docuOriginDate = "");
      (state.selectedMainType = "");
      (state.selectedSubType = "");
      (document.getElementById("file_input").value = "");
      toast.success(t("message.successfulSave"));
    } catch (error) {
      console.log(error);
      toast.error(t("message.saveError"));
    }
  },
  deleteMeter: async function (id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/meter/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deleteMeter(id, t);
        },
      });
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      console.log(error);
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  saveMeter: async function (state, buildingId, t) {
    const hermes = {
      buildingId: buildingId,
      gistId: "",
      type: "",
      utilityType: "",
      identifier: "",
      placement: "",
      readings: "",
      readingFrequency: "",
      commType: "",
      expirationDate: "",
      partnerId: ""
    };

    state.meterData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    console.log(hermes);
    

    console.log(hermes);
    if (state.meterId != 0) {
      hermes.id = state.meterId;
      await Axios.patch(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          "/rem/meter/" +
          state.meterId,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveMeter(state, buildingId, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 200) {
            console.log(resp.status);
            
            toast.success(t("message.successfulSave"));
            state.meterData.forEach((e) => {
              e.value = "";
            });
            state.tab = 4;
            state.meterId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    } else {
      await Axios.post(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/meter",
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveMeter(state, buildingId, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 201) {
            toast.success(t("message.successfulSave"));
            state.meterData.forEach((e) => {
              e.value = "";
            });
            state.tab = 4;
            state.utilizationId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },
  deleteUtilization: async function (id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/utilization/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deleteUtilization();
        },
      });
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      console.log(error);
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  saveUtilization: async function (state, buildingId, t) {
    const hermes = {
      buildingId: buildingId,
      floorSpace: "",
      legalRelationship: "",
      legalRelationshipStart: "",
      legalRelationshipEnd: "",
      partnerId: "",
    };

    state.utilizationData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    console.log(hermes);
    if (state.utilizationId != 0) {
      hermes.id = state.utilizationId;
      await Axios.patch(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          "/rem/utilization/" +
          state.utilizationId,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveUtilization(state, buildingId, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 200) {
            toast.success(t("message.successfulSave"));
            state.utilizationData.forEach((e) => {
              e.value = "";
            });
            state.tab = 3;
            state.utilizationId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    } else {
      await Axios.post(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/utilization",
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveUtilization(state, buildingId, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 201) {
            toast.success(t("message.successfulSave"));
            state.utilizationData.forEach((e) => {
              e.value = "";
            });
            state.tab = 3;
            state.utilizationId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },
  deletePremise: async function (id, t) {
    const uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/premise/${id}`;
    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deletePremise(id);
        },
      });
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      console.log(error);
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  savePremise: async function (state, t, buildingId) {
    const hermes = {
      buildingId: buildingId,
      partnerIds: [],
      basicData: {
        premiseFunction: "",
        cremClass: "",
        identifier: "",
        premiseNumber: "",
        floorSpace: "",
        stairwayIdentifier: "",
        stairwayType: "",
        smokeFree: "",
        fireRoute: "",
        elevatorNumberPersons: "",
        elevatorLoadCapacity: "",
        elevatorType: "",
        emergencyElevator: "",
        accessibility: "",
      },
    };

    state.premiseData.forEach((e) => {
      hermes.basicData[e.model] = e.value;
    });

    if (state.premiseId != 0) {
      await Axios.patch(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          "/rem/premise/" +
          state.premiseId,
        hermes,
        {
          onTokenRefreshed: () => {
            this.savePremise(state, t, buildingId);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 200) {
            toast.success(t("message.successfulSave"));
            state.premiseData.forEach((e) => {
              e.value = "";
            });
            state.tab = 1;
            state.premiseId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    } else {
      await Axios.post(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/premise",
        hermes,
        {
          onTokenRefreshed: () => {
            this.savePremise(state, t, buildingId);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 201) {
            toast.success(t("message.successfulSave"));
            state.premiseData.forEach((e) => {
              e.value = "";
            });
            state.tab = 1;
            state.premiseId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },
  saveLegalsit: async function (state, t, buildingId) {
    const hermes = {
      buildingId: buildingId,
      legalSituationType: "",
      legalRelationshipType: "",
      ownership: "",
      legalRelationshipStart: "",
      legalRelationshipEnd: "",
      squareMeter: ""
    };

    state.legalsitData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    if (state.legalsitId != 0) {
      await Axios.patch(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          "/rem/legal-situation/" +
          state.legalsitId,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveLegalsit(state, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 200) {
            toast.success(t("message.successfulSave"));
            state.legalsitData.forEach((e) => {
              e.value = "";
            });
            state.tab = 2;
            state.legalsitId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    } else {
      await Axios.post(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/legal-situation",
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveLegalsit(state, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 201) {
            toast.success(t("message.successfulSave"));
            state.legalsitData.forEach((e) => {
              e.value = "";
            });
            state.tab = 2;
            state.legalsitId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },
  toPremise: function (id) {
    router.push({
      name: "premisePage",
      params: { premiseId: id },
    });
  },
};
