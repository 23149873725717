<template>
  <div>
    <!--DOCUMENTS TABLE-->
    <div v-if="props.title == 'documents'" class="im-card-form">
      <h1 class="text-xl font-bold text-secondary mt-0">
        {{ $t("message.documents") }}
      </h1>
      <div class="flex flex-row gap-3 w-full my-2">
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0 font-bold" for="">
            <span class="label-text-alt">{{ $t("message.name") }}</span>
          </label>
          <input v-model="state.docuName" type="text" class="input input-sm input-bordered w-full" />
        </div>
        <div class="form-control w-full mt-5">
          <label class="flex flex-row gap-2">
            <VueDatePicker format="yyyy MMM dd" :enable-time-picker="false" :time-picker-inline="true"
              :locale="$i18n.locale" :month-change-on-scroll="false" :action-row="{}" :clearable="true" select-text="OK"
              :cancel-text="t('message.cancel')" :teleport="true" model-type="yyyy-MM-dd"
              :placeholder="t('message.dateOfOrigin')" v-model="state.docuOriginDate" dark="true" />
          </label>
        </div>
      </div>
      <div class="flex flex-row gap-3 w-full my-4">
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0" for="mainTypeSelector">
            <span class="label-text-alt">{{ $t("message.mainType") }}</span>
          </label>
          <OptionValueSelector id="mainTypeSelector" v-model="state.selectedMainType" type="DOCUMENT_TYPE" />
        </div>
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0" for="subTypeSelector">
            <span class="label-text-alt">{{ $t("message.subType") }}</span>
          </label>
          <OptionValueSelector id="subTypeSelector" v-model="state.selectedSubType" type="DOCUMENT_SUB_TYPE" />
        </div>
      </div>
      <input
        class="file:p-2 file:bg-gray-800 file:border-none file:hover:cursor-pointer w-full text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mb-8"
        aria-describedby="file_input_help"
        :accept="allowedExtensions" 
        id="file_input"
        type="file" ref="docsInput" />

      <button v-if="building" @click="uploadBuildingFile"
        class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
        {{ $t("message.upload") }}
      </button>
      <button v-if="!building" @click="uploadPropertyFile"
        class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
        {{ $t("message.upload") }}
      </button>
      <hr class="mb-8" />
      <RobberVueGrid v-if="buildingId" ref="documentsGrid" :config="buildingDocumentsGridConfigComputed" />
      <RobberVueGrid v-if="!buildingId" ref="documentsGrid" :config="propertyDocumentsGridConfigComputed" />
      <input type="checkbox" class="modal-toggle" v-model="state.showRemoveConfirmation" />
      <div class="modal">
        <div class="modal-box">
          <h3 class="text-lg text-left font-normal hover:text-gray-100 text-gray-100">
            {{ $t("message.confirmRemovePartner") }}
          </h3>
          <div class="modal-action">
            <label class="btn btn-sm text-white font-light text-xs" @click="() => {
                state.showRemoveConfirmation = false;
              }
              ">{{ $t("message.deny") }}</label>
            <label class="btn btn-sm bg-magenta text-white font-light text-xs" @click="() => {
                deleteDocument(state.rvgDocumentId);
                state.showRemoveConfirmation = false;
              }
              ">{{ $t("message.confirm") }}</label>
          </div>
        </div>
      </div>
      <EditDocumentModal :showModal="modalStatus" :id="state.rvgDocumentId" />
    </div>

    <!--PROPERTY BASIC DATA TABLE-->
    <div v-if="props.title == 'baseData'">
      <h2 class="text-xl font-bold uppercase text-[#c6c6c6]">
        {{ $t("message.baseDatas") }}
      </h2>
      <div class="w-full flex flex-row mt-4">
        <table class="text-sm text-center w-1/2">
          <tbody>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.propertySheetAppellation")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.property?.basicDataPropertySheetAppellation }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.floorArea")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.property?.basicDataFloorArea }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.mtArea")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.property?.basicDataMtArea }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.kng")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.property?.basicDataKng }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.floc")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.property?.basicDataFloc }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.sap")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.property?.basicDataSap
                    ? props.property?.basicDataSap
                    : "null"
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.type")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.property?.basicDataTypeValue
                    ? props.property?.basicDataTypeValue
                    : "null"
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.protection")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.property?.basicDataProtection }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.protectionNote")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.property?.basicDataProtectionNote }}
              </td>
            </tr>
          </tbody>
        </table>

        <table class="text-sm text-center w-1/2">
          <tbody>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.numberDedicated")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.property?.basicDataNumberDedicated }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.numberCanBeSeated")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.property?.basicDataNumberCanBeSeated }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.numberSeated")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.property?.basicDataNumberSeated }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.classification")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.property?.basicDataClassificationValue
                    ? props.property?.basicDataClassificationValue
                    : "null"
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.status")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.property?.basicDataStatusValue
                    ? props.property?.basicDataStatusValue
                    : "null"
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.solarPanel")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.property?.basicDataSolarPanel
                    ? $t("message.yes")
                    : $t("message.no")
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.fuelCellGenerator")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.property?.basicDataFuelCellGenerator
                    ? $t("message.yes")
                    : $t("message.no")
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.dieselGenerator")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.property?.basicDataDieselGenerator
                    ? $t("message.yes")
                    : $t("message.no")
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.energyTradingPartner")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.property?.basicDataEnergyTradingPartner
                    ? $t("message.yes")
                    : $t("message.no")
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!--BUILDING BASIC DATA TABLE-->
    <div v-if="props.title == 'buildingBaseData'">
      <h2 class="text-xl font-bold uppercase text-[#c6c6c6]">
        {{ $t("message.baseDatas") }}
      </h2>
      <div class="w-full flex flex-row mt-4">
        <table class="text-sm text-center w-1/2">
          <tbody>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.floorArea")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataFloorArea
                    ? props.building?.basicDataFloorArea
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.mtArea")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataMtArea
                    ? props.building?.basicDataMtArea
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.mainFunction")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataMainFunctionValue
                    ? props.building?.basicDataMainFunctionValue
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.premiseNumber")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataPremiseNumber
                    ? props.building?.basicDataPremiseNumber
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.isAccessible")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataAccessible
                    ? t("message.yes")
                    : t("message.no")
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.numberDedicated")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataNumberDedicated
                    ? props.building?.basicDataNumberDedicated
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.numberCanBeSeated")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataNumberCanBeSeated
                    ? props.building?.basicDataNumberCanBeSeated
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.numberSeated")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataNumberSeated
                    ? props.building?.basicDataNumberSeated
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.buildingMaxHeight")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataMaxHeight
                    ? props.building?.basicDataMaxHeight
                    : ""
                }}
              </td>
            </tr>
          </tbody>
        </table>

        <table class="text-sm text-center w-1/2">
          <tbody>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.classification")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataStatusValue
                    ? props.building?.basicDataStatusValue
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.paymentObligations")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.paymentObligation
                    ? props.building?.paymentObligation
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.floorsAboveSurface")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataFloorsAboveSurface
                    ? props.building?.basicDataFloorsAboveSurface
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.floorsBelowSurface")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataFloorsBelowSurface
                    ? props.building?.basicDataFloorsBelowSurface
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.attic")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataAttic
                    ? $t("message.yes")
                    : $t("message.no")
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.atticCanBeUsed")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataAtticCanBeUsed
                    ? $t("message.yes")
                    : $t("message.no")
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.sapBuildingId")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataSapValue
                    ? props.building?.basicDataSapValue
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">TCAFM</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataTcafm
                    ? props.building?.basicDataTcafm
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.parcelNumber")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataParcelNumber
                    ? props.building?.basicDataParcelNumber
                    : ""
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!--MT RESPONSIBLES TABLE-->
    <div v-if="props.title == 'mtResponsibles'">
      <h2 class="text-xl font-bold uppercase text-[#c6c6c6]">
        {{ $t("message.mtResponsibles") }}
      </h2>
      <div class="w-full flex flex-row mt-4">
        <table class="text-sm text-center w-full">
          <tbody>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">
                  {{ $t("message.operation") }}
                </span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataOperation
                    ? props.building?.basicDataOperation
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.cleaning")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataCleaner
                    ? props.building?.basicDataCleaner
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.supplies")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataSupplies
                    ? props.building?.basicDataSupplies
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">TUI</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataTui
                    ? props.building?.basicDataTui
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.ownerOfAssets")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataOwnerOfAssets
                    ? props.building?.basicDataOwnerOfAssets
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.assetManager")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicDataAssetManager
                    ? props.building?.basicDataAssetManager
                    : ""
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!--STRUCTURE TYPES TABLE-->
    <div v-if="props.title == 'structureTypes'">
      <h2 class="text-xl font-bold uppercase text-[#c6c6c6]">
        {{ $t("message.structureTypes") }}
      </h2>
      <div class="w-full flex flex-row mt-4">
        <table class="text-sm text-center w-full">
          <tbody>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">
                  {{ $t("message.foundation") }}
                </span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.foundationValue
                    ? props.building?.foundationValue
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.bearingMasonry")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.bearingMasonryValue
                    ? props.building?.bearingMasonryValue
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.slabStructInter")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.slabStructureInterValue
                    ? props.building?.slabStructureInterValue
                    : ""
                }},
                {{
                  props.building?.slabSubStructureInterValue
                    ? props.building?.slabSubStructureInterValue
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.slabStructClosing")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.slabStructureClosingValue
                    ? props.building?.slabStructureClosingValue
                    : ""
                }},
                {{
                  props.building?.slabSubStructureClosingValue
                    ? props.building?.slabSubStructureClosingValue
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.flatRoof")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.flatRoofValue
                    ? props.building?.flatRoofValue
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.roofType")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.roofingValue
                    ? props.building?.roofingValue
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.insulation")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.insulationValue
                    ? props.building?.insulationValue
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.numberOfStairways")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.numberOfStairways
                    ? props.building?.numberOfStairways
                    : ""
                }}
              </td>
            </tr>
            <tr class="border bg-gray-800 border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.numberOfElevators")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.numberOfElevators
                    ? props.building?.numberOfElevators
                    : ""
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!--MAIN PAGE DOCUMENTS-->
    <div class="space-y-6" v-if="props.title == 'documentsMainPage'">
      <!-- <div class="flex flex-row justify-between">
        <h2 class="text-xl uppercase text-secondary font-bold">
          Dokumentumok
        </h2>
        <button
          @click="
            store.commit('showSidebar', {
              type: 'addNewMainPageDocument',
              params: {
                onSave: () => {
                  fetchDocuments();
                },
              },
            })
          "
          class="whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-secondary text-nrgGray shadow hover:bg-nrgGray hover:text-secondary h-9 px-4 py-2 font-bold uppercase"
        >
          Új dokumentum rögzítése
        </button>
      </div> -->
      <div class="flex flex-row gap-3 w-full my-4">
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0 font-bold" for="">
            <span class="label-text-alt">{{ $t("message.name") }}</span>
          </label>
          <input v-model="state.mainPageDocuments.name" type="text" class="input input-sm input-bordered w-full" />
        </div>
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0 font-bold" for="">
            <span class="label-text-alt">{{ $t("message.fileType") }}</span>
          </label>
          <v-select class="border border-gray-700 rounded-lg" :options="state.fileTypes"
            v-model="state.mainPageDocuments.fileType"></v-select>
        </div>
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0 font-bold" for="mainTypeSelector">
            <span class="label-text-alt">{{ $t("message.mainType") }}</span>
          </label>
          <OptionValueSelector id="mainTypeSelector" v-model="state.mainPageDocuments.typeId" type="DOCUMENT_TYPE" />
        </div>
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0 font-bold" for="subTypeSelector">
            <span class="label-text-alt">{{ $t("message.subType") }}</span>
          </label>
          <OptionValueSelector id="subTypeSelector" v-model="state.mainPageDocuments.subTypeId"
            type="DOCUMENT_SUB_TYPE" />
        </div>
      </div>

      <div class="flex flex-row gap-3 w-full my-4">
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0 font-bold" for="">
            <span class="label-text-alt">{{ $t("message.uploader") }}</span>
          </label>
          <SearchOptions v-model="state.mainPageDocuments.uploaderId" type="uploader" />
        </div>

        <div class="form-control w-full mt-5">
          <label class="flex flex-row gap-2">
            <VueDatePicker format="yyyy MMM dd HH:mm" :enable-time-picker="true" :time-picker-inline="true"
              :locale="$i18n.locale" :month-change-on-scroll="false" :action-row="{}" :clearable="true" select-text="OK"
              :cancel-text="t('message.cancel')" :teleport="true" model-type="yyyy-MM-dd HH:mm" :placeholder="t('message.startDate') + ' (' + t('message.upload') + ')'
                " v-model="state.mainPageDocuments.fromDate" dark="true" />
          </label>
        </div>

        <div class="form-control w-full mt-5">
          <label class="flex flex-row gap-2">
            <VueDatePicker format="yyyy MMM dd HH:mm" :enable-time-picker="true" :time-picker-inline="true" locale="hu"
              :month-change-on-scroll="false" :action-row="{}" select-text="OK" :cancel-text="t('message.cancel')"
              :clearable="true" :teleport="true" model-type="yyyy-MM-dd HH:mm" :placeholder="t('message.endDate') + ' (' + t('message.upload') + ')'
                " v-model="state.mainPageDocuments.toDate" dark="true" />
          </label>
        </div>

        <div class="form-control w-full mt-5">
          <label class="flex flex-row gap-2">
            <VueDatePicker format="yyyy MMM dd" :enable-time-picker="false" :time-picker-inline="true" locale="hu"
              :month-change-on-scroll="false" :action-row="{}" select-text="OK" :cancel-text="t('message.cancel')"
              :clearable="true" :teleport="true" model-type="yyyy-MM-dd" :placeholder="t('message.dateOfOrigin')"
              v-model="state.mainPageDocuments.dateOfOrigin" dark="true" />
          </label>
        </div>
      </div>
      <RobberVueGrid v-if="!state.forceRefresh" ref="documentsMainPageGrid"
        :config="documentsMainPageGridConfigComputed" />
      <input type="checkbox" class="modal-toggle" v-model="state.showRemoveConfirmation" />
      <div class="modal">
        <div class="modal-box">
          <h3 class="text-lg text-left font-normal hover:text-gray-100 text-gray-100">
            Biztos benne, hogy törli a dokumentumot?
          </h3>
          <div class="modal-action">
            <label class="btn btn-sm text-white font-light text-xs" @click="() => {
                state.showRemoveConfirmation = false;
              }
              ">{{ $t("message.deny") }}</label>
            <label class="btn btn-sm bg-magenta text-white font-light text-xs" @click="() => {
                deleteDocument(state.rvgDocumentId);
                state.showRemoveConfirmation = false;
              }
              ">{{ $t("message.confirm") }}</label>
          </div>
        </div>
      </div>
      <EditDocumentModal :showModal="modalStatus" :id="state.rvgDocumentId" />
    </div>
    <!-- PREVIEW MODAL -->
    <input type="checkbox" id="document_preview" class="modal-toggle" v-model="state.documentPreview.open" />
      <div class="modal " role="dialog">
        <div class="modal-box im-preview-modal-box">
          <h3 class="text-lg font-bold mb-4">{{ state.documentPreview.title }}</h3>
          <iframe class="w-full " style="height: 80%; " :src="state.documentPreview.url"></iframe>
          <div class="modal-action">
            <label for="document_preview" class="btn"> {{ $t("message.close") }}</label>
          </div>
        </div>
      </div>
  </div>
</template>

<script setup>
import VueDatePicker from "@vuepic/vue-datepicker";
import { onMounted, reactive, ref, computed, watch } from "vue";
import toast from "@/misc/toast.js";
import { useI18n } from "vue-i18n";
import store from "@/misc/vuex-store";
import Utils from "../../misc/Utils";
import axios from "@/misc/axios.js";
import { useRoute } from "vue-router";
import OptionValueSelector from "../OptionValueSelector.vue";
import RobberVueGrid from "@/components/RVG/RobberVueGrid.vue";
import buildingDocumentsGridConfig from "@/misc/gridConfigs/buildingPage/documentsGridConfig.js";
import propertyDocumentsGridConfig from "@/misc/gridConfigs/propertyPage/documentsGridConfig.js";
import documentsMainPageGridConfig from "@/misc/gridConfigs/documentsMainPageGridConfig.js";
import moment from "moment";
import SearchOptions from "../SearchOptions.vue";
import EditDocumentModal from "./EditDocumentModal.vue";
import buildingUtils from "../../pages/BuildingPageComponents/buildingUtils";
import propertyUtils from "../../pages/PropertyPageComponents/propUtils";
import TenantConfigHandler from '@/misc/TenantConfigHandler';

const props = defineProps(["title", "property", "building"]);

const { t } = useI18n();
const route = useRoute();
const propertyId = route.params.propertyId;
const buildingId = route.params.buildingId;

const docsInput = ref("");
const documentsMainPageGrid = ref(null);

const buildingDocumentsGridConfigComputed = computed(() => {
  return buildingDocumentsGridConfig(
    state,
    t,
    buildingId,
    setRemoveConfirmation,
    editDocument,
    downloadDoc,
  );
});

const propertyDocumentsGridConfigComputed = computed(() => {
  return propertyDocumentsGridConfig(
    state,
    t,
    propertyId,
    setRemoveConfirmation,
    editDocument,
    downloadDoc,
  );
});

const documentsMainPageGridConfigComputed = computed(() => {
  return documentsMainPageGridConfig(
    t,
    state.mainPageDocuments.name,
    state.mainPageDocuments.fileType,
    state.mainPageDocuments.uploaderId,
    state.mainPageDocuments.typeId,
    state.mainPageDocuments.subTypeId,
    state.mainPageDocuments.fromDate
      ? moment(state.mainPageDocuments.fromDate)
      : null,
    state.mainPageDocuments.toDate
      ? moment(state.mainPageDocuments.toDate)
      : null,
    state.mainPageDocuments.dateOfOrigin
      ? moment(state.mainPageDocuments.dateOfOrigin)
      : null,
    state.documentPreview,
    editDocument,
    setRemoveConfirmation,
    downloadDoc,
  );
});

const editDocument = async (id) => {
  const response = await Utils.fetchDocumentById(id);
  console.log(response);

  store.commit("setIsModalOpen", true);
  store.commit("setDocumentById", response);
  state.rvgDocumentId = id;
};

const modalStatus = computed(() => {
  return store.getters.getIsModalOpen;
});

const setRemoveConfirmation = (id) => {
  state.rvgDocumentId = id;
  state.showRemoveConfirmation = true;
};

const state = reactive({
  showRemoveConfirmation: false,
  data: [],
  basicData: null,
  selectedMainType: "",
  selectedSubType: "",
  documents: [],
  docuMainType: "",
  docuSubType: "",
  docuName: "",
  docuOriginDate: "",
  uploadAt: null,
  fileTypes: [],
  mainPageDocuments: {
    name: "",
    fileType: "",
    uploaderId: null,
    typeId: null,
    subTypeId: null,
    fromDate: "",
    toDate: "",
    dateOfOrigin: "",
  },
  forceRefresh: false,
  documentPreview: {
    title: "",
    url: "",
    open:false
  },
});

const refreshGrid = () => {
  state.forceRefresh = true;
  setTimeout(() => {
    state.forceRefresh = false;
  }, 100);
};

watch(
  () => state.mainPageDocuments.typeId,
  () => {
    refreshGrid();
  }
);

watch(
  () => state.mainPageDocuments.subTypeId,
  () => {
    refreshGrid();
  }
);

watch(
  () => state.mainPageDocuments.name,
  () => {
    refreshGrid();
  }
);

watch(
  () => state.mainPageDocuments.fileType,
  () => {
    refreshGrid();
  }
);

watch(
  () => state.mainPageDocuments.uploaderId,
  () => {
    refreshGrid();
  }
);

watch(
  () => state.mainPageDocuments.fromDate,
  () => {
    refreshGrid();
  }
);

watch(
  () => state.mainPageDocuments.toDate,
  () => {
    refreshGrid();
  }
);

watch(
  () => state.mainPageDocuments.dateOfOrigin,
  () => {
    refreshGrid();
  }
);

const downloadDoc = (id) => {
  axios
    .get(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/document/${id}/download`,
      {
        responseType: "blob",
      }
    )
    .then((response) => {
      var url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.data.type,
        })
      );
      var link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", Utils.parseContentDispositionToFileName(response.headers["content-disposition"]));
      document.body.appendChild(link);
      link.click();
    });
};

const uploadBuildingFile = async () => {
  buildingUtils.uploadFile(state, docsInput, t, buildingId);
};

const uploadPropertyFile = async () => {
  propertyUtils.uploadFile(state, docsInput, t, propertyId);
};

const deleteDocument = async (id) => {
  const uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/document/${id}`;
  try {
    await axios.delete(uri, {
      onTokenRefreshed: () => {
        deleteDocument(id);
      },
    });
    toast.success(t("message.deleteSuccessful"));
  } catch (error) {
    console.log(error);
    toast.error(t("message.deleteNotSuccessful"));
  }
};

const allowedExtensions = computed(()=>{
  return store.getters.getAllowedExtensions
})

const filterFileTypes = computed(()=>{
  return store.getters.getAllowedFileFilters
})
const fillFileTypes = () =>{
  state.fileTypes = filterFileTypes
  console.log(state.fileTypes);
}

onMounted(() => {
  fillFileTypes()
});
</script>

<style scoped>
.im-main-card {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  padding: 3rem;
  width: 100%;
  max-width: 100%;
  max-height: 75vh;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .im-main-card {
    margin-bottom: 30px;
  }
}

.im-main-card-starter {
  animation: 0.5s ease-out 0s 1 scaleupY;
  overflow: auto;
}

.im-main-card-starter div {
  animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
  0% {
    transform-origin: left top;
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-sidemenu-contanier {
  animation: 0.5s ease-out 0s 1 scaleupY;
  padding: 1rem;

  margin-right: 2rem;
  height: fit-content;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

.im-sidemenu-contanier div {
  animation: 1.5s ease-out 0s 1 fadein;
}

.im-card-form {
  padding-top: 2rem;
}

.fade-enter-active {
  transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
  transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  padding: 0;
  width: 0;
  opacity: 0;
}

.im-round-btn {
  border-radius: 9999px;
}

/* button */

.btn-circle {
  transition: width 0.5s;
}

.im-sidemenu-contanier:hover .btn-circle {
  width: 15rem;
}

@keyframes fadeinbtn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-btn-text {
  display: none;
}

.im-sidemenu-contanier:hover .im-btn-text {
  animation: 0.5s ease-out 0s 1 fadeinbtn;
  display: inline;
}

.im-btn-icon {
  display: inline;
}

.im-button-active {
  border-color: lighter hsl(var(--s));
}

.im-sidemenu-contanier:hover .im-btn-icon {
  display: none;
}

.im-preview-modal-box{
  width: 75%;
  min-width: 75%;
  height: 75%;
}
</style>
