export default {
  insulation: [
    {
      title: "date",
      value: "",
      valueLabel: "",
      model: "date",
      type: "date", 
    },
    {
      title: "material",
      value: "",
      model: "material",
      type: "option",
      optionValue: "INSULATION_MATERIAL",
    },
    {
      title: "note",
      value: "",
      model: "note",
      type: "text",
    }
  ],
  solarPanel: [
    {
      title: "manufacturer",
      value: "",
      model: "manufacturer",
      type: "text",
    },
    {
      title: "guarantee",
      value: "",
      model: "guarantee",
      type: "text",
    },
    {
      title: "powerProduced",
      value: "",
      model: "powerProduced",
      type: "text",
    },
    {
      title: "annualPerformance",
      value: "",
      model: "annualPerformance",
      type: "text",
    },
    {
      title: "performanceTransfer",
      value: "",
      model: "performanceTransfer",
      type: "text",
    },
    {
      title: "accounting",
      value: "",
      model: "accounting",
      type: "text",
    },
    {
      title: "number",
      value: "",
      model: "number",
      type: "number",
    },
    {
      title: "performance",
      value: "",
      model: "performance",
      type: "text",
    },
    {
      title: "installDate",
      value: "",
      model: "installDate",
      type: "date",
    },
    {
      title: "inverterPerformance",
      value: "",
      model: "inverterPerformance",
      type: "text",
    },
    {
      title: "inverterManufacturer",
      value: "",
      model: "inverterManufacturer",
      type: "text",
    },
    {
      title: "inverterType",
      value: "",
      model: "inverterType",
      type: "text",
    },
  ],
  centralAirConditioner: [
    {
      title: "isCentral",
      value: "",
      model: "central",
      type: "checkbox",
    },
    {
      title: "manufacturer",
      value: "",
      model: "manufacturer",
      type: "text",
    },
    {
      title: "setPerPremise",
      value: "",
      model: "setPerPremise",
      type: "checkbox",
    },
    {
      title: "controlled",
      value: "",
      model: "controlled",
      type: "checkbox",
    },
    {
      title: "maxEnergyIntake",
      value: "",
      model: "maxEnergyIntake",
      type: "number",
    },
    {
      title: "cooling",
      value: "",
      model: "cooling",
      type: "checkbox",
    },
    {
      title: "coolingPerformance",
      value: "",
      model: "coolingPerformance",
      type: "number",
    },
    {
      title: "heating",
      value: "",
      model: "heating",
      type: "checkbox",
    },
    {
      title: "heatingPerformance",
      value: "",
      model: "heatingPerformance",
      type: "number",
    },
    {
      title: "installDate",
      value: "",
      model: "installDate",
      type: "date",
    },
    {
      title: "maintenanceCycle",
      value: "",
      model: "maintenanceCycle",
      type: "text",
    },
    {
      title: "yearOfManufacture",
      value: "",
      model: "yearOfManufacture",
      type: "number",
    },
    {
      title: "guarantee",
      value: "",
      model: "guarantee",
      type: "text",
    },
    {
      title: "liquidCoolerCoolingPerformance",
      value: "",
      model: "liquidCoolerCoolingPerformance",
      type: "number",
    },
    {
      title: "liquidCoolerMaxElectricPerformance",
      value: "",
      model: "liquidCoolerMaxElectricPerformance",
      type: "number",
    },
  ],
  airConditionerOutdoorUnit: [
    {
      title: "name",
      value: "",
      model: "name",
      type: "text",
    },
    {
      title: "manufacturer",
      value: "",
      model: "manufacturer",
      type: "text",
    },
    {
      title: "energyIntake",
      value: "",
      model: "energyIntake",
      type: "text",
    },
    {
      title: "cooling",
      value: "",
      model: "cooling",
      type: "checkbox",
    },
    {
      title: "coolingPerformance",
      value: "",
      model: "coolingPerformance",
      type: "text",
    },
    {
      title: "heating",
      value: "",
      model: "heating",
      type: "checkbox",
    },
    {
      title: "heatingPerformance",
      value: "",
      model: "heatingPerformance",
      type: "text",
    },
    {
      title: "installDate",
      value: "",
      model: "installDate",
      type: "date",
    },
    {
      title: "maintenanceCycle",
      value: "",
      model: "maintenanceCycle",
      type: "text",
    },
    {
      title: "yearOfManufacture",
      value: "",
      model: "yearOfManufacture",
      type: "number",
    },
    {
      title: "guarantee",
      value: "",
      model: "guarantee",
      type: "text",
    },
    {
      title: "liquidCoolerCoolingPerformance",
      value: "",
      model: "liquidCoolerCoolingPerformance",
      type: "text",
    },
    {
      title: "liquidCoolerMaxElectricPerformance",
      value: "",
      model: "liquidCoolerMaxElectricPerformance",
      type: "text",
    },
  ],
  fuelCellGenerator: [
    {
      title: "type",
      value: "",
      model: "type",
      type: "text",
    },
    {
      title: "propellant",
      value: "",
      model: "propellant",
      type: "text",
    },
    {
      title: "performance",
      value: "",
      model: "performance",
      type: "text",
    },
    {
      title: "useOfGeneratedHeat",
      value: "",
      model: "useOfGeneratedHeat",
      type: "text",
    },
    {
      title: "installDate",
      value: "",
      model: "installDate",
      type: "date",
    },
    {
      title: "guarantee",
      value: "",
      model: "guarantee",
      type: "text",
    },
  ],
  dieselGenerator: [
    {
      title: "performance",
      value: "",
      model: "performance",
      type: "text",
    },
    {
      title: "offGrid",
      value: "",
      model: "offGrid",
      type: "checkbox",
    },
    {
      title: "installDate",
      value: "",
      model: "installDate",
      type: "date",
    },
    {
      title: "tankSize",
      value: "",
      model: "tankSize",
      type: "text",
    },
  ],
  ups: [
    {
      title: "batteryInstallDate",
      value: "",
      model: "batteryInstallDate",
      type: "date",
    },
    {
      title: "nextMaintenance",
      value: "",
      model: "nextMaintenance",
      type: "text",
    },
    {
      title: "expiration",
      value: "",
      model: "expiration",
      type: "date",
    },
    {
      title: "manufacturer",
      value: "",
      model: "manufacturer",
      type: "text",
    },
    {
      title: "guarantee",
      value: "",
      model: "guarantee",
      type: "text",
    },
    {
      title: "maintainedBy",
      value: "",
      model: "maintainedBy",
      type: "text",
    },
    {
      title: "energyIntake",
      value: "",
      model: "energyIntake",
      type: "text",
    },
  ],
  centralHeating: [
    {
      title: "type",
      value: "",
      model: "type",
      type: "option",
      optionValue: "CENTRAL_HEATING_TYPE",
    },
    {
      title: "canBePhased",
      value: "",
      model: "canBePhased",
      type: "checkbox",
    },
    {
      title: "manufacturer",
      value: "",
      model: "manufacturer",
      type: "text",
    },
    {
      title: "setPerPremise",
      value: "",
      model: "setPerPremise",
      type: "checkbox",
    },
    {
      title: "energyIntake",
      value: "",
      model: "energyIntake",
      type: "text",
    },
    {
      title: "heatingPerformance",
      value: "",
      model: "heatingPerformance",
      type: "text",
    },
    {
      title: "installDate",
      value: "",
      model: "installDate",
      type: "date",
    },
    {
      title: "maintenanceCycle",
      value: "",
      model: "maintenanceCycle",
      type: "text",
    },
    {
      title: "yearOfManufacture",
      value: "",
      model: "yearOfManufacture",
      type: "number",
    },
    {
      title: "guarantee",
      value: "",
      model: "guarantee",
      type: "text",
    },
  ]

};
