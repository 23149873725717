import Axios from "@/misc/axios";
import toast from "@/misc/toast.js";
import { watchEffect /*onMounted, reactive,*/ } from "vue";
import TenantConfigHandler from "@/misc/TenantConfigHandler";

export default {
  // setup scripts

  asyncGet: async function (state, premiseId, formData) {
    const response = await Axios.get(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/premise/" +
        premiseId,
      {
        onTokenRefreshed: () => {
          this.asyncGet();
        },
      }
    );
    if (response && response.data) {
      state.value.orig = response.data;

      this.getOptions(
        response.data,
        state.value.basicData,
        formData.basicData,
        true
      );
      this.getOptions(
        response.data,
        state.value.staircaseData,
        formData.basicData,
        true
      );
      this.getOptions(
        response.data,
        state.value.elevatorData,
        formData.elevatorData,
        true
      );

      watchEffect(() => {
        if (state.value.basicData[0]) {
          state.value.isStaire = state.value.basicData[0].value == 216;
          state.value.isElevator = state.value.basicData[0].value == 217;
        }
      });
    }

    await Axios.get(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/premise/" +
        premiseId +
        "/special-data",
      {
        onTokenRefreshed: () => {
          this.asyncGet();
        },
      }
    ).then((resp) => {
      if (resp && resp.data) {
        this.getOptions(
          resp.data,
          state.value.specialData,
          formData.specialData,
          true
        );
      }
    });

    await Axios.get(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/premise/" +
        premiseId +
        "/partners",
      {
        onTokenRefreshed: () => {
          this.asyncGet();
        },
      }
    ).then((resp) => {
      if (resp && resp.data) {
        resp.data.content.forEach((e) => {
          state.value.partnerList.push(e.id);
        });
      }
    });

    await Axios.get(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/premise/" +
        premiseId +
        "/cooling-heating-device",
      {
        onTokenRefreshed: () => {
          this.asyncGet();
        },
      }
    ).then((resp) => {
      if (resp && resp.data) {
        state.value.coolingHeatingDeviceData.forEach((e) => {
          if (e.type == "label") {
            return;
          }
          if (e.type == "option") {
            if (resp.data[e.model + "Id"]) {
              e.value = resp.data[e.model + "Id"];
              e.valueLabel = resp.data[e.model + "Value"];
            }
          } else {
            e.value = resp.data[e.model];
          }
        });
      }
    });
  },
  getOptions: async function (
    source,
    target,
    schema,
    isThereWeirdFormattingForOption
  ) {
    schema.forEach((e) => {
      if (e.type == "label") {
        return;
      }

      
      if (e.type == "option" && isThereWeirdFormattingForOption == true) {
        if (source[e.model + "Id"]) {
          e.value = source[e.model + "Id"];
          e.valueLabel = source[e.model + "Value"];
        }
      } else {
        e.value = source[e.model];
      }
      
    });
  },

  //savers
  asyncSavePremise: async function (state, premiseId, t) {
    const hermes = {
      buildingId: state.value.orig.buildingId,
      basicData: {},
      partnerIds: state.value.partnerList,
      //coolingHeatingDevice: state.value.orig.coolingHeatingDevice
    };
    state.value.basicData.forEach((e) => {
      if (e.type == "label") return;
      let model = e.model.replace("basicData", "");
      model = model.charAt(0).toLowerCase() + model.slice(1);
      hermes.basicData[model] = e.value;
    });

    state.value.staircaseData.forEach((e) => {
      let model = e.model.replace("basicData", "");
      model = model.charAt(0).toLowerCase() + model.slice(1);
      hermes.basicData[model] = e.value;
    });
    state.value.elevatorData.forEach((e) => {
      let model = e.model.replace("basicData", "");
      model = model.charAt(0).toLowerCase() + model.slice(1);
      hermes.basicData[model] = e.value;
    });

    Axios.patch(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/premise/" +
        premiseId,
      hermes,
      {
        onTokenRefreshed: () => {
          this.asyncSavePremise(state, premiseId, t);
        },
      }
    ).then(function (resp) {
      if (resp) {
        if (resp.status == 200) {
          toast.success(t("message.successfulSave"));
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      } else {
        toast.error(t("message.notSuccessfulSave"));
      }
    });
  },
  asyncSavePremiseSpecialData: async function (state, premiseId, t) {
    const hermes = {};
    state.value.specialData.forEach((e) => {
      if (e.type == "label") return;
      hermes[e.model] = e.value;
    });

    //const hermes = state.value.orig.specialData;

    Axios.patch(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/premise/" +
        premiseId +
        "/special-data",
      hermes,
      {
        onTokenRefreshed: () => {
          this.asyncSavePremise(state, premiseId, t);
        },
      }
    ).then(function (resp) {
      if (resp) {
        if (resp.status == 200) {
          toast.success(t("message.successfulSave"));
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      } else {
        toast.error(t("message.notSuccessfulSave"));
      }
    });
  },
  uploadFile: async function (state, documentFile, premiseId, t) {
    const formData = new FormData();

    formData.append("mainType", state.value.documentSetup.documentType);
    formData.append("subType", state.value.documentSetup.documentSubType);
    formData.append("document", documentFile.value.files[0]);
    formData.append("name", state.value.documentSetup.documentName);
    formData.append(
      "dateOfOrigin",
      state.value.documentSetup.documentDateOfOrigin
    );

    await Axios.post(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        `/rem/premise/${premiseId}/document`,
      formData
    ).then((resp) => {
      if (resp) {
        if (resp.status == 200) {
          toast.success(t("message.successfulSave"));
          documentFile.value = "";
          state.value.documentSetup = {
            documentName: "",
            documentDateOfOrigin: "",
            documentType: "",
            documentSubType: "",
            documentTypeLabel: "",
            documentSubTypeLabel: "",
          };
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      } else {
        toast.error(t("message.notSuccessfulSave"));
      }
    });
  },
  deleteDocument: async function (id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/document/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deleteDocument(id, t);
        },
      });
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  saveLegalsit: async function (state, premiseId, t) {
    const hermes = {
      legalSituationType: "",
      legalRelationshipType: "",
      ownership: "",
      legalRelationshipStart: "",
      legalRelationshipEnd: "",
      squareMeter: "",
    };

    state.value.legalsitData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    hermes.premiseId = premiseId;

    this.patchPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/legal-situation",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/legal-situation/" +
        state.value.legalsitId,
      hermes,
      "legalsit",
      2,
      state,
      t
    );
  },
  saveMeter: async function (state, premiseId, t) {
    const hermes = {};

    state.value.meterData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    hermes.premiseId = premiseId;

    this.patchPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/meter",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/meter/" +
        state.value.meterId,
      hermes,
      "meter",
      11,
      state,
      t
    );
  },
  saveUtilization: async function (state, premiseId, t) {
    const hermes = {
      premiseId: premiseId,
      floorSpace: "",
      legalRelationship: "",
      legalRelationshipStart: "",
      legalRelationshipEnd: "",
      partnerId: "",
    };

    state.value.utilizationData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    if (state.value.utilizationId != 0) {
      hermes.id = state.value.utilizationId;
    }

    this.patchPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/utilization",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/utilization/" +
        state.value.utilizationId,
      hermes,
      "utilization",
      3,
      state,
      t
    );
  },

  saveCoolingHeatingDevice: async function (state, premiseId, t) {
    const hermes = {};

    state.value.coolingHeatingDeviceData.forEach((e) => {
      
      
      hermes[e.model] = e.value;
      console.log(hermes[e.model], e.value);
    });

    //console.log(hermes)
    try {
      await Axios.post(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          `/rem/premise/${premiseId}/cooling-heating-device`,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveCoolingHeatingDevice(state, premiseId, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 200) {
            toast.success(t("message.successfulSave"));
           
            state.value.tab = 13;
            state.value.saveCoolingHeatingDeviceId = 0;
            state.value.coolingHeatingDeviceEdit = false;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    } catch (e) {
      toast.error(t("message.notSuccessfulSave"));
    }
  },
  savePartner: async function (state, premiseId, t) {
    const hermes = {
      partnerMainType: 0,
      partnerSubType: 0,
      name: "",
      taxId: "",
      taxNumber: "",
      partnerAddresses: [],
    };

    state.value.partnerData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    if (state.value.partnerId != 0) {
      hermes.id = state.value.partnerId;
    }

    this.patchPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner",
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/partner/" +
        state.value.partnerId,
      hermes,
      "partner",
      9,
      state,
      t
    );
  },

  patchPost: async function (
    postendpoint,
    patchendpoint,
    hermes,
    schema,
    tab,
    state,
    t
  ) {
    if (state.value[`${schema}Id`] != 0) {
      try {
        await Axios.patch(patchendpoint, hermes, {
          onTokenRefreshed: () => {
            this.patchPost(
              postendpoint,
              patchendpoint,
              hermes,
              schema,
              tab,
              state,
              t
            );
          },
        }).then((resp) => {
          if (resp && resp.status == 200) {
            toast.success(t("message.successfulSave"));
            state.value[`${schema}Data`].forEach((e) => {
              e.value = "";
            });

            state.value.tab = tab;
            state.value[`${schema}Id`] = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        });
      } catch (e) {
        toast.error(t("message.notSuccessfulSave"));
      }
    } else {
      try {
        await Axios.post(postendpoint, hermes, {
          onTokenRefreshed: () => {
            this.patchPost(
              postendpoint,
              patchendpoint,
              hermes,
              schema,
              tab,
              state,
              t
            );
          },
        }).then((resp) => {
          if (resp && resp.status == 201) {
            toast.success(t("message.successfulSave"));
            state.value[`${schema}Data`].forEach((e) => {
              e.value = "";
            });

            state.value.tab = tab;
            state.value[`${schema}Id`] = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        });
      } catch (e) {
        toast.error(t("message.notSuccessfulSave"));
      }
    }
  },
};
