<template>
  <div id="VocabularyAdminEditorPage" class="h-screen max-h-screen">
    <BaseLayout>
      <template #leftButtons>
        <BackToMapButton />
      </template>
      <template #mainContent>

        <div class=" h-full max-h-full mx-auto px-2 overflow-y-auto k">
          <h2 class="text-lg font-sans font-semibold uppercase text-white my-8">{{ $t('message.optionEditorPage') }}
          </h2>
          <div class="my-4 ">

            <div class="input-group input-groupsm">
              <input class="input input-bordered " type="text" v-model="state.query"
                :placeholder="$t('message.optionValue')">

              <button class="btn btn-secondary">{{ $t('message.search') }}</button>
            </div>
          </div>

          <div class="my-6 h-full max-h-80">
            <table class="table table-xs table-pin-cols table-pin-rows table-auto w-full ">
              <thead>
                <th class="text-white text-base">{{ $t('message.name') }}</th>
                <th class="text-white text-base">{{ $t('message.type') }}</th>
                <!--<th class="text-white text-base">Módosítás</th> -->
                <th class="text-white text-base">{{ $t('message.optionsNumber') }}</th>
                <th class="text-white text-base">{{ $t('message.actions') }}</th>
              </thead>

              <VocabTableRow v-for="item in state.options" v-bind:key="item.translatedValue + item.type" :item="item">
              </VocabTableRow>

            </table>
          </div>
        </div>

      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
import BaseLayout from '../layouts/BaseLayout.vue'

import BackToMapButton from '../components/BackToMapButton.vue'
import { computed, watch, onMounted, reactive } from 'vue';
import VocabTableRow from './VocabularyAdminEditorComponents/VocabTableRow.vue';
import Axios from '@/misc/axios';
import store from '@/misc/vuex-store'
import TenantConfigHandler from '@/misc/TenantConfigHandler';


const state = reactive({
  query: "",
});

const language = computed(() => store.getters.getLanguage);

onMounted(() => {
  asyncGet();
})

watch(() => state.query, (newValue, oldValue) => {
  if (newValue == "") {
    state.options = state.optionsRepo;
  } else if (newValue != oldValue) {
    state.options = state.optionsRepo.filter(elem => (elem.translatedValue.toLowerCase()).includes(newValue.toLowerCase()));

  }
}
);

watch(() => language.value, () => {
  asyncGet();
}
);


async function asyncGet() {
  const response = await Axios.get(TenantConfigHandler.getVariable("ADMINV2_API_URL") + '/rem/option/option-types?Accept-Language=' + language.value, {
    onTokenRefreshed: () => {
      asyncGet();
    }
  });
  if (response && response.data) {
    //console.log(response.data);
    state.options = [];
    state.options = response.data;

    state.options.forEach((element/*, i*/) => {
      //element.modificationTypes = ["DELETE", "MODIFY"];

      element.newOption = "";
      element.newOptionDelete = false;
      element.optionValueAdminDtos = [],
        element.deletableOptionValues = []
      element.toDeleteOptions = [];
      Axios.get(TenantConfigHandler.getVariable("ADMINV2_API_URL") + "/rem/option/" + element.type + "/option-values", {
        onTokenRefreshed: () => {
          asyncGet();
        }
      }).then(function (resp) {
        if (resp && resp.data) {

          element.optionValueAdminDtos = resp.data;
          element.optionValueAdminDtos.forEach((ovElement) => {
            ovElement.translations = [];
           
            Axios.get(TenantConfigHandler.getVariable("ADMINV2_API_URL") + "/rem/option/option-values/" + ovElement.id + "/translations", {
              onTokenRefreshed: () => {
                asyncGet();
              }
            }).then(function (ovresp) {
              
              if (ovresp && ovresp.data) {
              
                 
                ovElement.translations = ovresp.data;
                ovElement.newValue = '';
                ovElement.newLanguage = '';
                

              }
            });
          })

        }
      });



    });
    state.optionsRepo = state.options;
    
  }
}




</script>

<style scoped></style>
