<template>
  <div>
    <!-- Modal toggle -->
    <input type="checkbox" class="modal-toggle" v-model="state.showModal" />
    <div class="modal">
      <div class="modal-box">
        <div class="h-full overflow-y-auto w-full">
          <div
            class="mt-5 mx-5 flex flex-row grow border-b-0 border-base-300 justify-between"
          >
            <div class="flex flex-row items-center gap-2 justify-center">
              <h2
                class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow"
              >
                <span class="text-secondary">{{
                  $t("message.editDocument")
                }}</span>
              </h2>
            </div>
            <a
              href="javascript:void(null)"
              class="hover:text-secondary"
              @click="hideSidebar"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </a>
          </div>

          <div class="px-5 pb-5">
            <div class="flex flex-col items-center justify-center gap-2 w-full">
              <div class="flex flex-row gap-3 w-full mt-2">
                <div class="form-control w-full mt-0">
                  <label class="label mt-0 pt-0" for="">
                    <span class="label-text-alt">{{ $t("message.name") }}</span>
                  </label>
                  <input
                    v-model="state.docuName"
                    type="text"
                    class="input input-sm input-bordered w-full"
                  />
                </div>
              </div>

              <div class="flex flex-row gap-3 w-full mt-2">
                <div class="form-control w-full mt-0">
                  <label class="flex flex-row gap-2">
                    <VueDatePicker
                      format="yyyy MMM dd"
                      :enable-time-picker="false"
                      :time-picker-inline="true"
                      :locale="$i18n.locale"
                      :month-change-on-scroll="false"
                      :action-row="{}"
                      :clearable="true"
                      select-text="OK"
                      :cancel-text="t('message.cancel')"
                      :teleport="true"
                      model-type="yyyy-MM-dd"
                      :placeholder="t('message.dateOfOrigin')"
                      v-model="state.docuOriginDate"
                      dark="true"
                    />
                  </label>
                </div>
              </div>

              <div class="flex flex-row gap-3 w-full mt-2">
                <div class="form-control w-full mt-0">
                  <label class="label mt-0 pt-0" for="">
                    <span class="label-text-alt">{{
                      $t("message.mainType")
                    }}</span>
                  </label>
                  <OptionValueSelector
                    id="mainTypeSelector"
                    v-model="state.typeId"
                    type="DOCUMENT_TYPE"
                  />
                </div>
              </div>

              <div class="flex flex-row gap-3 w-full mt-2">
                <div class="form-control w-full mt-0">
                  <label class="label mt-0 pt-0" for="">
                    <span class="label-text-alt">{{
                      $t("message.subType")
                    }}</span>
                  </label>
                  <OptionValueSelector
                    id="subTypeSelector"
                    v-model="state.subTypeId"
                    type="DOCUMENT_SUB_TYPE"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-action">
          <label
            class="btn btn-sm text-white font-light text-xs"
            @click="
              () => {
                store.commit('setIsModalOpen', false);
              }
            "
            >{{ $t("message.cancel") }}</label
          >
          <label
            class="btn btn-sm bg-magenta text-white font-light text-xs"
            @click="
              () => {
                updateDocument(props.id);
                store.commit('setIsModalOpen', false);
              }
            "
            >{{ $t("message.save") }}</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, watch } from "vue";
import Axios from "@/misc/axios";
import store from "@/misc/vuex-store.js";
import toast from "@/misc/toast.js";
import { useI18n } from "vue-i18n";
import OptionValueSelector from "@/components/OptionValueSelector.vue";
import VueDatePicker from "@vuepic/vue-datepicker";

import TenantConfigHandler from '@/misc/TenantConfigHandler';

const { t } = useI18n();

const state = reactive({
  showModal: false,
  docuName: "",
  docuOriginDate: "",
  typeId: null,
  subTypeId: null,
});

const props = defineProps(["showModal", "id"]);

const updateDocument = async () => {
  const uri =
    TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/document/${props.id}`;

  const formData = new FormData();
  formData.append("name", state.docuName);
  formData.append("mainType", state.typeId);
  formData.append("subType", state.subTypeId);
  formData.append("dateOfOrigin", state.docuOriginDate);

  try {
    await Axios.patch(uri, formData);
    emptyEditor();
    toast.success(t("message.successfulSave"));
  } catch (error) {
    console.log(error);
    toast.error(t("message.saveError"));
  }
};

const document = computed(() => {
  return store.getters.getDocumentById;
});

const fillEditor = () => {
  state.docuName = document.value.name ? document.value.name : "";
  state.docuOriginDate = document.value.dateOfOrigin ? document.value.dateOfOrigin : "";
  state.typeId = document.value.typeId ? document.value.typeId : null;
  state.subTypeId = document.value.subTypeId ? document.value.subTypeId : null;
};

const emptyEditor = () => {
  (state.docuName = ""), (state.docuOriginDate = ""), (state.typeId = null), (state.subTypeId = null);
};

watch(
  () => props.showModal,
  () => {
    state.showModal = props.showModal;
    fillEditor();
  }
);

const hideSidebar = () => {
  store.commit("setIsModalOpen", false);
};

onMounted(() => {});
</script>

<style scoped></style>
