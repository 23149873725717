
import TenantConfigHandler from '@/misc/TenantConfigHandler';

export default {
  basicData: [
    {
      title: "function",
      value: "",
      valueLabel: "",
      model: "basicDataPremiseFunction",
      type: "option",
      searchable: true,
      optionValue: "PREMISE_FUNCTION",
    },
    {
      title: "cremClassification",
      value: "",
      valueLabel: "",
      model: "basicDataCremClass",
      type: "option",
      optionValue: "CREM_CLASSIFICATION",
    },
    {
      title: "identifier",
      value: "",
      model: "basicDataIdentifier",
      type: "text",
    },
    {
      title: "premiseNumber",
      value: "",
      model: "basicDataPremiseNumber",
      type: "number",
    },
    {
      title: "sqm",
      value: "",
      model: "basicDataFloorSpace",
      type: "number",
    },
    {
      title: "isAccessible	",
      value: "",
      model: "basicDataAccessibility",
      type: "checkbox",
    },
  ],
  staircaseData: [
    {
      title: "stairwayId",
      value: "",
      model: "basicDataStairwayIdentifier",
      type: "text",
    },
    {
      title: "stairwayType",
      value: "",
      model: "basicDataStairwayType",
      type: "text",
    },
    {
      title: "isEscapeRoute",
      value: "",
      model: "basicDataFireRoute",
      type: "checkbox",
    },
    {
      title: "isSmokeFree",
      value: "",
      model: "basicDataSmokeFree",
      type: "checkbox",
    },
  ],
  elevatorData: [
    {
      title: "numberOfPerson",
      value: "",
      model: "basicDataElevatorNumberPersons",
      type: "text",
    },
    {
      title: "elevatorType",
      value: "",
      model: "basicDataElevatorType",
      type: "text",
    },
    {
      title: "loadCapacity",
      value: "",
      model: "basicDataElevatorLoadCapacity",
      type: "number",
    },
    {
      title: "isEmergencyElevator",
      value: "",
      model: "basicDataEmergencyElevator",
      type: "checkbox",
    },
  ],
  specialData: [
    {
      title: "floors",
      value: "",
      model: "floor",
      type: "number",
    },
    {
      title: "notes",
      value: "",
      model: "entryNote",
      type: "text",
      validationMethod: (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue,
        inputError
      ) => {
        if (newValue > 2000) {
          inputError.value = "message.entryNoteWarning";
        } else {
          inputError.value = "";
        }
      },
    },
    {
      title: "szepaClass",
      value: "",
      model: "szepa",
      type: "text",
    },
    {
      title: "floorCovering",
      value: "",
      model: "floorCovering",
      type: "option",
      optionValue: "FLOOR_COVERING",
    },
    {
      title: "isFalseFloor",
      value: "",
      model: "falseFloor",
      type: "checkbox",
    },
    {
      title: "isFalseCeiling",
      value: "",
      model: "falseCeiling",
      type: "checkbox",
    },
    {
      title: "personnel",
      type: "label",
    },
    {
      title: "numberDedicated",
      value: "",
      model: "numberDedicated",
      type: "number",
      helperText: "numberDedicatedHelperText"
    },
    {
      title: "numberCanBeSeated",
      value: "",
      model: "numberCanBeSeated",
      type: "number",
      helperText: "numberCanBeSeatedHelperText"
    },
    {
      title: "numberSeated",
      value: "",
      model: "numberSeated",
      type: "number",
      helperText: "numberSeatedHelperText"
    },
    {
      title: "fireProtection",
      type: "label",
    },
    {
      title: "fireProtectionClass",
      value: "",
      model: "fireProtectionClass",
      type: "text",
      optionValue: "PREMISE_FUNCTION",
    },
    {
      title: "builtInFireProtection",
      value: "",
      model: "builtInFireProtection",
      type: "text",
      optionValue: "PREMISE_FUNCTION",
    },

    {
      title: "fenestration",
      type: "label",
    },
    {
      title: "doorType",
      value: "",
      model: "doorType",
      type: "option",
      optionValue: "DOOR_TYPE",
    },
    {
      title: "windowNumber",
      value: "",
      model: "numberOfWindows",
      type: "number",
    },
    {
      title: "openableWindow",
      value: "",
      model: "openableWindow",
      type: "checkbox",
    },
    {
      title: "windowMaterial",
      value: "",
      model: "windowMaterial",
      type: "text",
      optionValue: "WINDOW_MATERIAL",
    },
    {
      title: "windowDepth",
      value: "",
      model: "windowDepth",
      type: "number",
    },
    {
      title: "windowHeight",
      value: "",
      model: "windowHeight",
      type: "number",
    },
    {
      title: "windowWidth",
      value: "",
      model: "windowWidth",
      type: "text",
    },
    {
      title: "technicalData",
      type: "label",
    },
    {
      title: "minHeight",
      value: "",
      model: "minHeight",
      type: "text",
    },
    {
      title: "maxHeight",
      value: "",
      model: "maxHeight",
      type: "text",
    },
    {
      title: "sharedUse",
      value: "",
      model: "sharedUse",
      type: "checkbox",
    },
    {
      title: "dedicatedOrgUnit",
      value: "",
      model: "dedicatedOrgUnit",
      type: "checkbox",
    },
    {
      title: "canBeCooled",
      value: "",
      model: "canBeCooled",
      type: "checkbox",
    },
    {
      title: "canBeHeated",
      value: "",
      model: "canBeHeated",
      type: "checkbox",
    },
  ],
  utilizationData: [
    {
      title: "floorSpace",
      value: "",
      model: "floorSpace",
      type: "number",
    },
    {
      title: "legalRelationshipUtilization",
      value: "",
      model: "legalRelationship",
      type: "option",
      optionValue: "UTILIZATION_LEGAL_RELATIONSHIP",
    },
    {
      title: "legalRelationshipStart",
      value: "",
      model: "legalRelationshipStart",
      type: "date",
    },
    {
      title: "legalRelationshipEnd",
      value: "",
      model: "legalRelationshipEnd",
      type: "date",
    },
    {
      title: "partner",
      value: "",
      model: "partner",
      type: "optionapi",
      endPoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner",
      displayValue: "name",
    },
  ],
  legalsitData: [
    {
      title: "legalSituation",
      value: "",
      valueLabel: "",
      model: "legalSituationType",
      type: "option",
      optionValue: "LEGAL_SITUATION",
    },
    {
      title: "legalRelationship",
      value: "",
      valueLabel: "",
      model: "legalRelationshipType",
      type: "option",
      optionValue: "LEGAL_RELATIONSHIP_TYPE",
    },
    {
      title: "ownership",
      value: "",
      model: "ownership",
      type: "text",
    },
    {
      title: "legalRelationshipStart",
      value: "",
      model: "legalRelationshipStart",
      type: "date",
    },
    {
      title: "legalRelationshipEnd",
      value: "",
      model: "legalRelationshipEnd",
      type: "date",
    },
    {
      title: "areaSize",
      value: "",
      model: "squareMeter",
      type: "number",
    },
  ],
  editLegalsit: {
    property: 0,
    building: 0,
    legalSituationType: {
      id: "",
      value: "",
    },
    legalRelationshipType: {
      id: "",
      value: "",
    },
    ownership: "",
    legalRelationshipStart: "",
    legalRelationshipEnd: "",
    squareMeter: ""
  },
  meterData: [
    {
      title: "gist",
      value: "",
      model: "gistId",
      optionSearchEndpoint: "gist",
      type: "optionSearchEndpoint",
    },
    {
      title: "meterType",
      value: "",
      valueLabel: "",
      model: "type",
      type: "option",
      optionValue: "METER_TYPE",
    },
    {
      title: "partner",
      value: "",
      model: "partnerId",
      type: "optionapi",
      endPoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner",
      displayValue: "name",
    },
    {
      title: "publicUtilityType",
      value: "",
      model: "utilityType",
      type: "option",
      optionValue: "METER_UTILITY_TYPE",
    },
    {
      title: "identifier",
      value: "",
      model: "identifier",
      type: "text",
    },
    {
      title: "placement",
      value: "",
      model: "placement",
      type: "text",
    },
    {
      title: "readings",
      value: "",
      model: "readings",
      type: "text",
    },
    {
      title: "readingFrequency",
      value: "",
      model: "readingFrequency",
      type: "option",
      optionValue: "METER_READING_FREQUENCY",
    },
    {
      title: "commType",
      value: "",
      type: "option",
      model: "commType",
      optionValue: "METER_COMM_TYPE",
    },
    {
      title: "expirationDate",
      value: "",
      model: "expirationDate",
      type: "date",
    },
  ],
  partnerData: [
    {
      title: "name",
      value: "",
      model: "name",
      type: "text",
    },
    {
      title: "mainType",
      value: "",
      model: "partnerMainType",
      type: "option",
      optionValue: "PARTNER_MAIN_TYPE",
    },
    {
      title: "subType",
      value: "",
      model: "partnerSubType",
      type: "option",
      optionValue: "PARTNER_SUB_TYPE",
      onchangeMethod: (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue
      ) => {
        options.value = optionsRepo.filter((elem) => elem.parent == value);
        if (options.value.filter((elem) => elem.value == oldValue)) {
          inputValue.value = oldValue;
        } else {
          inputValue.value = "";
        }
      },
    },
    {
      title: "taxId",
      value: "",
      model: "taxId",
      type: "text",
    },
    {
      title: "taxNumber",
      value: "",
      model: "taxNumber",
      type: "text",
    },
  ],
  coolingHeatingDeviceData: [
    {
      title: "function",
      value: "",
      valueLabel: "",
      model: "coolingHeatingFunction",
      type: "option",
      optionValue: "COOLING_HEATING_FUNCTION",
    },
    {
      title: "type",
      value: "",
      valueLabel: "",
      model: "coolingHeatingType",
      type: "option",
      optionValue: "COOLING_HEATING_TYPE",
      onchangeMethod: (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue
      ) => {
        options.value = optionsRepo.filter((elem) => elem.parent == value);
        if (options.value.filter((elem) => elem.value == oldValue)) {
          inputValue.value = oldValue;
        } else {
          inputValue.value = "";
        }
      },
    },
    {
      title: "isCentral",
      value: "",
      model: "central",
      type: "checkbox",
    },
    {
      title: "canBeControlled",
      value: "",
      model: "canBeControlled",
      type: "checkbox",
    },
    {
      title: "outdoorUnit",
      value: "",
      model: "outdoorUnit",
      type: "checkbox",
    },
    {
      title: "manufacturer",
      value: "",
      model: "manufacturer",
      type: "text",
    },
    {
      title: "energyIntake",
      value: "",
      model: "energyIntake",
      type: "text",
    },
    {
      title: "coolingPerformance",
      value: "",
      model: "coolingPerformance",
      type: "text",
    },
    {
      title: "heatingPerformance",
      value: "",
      model: "heatingPerformance",
      type: "text",
    },
    {
      title: "installationDate",
      value: "",
      model: "installationDate",
      type: "date",
    },
    {
      title: "maintenanceCycle",
      value: "",
      model: "maintenanceCycle",
      type: "text",
    },
    {
      title: "yearOfManufacture",
      value: "",
      model: "yearOfManufacture",
      type: "number",
    },
    {
      title: "guarantee",
      value: "",
      model: "guarantee",
      type: "text",
    }
  ],
};
