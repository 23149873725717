import Axios from "@/misc/axios";
import TenantConfigHandler from '@/misc/TenantConfigHandler';

export default {
  partnerData: [
    {
      title: "name",
      value: "",
      model: "name",
      type: "text",
    },
    {
      title: "mainType",
      value: "",
      model: "partnerMainType",
      type: "option",
      optionValue: "PARTNER_MAIN_TYPE",
    },
    {
      title: "subType",
      value: "",
      model: "partnerSubType",
      type: "option",
      optionValue: "PARTNER_SUB_TYPE",
      onchangeMethod: (options, optionsRepo, value, newValue, oldValue, inputValue,) => {
        options.value = optionsRepo.filter((elem) => elem.parent == value);
        if (options.value.filter((elem) => elem.value == oldValue)) {
          inputValue.value = oldValue;
        } else {
          inputValue.value = "";
        }
      },
    },
    {
      title: "privateIndividual",
      value: "",
      model: "",
      type: "checkbox",
    },
    {
      title: "taxId",
      value: "",
      model: "taxId",
      type: "text",
    },
    {
      title: "taxNumber",
      value: "",
      model: "taxNumber",
      type: "text",
      validationMethod: (options, optionsRepo, value, newValue, oldValue, inputValue, inputError) => {
        
        if(newValue.length == 8 && oldValue.length < 8){
          inputValue.value = inputValue.value + "-"
        }
        else if(newValue.length == 10 && oldValue.length < 10){
          inputValue.value = inputValue.value + "-"
        }
        else if(newValue.length > 13){
          inputError.value = "tulhosszuAdoszam"
        }
      }
    },
  ],
  buildingBasicData:[
    {
      title: "floorArea",
      value: "",
      model: "floorArea",
      type: "number",
    },
    {
      title: "mtArea",
      value: "",
      model: "mtArea",
      type: "number",
    },
    {
      title: "premiseNumber",
      value: "",
      model: "premiseNumber",
      type: "number",
    },
    {
      title: "mainFunction",
      value: "",
      valueLabel: "",
      model: "mainFunction",
      type: "option",
      optionValue: "BUILDING_MAIN_FUNCTION",
    },
    {
      title: "isAccessible",
      value: "",
      model: "isAccessible",
      type: "checkbox",
    },
    {
      title: "numberDedicated",
      value: "",
      valueLabel: "",
      model: "numberDedicated",
      type: "number",
      helperText: "numberDedicatedHelperText"
    },
    {
      title: "numberCanBeSeated",
      value: "",
      valueLabel: "",
      model: "numberCanBeSeated",
      type: "number",
      helperText: "numberCanBeSeatedHelperText"
    },
    {
      title: "numberSeated",
      value: "",
      valueLabel: "",
      model: "numberSeated",
      type: "number",
      helperText: "numberSeatedHelperText"
    },
    {
      title: "buildingMaxHeight",
      value: "",
      model: "maxHeight",
      type: "number",
    },
    {
      title: "status",
      value: "",
      valueLabel: "",
      model: "status",
      type: "option",
      optionValue: "BUILDING_STATUS",
    },
    {
      title: "floorsBelowSurface",
      value: "",
      model: "floorsBelowSurface",
      type: "text",
    },
    {
      title: "floorsAboveSurface",
      value: "",
      model: "floorsAboveSurface",
      type: "text",
    },
    {
      title: "attic",
      value: "",
      model: "attic",
      type: "checkbox",
    },
    {
      title: "atticCanBeUsed",
      value: "",
      model: "atticCanBeUsed",
      type: "checkbox",
    },
    {
      title: "sap",
      value: "",
      valueLabel: "",
      model: "sap",
      type: "option",
      optionValue: "SAP_BUILDING_IDENTIFIER",
    },
    {
      title: "tcafm",
      value: "",
      model: "tcafm",
      type: "text",
    },
    {
      title: "parcelNumber",
      value: "",
      model: "parcelNumber",
      type: "text",
    },
    {
      title: "mtResponsibles",
      type: "label"
    },
    {
      title: "operation",
      value: "",
      model: "operation",
      type: "text",
    },
    {
      title: "cleaning",
      value: "",
      model: "cleaner",
      type: "text",
    },
    {
      title: "supplies",
      value: "",
      model: "supplies",
      type: "text",
    },
    {
      title: "tui",
      value: "",
      model: "tui",
      type: "text",
    },
    {
      title: "ownerOfAssets",
      value: "",
      model: "ownerOfAssets",
      type: "text",
    },
    {
      title: "assetManager",
      value: "",
      model: "assetManager",
      type: "text",
    },
  ],
  buildingData: [
    {
      title: "baseDatas",
      type: "label"
    },
    {
      title: "siteDesign",
      value: "",
      optionSearchEndpoint: "siteDesign",
      model: "siteDesignId",
      type: "optionSearchEndpoint",
    },
    {
      title: "paymentObligations",
      value: "",
      model: "paymentObligation",
      type: "text",
    },
    {
      title: "numberOfStairways",
      value: "",
      model: "numberOfStairways",
      type: "number",
    },
    {
      title: "numberOfElevators",
      value: "",
      model: "numberOfElevators",
      type: "number",
    },
    {
      title: "structureTypes",
      type: "label"
    },
    {
      title: "foundation",
      value: "",
      valueLabel: "",
      model: "foundation",
      type: "option",
      optionValue: "FOUNDATION",
    },
    {
      title: "bearingMasonry",
      value: "",
      valueLabel: "",
      model: "bearingMasonry",
      type: "option",
      optionValue: "BEARING_MASONRY",
    },
    {
      title: "slabStructInter",
      value: "",
      valueLabel: "",
      model: "slabStructureInter",
      type: "option",
      optionValue: "SLAB_STRUCTURE_INTERMEDIATE",
    },
    {
      title: "slabSubStructInter",
      value: "",
      valueLabel: "",
      model: "slabSubStructureInter",
      type: "option",
      optionValue: "SLAB_SUB_STRUCTURE_INTERMEDIATE",
      onchangeMethod: (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue
      ) => {
        options.value = optionsRepo.filter((elem) => elem.parent == value);
        if (options.value.filter((elem) => elem.value == oldValue)) {
          inputValue.value = oldValue;
        } else {
          inputValue.value = "";
        }
      },
    },
    {
      title: "slabStructClosing",
      value: "",
      valueLabel: "",
      model: "slabStructureClosing",
      type: "option",
      optionValue: "SLAB_STRUCTURE_CLOSING",
    },
    {
      title: "slabSubStructClosing",
      value: "",
      valueLabel: "",
      model: "slabSubStructureClosing",
      type: "option",
      optionValue: "SLAB_SUB_STRUCTURE_CLOSING",
      onchangeMethod: (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue
      ) => {
        options.value = optionsRepo.filter((elem) => elem.parent == value);
        if (options.value.filter((elem) => elem.value == oldValue)) {
          inputValue.value = oldValue;
        } else {
          inputValue.value = "";
        }
      },
    },
    {
      title: "flatRoof",
      value: "",
      valueLabel: "",
      model: "flatRoof",
      type: "option",
      optionValue: "FLAT_ROOF",
    },
    {
      title: "roofType",
      value: "",
      valueLabel: "",
      model: "roofType",
      type: "option",
      optionValue: "ROOF_TYPE",
    },
    {
      title: "roofing",
      value: "",
      valueLabel: "",
      model: "roofing",
      type: "option",
      optionValue: "ROOFING",
    },
    {
      title: "insulation",
      value: "",
      valueLabel: "",
      model: "insulation",
      type: "option",
      optionValue: "INSULATION",
    },
  ],
  legalsitData: [
    {
      title: "legalSituation",
      value: "",
      valueLabel: "",
      model: "legalSituationType",
      type: "option",
      optionValue: "LEGAL_SITUATION",
    },
    {
      title: "legalRelationship",
      value: "",
      valueLabel: "",
      model: "legalRelationshipType",
      type: "option",
      optionValue: "LEGAL_RELATIONSHIP_TYPE",
    },
    {
      title: "ownership",
      value: "",
      model: "ownership",
      type: "text",
    },
    {
      title: "legalRelationshipStart",
      value: "",
      model: "legalRelationshipStart",
      type: "date",
    },
    {
      title: "legalRelationshipEnd",
      value: "",
      model: "legalRelationshipEnd",
      type: "date",
    },
    {
      title: "areaSize",
      value: "",
      model: "squareMeter",
      type: "number",
    },
  ],
  obligationData: [
    {
      title: "type",
      value: "",
      valueLabel: "",
      model: "obligationType",
      type: "option",
      optionValue: "OBLIGATION_TYPE",
    },
    {
      title: "otherRight",
      value: "",
      valueLabel: "",
      model: "otherRight",
      type: "option",
      optionValue: "OTHER_RIGHTS",
    },
    {
      title: "permission",
      value: "",
      model: "permission",
      type: "text",
    },
    {
      title: "obliged",
      value: "",
      model: "obliged",
      type: "text",
    },
  ],
  utilizationData: [
    {
      title: "floorSpace",
      value: "",
      model: "floorSpace",
      type: "number",
    },
    {
      title: "legalRelationship",
      value: "",
      valueLabel: "",
      model: "legalRelationship",
      type: "option",
      optionValue: "UTILIZATION_LEGAL_RELATIONSHIP",
    },
    {
      title: "legalRelationshipStart",
      value: "",
      model: "legalRelationshipStart",
      type: "date",
    },
    {
      title: "legalRelationshipEnd",
      value: "",
      model: "legalRelationshipEnd",
      type: "date",
    },
    {
      title: "partner",
      value: "",
      model: "partnerId",
      type: "optionapi",
      endPoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner",
      displayValue: "name",
    },
  ],
  addressData: [
    {
      title: "addressType",
      value: "",
      manualOptions: [
        {
          value: "PRIMARY",
          label: "ELsődleges",
        },
        {
          value: "SECONDARY",
          label: "Másodlagos",
        },
      ],
      model: "addressType",
      type: "optionManual",
    },
    {
      title: "country",
      value: "",
      valueLabel: "",
      model: "country",
      type: "option",
      optionValue: "COUNTRY",
    },
    {
      title: "postal",
      value: "",
      model: "postalCode",
      type: "number",
      validationMethod: (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue,
        inputError
      ) => {
        if (newValue < 1000 || newValue > 9999) {
          inputError.value = "message.postalError";
        } else {
          inputError.value = "";
        }
      },
    },
    {
      title: "county",
      value: "",
      model: "county",
      type: "optionapi",
      //endpoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner/settlement/postal",
      onchangeMethod: async (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue,
        inputError
      ) => {
        if (value > 1000 && value < 9999) {
          try {
            await Axios.get(
              TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
                "/rem/partner/settlement/postal?postalCode=" +
                value
            ).then((resp) => {
              if (resp.data) {
                inputError.value = "";
                options.value = [];
                resp.data.forEach((elem) => {
                  options.value.push({
                    label: elem.county,
                    value: elem.county,
                  });
                });
                if (options.value.length == 1) {
                  inputValue.value = resp.data[0].county;
                }
              }
            });
          } catch (error) {
            options.value = [];
            inputValue.value = "";
            inputError.value = "message.postalError";
          }
        }
      },
    },
    {
      title: "city",
      value: "",
      model: "city",
      type: "optionapi",
      //endpoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner/settlement/postal",
      onchangeMethod: async (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue,
        inputError
      ) => {
        if (value > 1000 && value < 9999) {
          try {
            await Axios.get(
              TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
                "/rem/partner/settlement/postal?postalCode=" +
                value
            ).then((resp) => {
              if (resp.data) {
                inputError.value = "";
                options.value = [];
                resp.data.forEach((elem) => {
                  options.value.push({
                    label: elem.name,
                    value: elem.name,
                  });
                });
                if (options.value.length == 1) {
                  inputValue.value = resp.data[0].name;
                }
              }
            });
          } catch (error) {
            options.value = [];
            inputValue.value = "";
            inputError.value = "message.postalError";
          }
        }
      },
    },
    {
      title: "publicAreaName",
      value: "",
      model: "publicAreaName",
      type: "text",
    },
    {
      title: "publicAreaType",
      value: "",
      valueLabel: "",
      model: "publicAreaType",
      type: "option",
      searchable: true,
      optionValue: "PUBLIC_AREA_TYPE",
    },
    {
      title: "houseNumber",
      value: "",
      model: "houseNumber",
      type: "text",
    },
    {
      title: "stairway",
      value: "",
      model: "stairWay",
      type: "text",
    },
    {
      title: "floor",
      value: "",
      model: "floor",
      type: "text",
    },
    {
      title: "door",
      value: "",
      model: "door",
      type: "text",
    },
    {
      title: "parcelNumber",
      value: "",
      model: "parcelNumber",
      type: "text",
    },
    {
      title: "gpsCoordinates",
      value: "",
      model: "gpsCoordinates",
      type: "text",
    },
    {
      title: "notes",
      value: "",
      model: "notes",
      type: "text",
      validationMethod: (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue,
        inputError
      ) => {
        if (newValue > 2000) {
          inputError.value = "message.entryNoteWarning";
        } else {
          inputError.value = "";
        }
      },
    },
  ],
};
