import Axios from "@/misc/axios";
import toast from "@/misc/toast.js";
import { watchEffect } from "vue";
import router from "@/misc/router.js";
import TenantConfigHandler from '@/misc/TenantConfigHandler';

export default {
  setup: (state) => {
    watchEffect(() => {
      if (state.partnerData[1].value) {
        setTimeout(() => {
          state.partnerData[2].value =
            "CHANGECODE###" + state.partnerData[1].value;
        }, 700);
      }

      if (state.buildingData[8].value) {
        setTimeout(() => {
          state.buildingData[9].value =
            "CHANGECODE###" + state.buildingData[8].value;
        }, 700);
      }

      if (state.buildingData[10].value) {
        setTimeout(() => {
          state.buildingData[11].value =
            "CHANGECODE###" + state.buildingData[10].value;
        }, 700);
      }

      if (state.partnerData[3].value) {
        console.log("isperson");

        setTimeout(() => {
          state.isPerson =
            state.partnerData[3].value == "true" ? true : false;
          if (state.isPerson == true) {
            state.partnerData[5].value = "";
          } else {
            state.partnerData[4].value = "";
          }
        }, 700);
      }

      if (state.addressData[2].value) {
        //console.log("postal");
        setTimeout(() => {
          state.addressData[3].value =
            "CHANGECODE###" + state.addressData[2].value;
          state.addressData[4].value =
            "CHANGECODE###" + state.addressData[2].value;
        }, 700);
      }
    });
  },
  savePartner: async function (state, t) {
    const hermes = {
      partnerMainType: 0,
      partnerSubType: 0,
      name: "",
      taxId: "",
      taxNumber: "",
    };

    state.partnerData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    if (state.partnerId != 0) {
      await Axios.patch(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          "/rem/partner/" +
          state.partnerId,
        hermes,
        {
          onTokenRefreshed: () => {
            this.savePartner(state, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 200) {
            toast.success(t("message.successfulSave"));
            state.partnerData.forEach((e) => {
              e.value = "";
            });
            state.tab = 3;
            state.partnerId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    } else {
      await Axios.post(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner",
        hermes,
        {
          onTokenRefreshed: () => {
            this.savePartner(state, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 201) {
            toast.success(t("message.successfulSave"));
            state.partnerData.forEach((e) => {
              e.value = "";
            });
            state.tab = 3;
            state.partnerId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },
  deletePartner: async function (id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/partner/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deletePartner(id, t);
        },
      });
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      console.log(error);
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  saveBuilding: async function (state, t, propertyId) {
    const hermes = {
      siteDesignId: "",
      property: propertyId,
      basicData: {
        floorArea: "",
        mtArea: "",
        mainFunction: "",
        premiseNumber: "",
        isAccessible: "",
        numberDedicated: "",
        numberCanBeSeated: "",
        numberSeated: "",
        maxHeight: "",
        status: "",
        floorsBelowSurface: "",
        floorsAboveSurface: "",
        attic: "",
        atticCanBeUsed: "",
        sap: "",
        tcafm: "",
        parcelNumber: "",
        operation: "",
        cleaner: "",
        supplies: "",
        tui: "",
        ownerOfAssets: "",
        assetManager: "",
      },
      paymentObligation: "",
      numberOfStairways: "",
      numberOfElevators: "",
      foundation: "",
      bearingMasonry: "",
      slabStructureInter: "",
      slabSubStructureInter: "",
      slabStructureClosing: "",
      slabSubStructureClosing: "",
      flatRoof: "",
      roofType: "",
      roofing: "",
      insulation: "",
    };

    state.buildingData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    state.buildingBasicData.forEach((e) => {
      hermes.basicData[e.model] = e.value;
    });

    if (state.buildingId != 0) {
      await Axios.patch(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          "/rem/building/" +
          state.buildingId,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveBuilding(state, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 200) {
            toast.success(t("message.successfulSave"));
            state.buildingData.forEach((e) => {
              e.value = "";
            });
            state.buildingBasicData.forEach((e) => {
              e.value = "";
            });
            state.tab = 1;
            state.buildingId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    } else {
      await Axios.post(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/building",
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveBuilding(state, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 201) {
            toast.success(t("message.successfulSave"));
            state.buildingData.forEach((e) => {
              e.value = "";
            });
            state.buildingBasicData.forEach((e) => {
              e.value = "";
            });
            state.tab = 1;
            state.buildingId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },
  deleteBuilding: async function (id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/building/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deleteBuilding(id, t);
        },
      });
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      console.log(error);
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  deleteAddress: async function (id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/property/address/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deleteAddress();
        },
      });
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      console.log(error);
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  saveSecondaryAddress: async function (state, propertyId, t) {
    const hermes = {
      propertyId: propertyId,
      addressType: "",
      country: "",
      postalCode: "",
      county: "",
      city: "",
      publicAreaName: "",
      publicAreaType: "",
      houseNumber: "",
      stairWay: "",
      floor: "",
      door: "",
      parcelNumber: "",
      gpsCoordinates: "",
      notes: "",
    };

    state.addressData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    console.log(hermes);
    if (state.addressId != 0) {
      hermes.id = state.addressId;
      await Axios.patch(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          "/rem/property/address/" +
          state.addressId,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveAddress(state, propertyId, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 200) {
            toast.success(t("message.successfulSave"));
            state.addressData.forEach((e) => {
              e.value = "";
            });
            state.tab = 2;
            state.addressId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    } else {
      await Axios.post(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/property/address",
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveSecondaryAddress(state, propertyId, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 201) {
            toast.success(t("message.successfulSave"));
            state.addressData.forEach((e) => {
              e.value = "";
            });
            state.tab = 2;
            state.addressId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },
  deleteUtilization: async function (id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/utilization/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deleteUtilization();
        },
      });
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      console.log(error);
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  saveUtilization: async function (state, propertyId, t) {
    const hermes = {
      propertyId: propertyId,
      floorSpace: "",
      legalRelationship: "",
      legalRelationshipStart: "",
      legalRelationshipEnd: "",
      partnerId: "",
    };

    state.utilizationData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    console.log(hermes);
    if (state.utilizationId != 0) {
      hermes.id = state.utilizationId;
      await Axios.patch(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          "/rem/utilization/" +
          state.utilizationId,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveUtilization(state, propertyId, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 200) {
            toast.success(t("message.successfulSave"));
            state.utilizationData.forEach((e) => {
              e.value = "";
            });
            state.tab = 7;
            state.utilizationId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    } else {
      await Axios.post(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/utilization",
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveUtilization(state, propertyId, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 201) {
            toast.success(t("message.successfulSave"));
            state.utilizationData.forEach((e) => {
              e.value = "";
            });
            state.tab = 7;
            state.utilizationId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },
  deleteObligation: async function (id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/property/obligation/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deleteObligation();
        },
      });
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      console.log(error);
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  saveObligation: async function (state, t, propertyId) {
    const hermes = {
      propertyId: propertyId,
      obligationType: "",
      otherRight: "",
      permission: "",
      obliged: "",
    };

    state.obligationData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    if (state.obligationId != 0) {
      await Axios.patch(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          "/rem/property/obligation/" +
          state.obligationId,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveObligation(state, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 200) {
            toast.success(t("message.successfulSave"));
            state.obligationData.forEach((e) => {
              e.value = "";
            });
            state.tab = 9;
            state.obligationId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    } else {
      await Axios.post(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/property/obligation",
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveObligation(state, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 201) {
            toast.success(t("message.successfulSave"));
            state.obligationData.forEach((e) => {
              e.value = "";
            });
            state.tab = 9;
            state.obligationId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },
  uploadFile: async function (state, docsInput, t, propertyId) {
    const uri =
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        `/rem/property/${propertyId}/document`;
  
    const formData = new FormData();
    formData.append("mainType", state.selectedMainType);
    formData.append("subType", state.selectedSubType);
    formData.append("document", docsInput.value.files[0]);
    formData.append("name", state.docuName);
    formData.append("dateOfOrigin", state.docuOriginDate);
  
    try {
      await Axios.post(uri, formData);
      (state.docuName = "");
      (state.docuOriginDate = "");
      (state.selectedMainType = "");
      (state.selectedSubType = "");
      (document.getElementById("file_input").value = "");
      toast.success(t("message.successfulSave"));
    } catch (error) {
      console.log(error);
      toast.error(t("message.saveError"));
    }
  },
  saveLegalsit: async function (state, t, propertyId) {
    const hermes = {
      propertyId: propertyId,
      legalSituationType: "",
      legalRelationshipType: "",
      ownership: "",
      legalRelationshipStart: "",
      legalRelationshipEnd: "",
      squareMeter: ""
    };

    state.legalsitData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    if (state.legalsitId != 0) {
      await Axios.patch(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          "/rem/legal-situation/" +
          state.legalsitId,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveLegalsit(state, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 200) {
            toast.success(t("message.successfulSave"));
            state.legalsitData.forEach((e) => {
              e.value = "";
            });
            state.tab = 6;
            state.legalsitId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    } else {
      await Axios.post(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/legal-situation",
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveLegalsit(state, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 201) {
            toast.success(t("message.successfulSave"));
            state.legalsitData.forEach((e) => {
              e.value = "";
            });
            state.tab = 6;
            state.legalsitId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },
  deleteLegalSituation: async function (id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/legal-situation/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deleteLegalSituation();
        },
      });
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      console.log(error);
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  toBuilding: function(id){
    router.push({
      name: "buildingPage",
      params: { buildingId: id },
    });
  },
}