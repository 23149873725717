import Axios from "@/misc/axios";

import TenantConfigHandler from "@/misc/TenantConfigHandler";

export default {
  partnerData: [
    {
      title: "name",
      value: "",
      model: "name",
      type: "text",
    },
    {
      title: "mainType",
      value: "",
      model: "partnerMainType",
      type: "option",
      optionValue: "PARTNER_MAIN_TYPE",
    },
    {
      title: "subType",
      value: "",
      model: "partnerSubType",
      type: "option",
      optionValue: "PARTNER_SUB_TYPE",
      onchangeMethod: (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue
      ) => {
        options.value = optionsRepo.filter((elem) => elem.parent == value);
        if (options.value.filter((elem) => elem.value == oldValue)) {
          inputValue.value = oldValue;
        } else {
          inputValue.value = "";
        }
      },
    },
    {
      title: "privateIndividual",
      value: "",
      model: "",
      type: "checkbox",
    },
    {
      title: "taxId",
      value: "",
      model: "taxId",
      type: "text",
    },
    {
      title: "taxNumber",
      value: "",
      model: "taxNumber",
      type: "text",
      validationMethod: (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue,
        inputError
      ) => {
        if (newValue.length == 8 && oldValue.length < 8) {
          inputValue.value = inputValue.value + "-";
        } else if (newValue.length == 10 && oldValue.length < 10) {
          inputValue.value = inputValue.value + "-";
        } else if (newValue.length > 13) {
          inputError.value = "tulhosszuAdoszam";
        }
      },
    },
  ],
  addressData: [
    {
      title: "addressType",
      value: "",
      model: "addressType",
      type: "option",
      optionValue: "PARTNER_ADDRESS_TYPE",
    },
    {
      title: "postal",
      value: "",
      model: "postalCode",
      type: "number",
      validationMethod: (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue,
        inputError
      ) => {
        if (newValue < 1000 || newValue > 9999) {
          inputError.value = "message.postalError";
        } else {
          inputError.value = "";
        }
      },
    },
    {
      title: "country",
      value: "",
      model: "country",
      type: "option",
      optionValue: "COUNTRY",
    },
    {
      title: "county",
      value: "",
      model: "county",
      type: "optionapi",
      //endpoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner/settlement/postal",
      onchangeMethod: async (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue,
        inputError
      ) => {
        if (value > 1000 && value < 9999) {
          try {
            await Axios.get(
              TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
                "/rem/partner/settlement/postal?postalCode=" +
                value
            ).then((resp) => {
              if (resp.data) {
                inputError.value = "";
                options.value = [];
                resp.data.forEach((elem) => {
                  options.value.push({
                    label: elem.county,
                    value: elem.county,
                  });
                });
                optionsRepo = options.value;
                if (options.value.length == 1) {
                  inputValue.value = resp.data[0].county;
                }
              }
            });
          } catch (error) {
            options.value = [];
            inputValue.value = "";
            inputError.value = "message.postalError";
          }
        }
      },
    },
    {
      title: "city",
      value: "",
      model: "city",
      type: "optionapi",
      //endpoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner/settlement/postal",
      onchangeMethod: async (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue,
        inputError
      ) => {
        if (value > 1000 && value < 9999) {
          try {
            await Axios.get(
              TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
                "/rem/partner/settlement/postal?postalCode=" +
                value
            ).then((resp) => {
              console.log(resp.data);

              if (resp.data) {
                inputError.value = "";
                options.value = [];
                resp.data.forEach((elem) => {
                  options.value.push({
                    label: elem.name,
                    value: elem.name,
                  });
                });
                optionsRepo = options.value;
                if (options.value.length == 1) {
                  inputValue.value = resp.data[0].name;
                }
              }
            });
          } catch (error) {
            console.log(error);

            options.value = [];
            inputValue.value = "";
            inputError.value = "message.postalError";
          }
        }
      },
    },
    {
      title: "publicAreaName",
      value: "",
      model: "publicAreaName",
      type: "text",
    },
    {
      title: "publicAreaType",
      value: "",
      model: "publicAreaType",
      type: "option",
      searchable: true,
      optionValue: "PUBLIC_AREA_TYPE",
    },
    {
      title: "houseNumber",
      value: "",
      model: "houseNumber",
      type: "text",
    },
    {
      title: "stairway",
      value: "",
      model: "stairWay",
      type: "text",
    },
    {
      title: "floor",
      value: "",
      model: "floor",
      type: "text",
    },
    {
      title: "door",
      value: "",
      model: "door",
      type: "text",
    },
  ],
  contactData: [
    {
      title: "name",
      value: "",
      model: "contactName",
      type: "text",
    },
    {
      title: "contactPhone",
      value: "",
      model: "contactPhone",
      type: "text",
    },
    {
      title: "email",
      value: "",
      model: "contactEmail",
      type: "text",
      validationMethod: (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue,
        inputError
      ) => {
        var pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        //If the inputString is NOT a match
        if (!pattern.test(newValue)) {
          inputError.value = "message.emailValidationFail";
        } else{
          inputError.value = ""
        }
      },
    },
    {
      title: "rank",
      value: "",
      model: "contactRank",
      type: "text",
    },
    {
      title: "note",
      value: "",
      model: "contactNote",
      type: "text",
    },
  ],
  settlementData: [
    {
      title: "postal",
      value: "",
      model: "postalCode",
      type: "text",
    },
    {
      title: "name",
      value: "",
      model: "name",
      type: "text",
    },
    {
      title: "county",
      value: "",
      model: "county",
      type: "text",
    },
  ],
};
