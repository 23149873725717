<template>
  <div id="PropertyPage" class="h-screen">
    <BaseLayout>
      <template #mainContent>
        <div class="p4 mt-4 rounded-xl px-8 w-full">
          <!--Header-->
          <header class="w-full mt-4 shadow-sm body-font cardBg">
            <div class="items-start px-6 pb-6 mx-auto md:flex-row">
              <div class="flex justify-between">
                <p
                  class="flex mb-4 text-xl text-secondary md:mb-0 uppercase font-bold"
                >
                  {{ $t("message.property") }} -
                  {{
                    state.property
                      ? state.property.primaryAddressPostalCode + '. ' + state.property.primaryAddressCity + ', ' + state.property.primaryAddressPublicAreaName + ' ' + state.property.primaryAddressPublicAreaValue + ' ' + state.property.primaryAddressHouseNumber + '.'
                      : ""
                  }}
                </p>
                <RemUpButton remType="property" />
              </div>
            </div>
          </header>

          <div class="flex md:flex-row md:columns-2 flex-col w-full">
            <!--SIDEMENU-->
            <div
              class="flex flex-row md:flex-col im-sidemenu-contanier rounded-xl mb-4 md:mb-0 flex-wrap md:flex-nowrap w-full md:md:w-auto justify-center"
            >
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a
                    @click="
                      () => {
                        state.tab = 1;
                      }
                    "
                    class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 1 }"
                  >
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="building3_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.buildings")
                    }}</span>
                  </a>
                </label>
              </div>

              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a
                    @click="
                      () => {
                        state.tab = 2;
                      }
                    "
                    class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 2 }"
                  >
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="address_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.addresses")
                    }}</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a
                    @click="
                      () => {
                        state.tab = 3;
                      }
                    "
                    class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 3 }"
                  >
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="partner_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.partners")
                    }}</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a
                    @click="
                      () => {
                        state.tab = 4;
                      }
                    "
                    class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 4 }"
                  >
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="document_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.documents")
                    }}</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a
                    @click="
                      () => {
                        state.tab = 5;
                      }
                    "
                    class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 5 }"
                  >
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="data_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.baseDatas")
                    }}</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a
                    @click="
                      () => {
                        state.tab = 6;
                      }
                    "
                    class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 6 }"
                  >
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="legal_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.legalSituations")
                    }}</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a
                    @click="
                      () => {
                        state.tab = 7;
                      }
                    "
                    class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 7 }"
                  >
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="system_settings_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.utilizations")
                    }}</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a
                    @click="
                      () => {
                        state.tab = 9;
                      }
                    "
                    class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 9 }"
                  >
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="obli_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.obligations")
                    }}</span>
                  </a>
                </label>
              </div>
            </div>

            <TransitionGroup name="fade">
              <!--BUILDINGS-->
              <div
                v-if="state.tab == 1"
                class="flex flex-col im-main-card rounded-xl im-main-card-starter">
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.buildings") }}
                  </h2>
                  <button
                    @click="
                      state.tab = 13;
                      state.buildingId = 0;
                      clearData(state.buildingBasicData);
                      clearData(state.buildingData);
                    "
                    class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8"
                  >
                    {{ $t("message.addNew") }}
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid
                      ref="buildingsGrid"
                      :config="buildingsGridConfigComputed"
                    />
                  </div>
                  <input
                    type="checkbox"
                    class="modal-toggle"
                    v-model="state.showRemoveConfirmation"
                  />
                  <div class="modal">
                    <div class="modal-box">
                      <h3
                        class="text-lg text-left font-normal hover:text-gray-100 text-gray-100"
                      >
                        {{ $t("message.buildingDeleteConfirm") }}
                      </h3>
                      <div class="modal-action">
                        <label
                          class="btn btn-sm text-white font-light text-xs"
                          @click="
                            () => {
                              state.showRemoveConfirmation = false;
                            }
                          "
                          >{{ $t("message.deny") }}</label
                        >
                        <label
                          class="btn btn-sm bg-magenta text-white font-light text-xs"
                          @click="
                            () => {
                              deleteBuilding(state.buildingId);
                              state.showRemoveConfirmation = false;
                            }
                          "
                          >{{ $t("message.confirm") }}</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--ADD/EDIT BUILDING-->
              <div
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                v-if="state.tab == 13"
              >
                <div>
                  <button
                    class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="state.tab = 1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                      />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ $t("message.building") + ' ' + state.buildingId }}
                  </h2>
                  <div
                      v-for="item in state.buildingBasicData"
                      v-bind:key="item.model"
                      class="mb-8 mt-4"
                    >
                      <ImFormInput
                        v-model="item.value"
                        :title="$t('message.' + item.title)"
                        :type="item.type"
                        :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue"
                        :endPoint="item.endPoint"
                        :displayValue="item.displayValue"
                        :searchable="item.searchable"
                        :optionSearchEndpoint="item.optionSearchEndpoint"
                        :onchangeMethod="item.onchangeMethod"
                        :helperText="item.helperText ? $t('message.' + item.helperText) : null"
                      >
                      </ImFormInput>
                    </div>
                  <div class="im-card-form">
                    <div
                      v-for="item in state.buildingData"
                      v-bind:key="item.model"
                      class="mb-8"
                    >
                      <ImFormInput
                        v-model="item.value"
                        :title="$t('message.' + item.title)"
                        :type="item.type"
                        :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue"
                        :endPoint="item.endPoint"
                        :displayValue="item.displayValue"
                        :searchable="item.searchable"
                        :optionSearchEndpoint="item.optionSearchEndpoint"
                        :onchangeMethod="item.onchangeMethod"
                      >
                      </ImFormInput>
                    </div>

                    <button
                      @click="saveBuilding"
                      class="btn btn-md btn-secondary normal-case text-sm im-round-btn"
                    >
                      {{ $t("message.save") }}
                    </button>
                  </div>
                </div>
              </div>
              <!--ADDRESSES-->
              <div
                v-if="state.tab == 2"
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
              >
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.addresses") }}
                  </h2>
                  <button
                    @click="
                      state.tab = 12;
                      state.addressId = 0;
                      clearData(state.addressData);
                    "
                    class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8"
                  >
                    {{ $t("message.addNew") }}
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid
                      ref="addressesGrid"
                      :config="addressesGridConfigComputed"
                    />
                    <input
                      type="checkbox"
                      class="modal-toggle"
                      v-model="state.showRemoveConfirmation"
                    />
                    <div class="modal">
                      <div class="modal-box">
                        <h3
                          class="text-lg text-left font-normal hover:text-gray-100 text-gray-100"
                        >
                          {{ $t("message.deleteAddressConfirm") }}
                        </h3>
                        <div class="modal-action">
                          <label
                            class="btn btn-sm text-white font-light text-xs"
                            @click="
                              () => {
                                state.showRemoveConfirmation = false;
                              }
                            "
                            >{{ $t("message.deny") }}</label
                          >
                          <label
                            class="btn btn-sm bg-magenta text-white font-light text-xs"
                            @click="
                              () => {
                                deleteAddress(state.addressId);
                                state.showRemoveConfirmation = false;
                              }
                            "
                            >{{ $t("message.confirm") }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--ADD/EDIT ADDRESSES-->
              <div
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                v-if="state.tab == 12"
              >
                <div>
                  <button
                    class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="state.tab = 2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                      />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ $t("message.address") }}
                  </h2>
                  <div class="im-card-form">
                    <div
                      v-for="item in state.addressData"
                      v-bind:key="item.model"
                      class="mb-8"
                    >
                      <ImFormInput
                        v-model="item.value"
                        :title="$t('message.' + item.title)"
                        :type="item.type"
                        :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue"
                        :endPoint="item.endPoint"
                        :displayValue="item.displayValue"
                        :searchable="item.searchable"
                        :onchangeMethod="item.onchangeMethod"
                        :validationMethod="item.validationMethod"
                        :manualOptions="item.manualOptions"
                      >
                      </ImFormInput>
                    </div>

                    <button
                      @click="saveSecondaryAddress"
                      class="btn btn-md btn-secondary normal-case text-sm im-round-btn"
                    >
                      {{ $t("message.save") }}
                    </button>
                  </div>
                </div>
              </div>
              <!--UTILIZATIONS-->
              <div
                v-if="state.tab == 7"
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
              >
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.utilizations") }}
                  </h2>
                  <button
                    @click="
                      state.tab = 11;
                      state.utilizationId = 0;
                      clearData(state.utilizationData);
                    "
                    class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8"
                  >
                    {{ $t("message.addNew") }}
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid
                      ref="utilizationsGrid"
                      :config="utilizationsGridConfigComputed"
                    />
                    <input
                      type="checkbox"
                      class="modal-toggle"
                      v-model="state.showRemoveConfirmation"
                    />
                    <div class="modal">
                      <div class="modal-box">
                        <h3
                          class="text-lg text-left font-normal hover:text-gray-100 text-gray-100"
                        >
                          {{ $t("message.deleteUtilizationConfirm") }}
                        </h3>
                        <div class="modal-action">
                          <label
                            class="btn btn-sm text-white font-light text-xs"
                            @click="
                              () => {
                                state.showRemoveConfirmation = false;
                              }
                            "
                            >{{ $t("message.deny") }}</label
                          >
                          <label
                            class="btn btn-sm bg-magenta text-white font-light text-xs"
                            @click="
                              () => {
                                deleteUtilization(state.utilizationId);
                                state.showRemoveConfirmation = false;
                              }
                            "
                            >{{ $t("message.confirm") }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--ADD/EDIT UTILIZATION-->
              <div
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                v-if="state.tab == 11"
              >
                <div>
                  <button
                    class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="state.tab = 7"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                      />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ $t("message.utilization") }}
                  </h2>
                  <div class="im-card-form">
                    <div
                      v-for="item in state.utilizationData"
                      v-bind:key="item.model"
                      class="mb-8"
                    >
                      <ImFormInput
                        v-model="item.value"
                        :title="$t('message.' + item.title)"
                        :type="item.type"
                        :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue"
                        :endPoint="item.endPoint"
                        :displayValue="item.displayValue"
                        :searchable="item.searchable"
                      >
                      </ImFormInput>
                    </div>

                    <button
                      @click="saveUtilization"
                      class="btn btn-md btn-secondary normal-case text-sm im-round-btn"
                    >
                      {{ $t("message.save") }}
                    </button>
                  </div>
                </div>
              </div>
              <!--LEGAL SITUATIONS-->
              <div
                v-if="state.tab == 6"
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
              >
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.legalSituations") }}
                  </h2>
                  <button
                    @click="
                      state.tab = 8;
                      state.legalsitId = 0;
                      clearData(state.legalsitData);
                    "
                    class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8"
                  >
                    {{ $t("message.addNew") }}
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid
                      ref="legalSituationsGrid"
                      :config="legalSituationsGridConfigComputed"
                    />
                    <input
                      type="checkbox"
                      class="modal-toggle"
                      v-model="state.showRemoveConfirmation"
                    />
                    <div class="modal">
                      <div class="modal-box">
                        <h3
                          class="text-lg text-left font-normal hover:text-gray-100 text-gray-100"
                        >
                          {{ $t("message.deleteLegalSituationConfirm") }}
                        </h3>
                        <div class="modal-action">
                          <label
                            class="btn btn-sm text-white font-light text-xs"
                            @click="
                              () => {
                                state.showRemoveConfirmation = false;
                              }
                            "
                            >{{ $t("message.deny") }}</label
                          >
                          <label
                            class="btn btn-sm bg-magenta text-white font-light text-xs"
                            @click="
                              () => {
                                deleteLegalSituation(state.legalsitId);
                                state.showRemoveConfirmation = false;
                              }
                            "
                            >{{ $t("message.confirm") }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--ADD/EDIT LEGAL SITUATION-->
              <div
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                v-if="state.tab == 8"
              >
                <div>
                  <button
                    class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="state.tab = 6"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                      />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ $t("message.legalSituation") }}
                  </h2>
                  <div class="im-card-form">
                    <div
                      v-for="item in state.legalsitData"
                      v-bind:key="item.model"
                      class="mb-8"
                    >
                      <ImFormInput
                        v-model="item.value"
                        :title="$t('message.' + item.title)"
                        :type="item.type"
                        :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue"
                        :endPoint="item.endPoint"
                        :displayValue="item.displayValue"
                        :searchable="item.searchable"
                      >
                      </ImFormInput>
                    </div>

                    <button
                      @click="saveLegalsit"
                      class="btn btn-md btn-secondary normal-case text-sm im-round-btn"
                    >
                      {{ $t("message.save") }}
                    </button>
                  </div>
                </div>
              </div>
              <!--BASEDATA-->
              <div
                v-if="state.tab == 5"
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
              >
                <PropertyLists title="baseData" :property="state.property" />
              </div>
              <!--Dokumentumok-->
              <div
                v-if="state.tab == 4"
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
              >
                <PropertyLists title="documents" :property="state.property" />
              </div>
              <!--PARTNERS-->
              <div
                v-if="state.tab == 3"
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
              >
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.partners") }}
                  </h2>
                  <button
                    @click="
                      state.tab = 14;
                      state.partnerId = 0;
                      clearData(state.partnerData);
                    "
                    class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8"
                  >
                    {{ $t("message.addNew") }}
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid
                      ref="partnersGrid"
                      :config="partnersGridConfigComputed"
                    />
                    <input
                      type="checkbox"
                      class="modal-toggle"
                      v-model="state.showRemoveConfirmation"
                    />
                    <div class="modal">
                      <div class="modal-box">
                        <h3
                          class="text-lg text-left font-normal hover:text-gray-100 text-gray-100"
                        >
                          {{ $t("message.confirmRemovePartner") }}
                        </h3>
                        <div class="modal-action">
                          <label
                            class="btn btn-sm text-white font-light text-xs"
                            @click="
                              () => {
                                state.showRemoveConfirmation = false;
                              }
                            "
                            >{{ $t("message.deny") }}</label
                          >
                          <label
                            class="btn btn-sm bg-magenta text-white font-light text-xs"
                            @click="
                              () => {
                                deletePartner(state.partnerId);
                                state.showRemoveConfirmation = false;
                              }
                            "
                            >{{ $t("message.confirm") }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--ADD/EDIT PARTNER-->
              <div
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                v-if="state.tab == 14"
              >
                <div>
                  <button
                    class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="state.tab = 1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                      />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ state.partnerData[0].value }}
                  </h2>
                  <div class="im-card-form">
                    <div
                      v-for="item in state.partnerData"
                      v-bind:key="item.model"
                      class="mb-8"
                    >
                      <div
                        v-if="
                          state.partnerEdit == false && state.partnerId != 0
                        "
                      >
                        <ImFormLabel
                          v-if="
                            (item.model != 'taxId' &&
                              item.model != 'taxNumber') ||
                            (item.model == 'taxId' && state.isPerson == true) ||
                            (item.model == 'taxNumber' &&
                              state.isPerson == false)
                          "
                          :item="item"
                        ></ImFormLabel>
                      </div>
                      <div
                        v-if="state.partnerEdit == true || state.partnerId == 0"
                      >
                        <ImFormInput
                          v-if="
                            (item.model != 'taxId' &&
                              item.model != 'taxNumber') ||
                            (item.model == 'taxId' && state.isPerson == true) ||
                            (item.model == 'taxNumber' &&
                              state.isPerson == false)
                          "
                          v-model="item.value"
                          :title="$t('message.' + item.title)"
                          :type="item.type"
                          :valueLabel="item.valueLabel"
                          :optionValue="item.optionValue"
                          :endPoint="item.endPoint"
                          :displayValue="item.displayValue"
                          :searchable="item.searchable"
                          :onchangeMethod="item.onchangeMethod"
                          :validationMethod="item.validationMethod"
                        >
                        </ImFormInput>
                      </div>
                    </div>

                    <button
                      v-if="state.partnerEdit == true || state.partnerId == 0"
                      @click="savePartner"
                      class="btn btn-md btn-secondary normal-case text-sm im-round-btn"
                    >
                      {{ $t("message.save") }}
                    </button>
                    <button
                      v-if="state.partnerEdit == false && state.partnerId != 0"
                      @click="state.partnerEdit = true"
                      class="btn btn-md btn-secondary normal-case text-sm im-round-btn"
                    >
                      {{ $t("message.edit") }}
                    </button>
                  </div>
                </div>
              </div>
              <!--OBLIGATIONS-->
              <div
                v-if="state.tab == 9"
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
              >
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.obligations") }}
                  </h2>
                  <button
                    @click="
                      state.tab = 10;
                      state.obligationId = 0;
                      clearData(state.obligationData);
                    "
                    class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8"
                  >
                    {{ $t("message.addNew") }}
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid
                      ref="obligationsGrid"
                      :config="obligationsGridConfigComputed"
                    />
                    <input
                      type="checkbox"
                      class="modal-toggle"
                      v-model="state.showRemoveConfirmation"
                    />
                    <div class="modal">
                      <div class="modal-box">
                        <h3
                          class="text-lg text-left font-normal hover:text-gray-100 text-gray-100"
                        >
                          {{ $t("message.deleteObligationConfirm") }}
                        </h3>
                        <div class="modal-action">
                          <label
                            class="btn btn-sm text-white font-light text-xs"
                            @click="
                              () => {
                                state.showRemoveConfirmation = false;
                              }
                            "
                            >{{ $t("message.deny") }}</label
                          >
                          <label
                            class="btn btn-sm bg-magenta text-white font-light text-xs"
                            @click="
                              () => {
                                deleteObligation(state.obligationId);
                                state.showRemoveConfirmation = false;
                              }
                            "
                            >{{ $t("message.confirm") }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--ADD/EDIT OBLIGATION-->
              <div
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                v-if="state.tab == 10"
              >
                <div>
                  <button
                    class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="state.tab = 9"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                      />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ $t("message.obligation") }}
                  </h2>
                  <div class="im-card-form">
                    <div
                      v-for="item in state.obligationData"
                      v-bind:key="item.model"
                      class="mb-8"
                    >
                      <ImFormInput
                        v-model="item.value"
                        :title="$t('message.' + item.title)"
                        :type="item.type"
                        :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue"
                        :endPoint="item.endPoint"
                        :displayValue="item.displayValue"
                        :searchable="item.searchable"
                      >
                      </ImFormInput>
                    </div>

                    <button
                      @click="saveObligation"
                      class="btn btn-md btn-secondary normal-case text-sm im-round-btn"
                    >
                      {{ $t("message.save") }}
                    </button>
                  </div>
                </div>
              </div>
            </TransitionGroup>
          </div>
        </div>
      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
import BaseLayout from "../../layouts/BaseLayout.vue";
import PropertyLists from "../../components/REM/RemLists.vue";
import { onMounted, reactive, computed } from "vue";
import Utils from "../../misc/Utils";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import RemUpButton from "../../components/REM/RemUpButton.vue";
import SVGRenderer from "../../components/SVGRenderer.vue";
import formdata from "./FormData";
import legalSituationsGridConfig from "../../misc/gridConfigs/propertyPage/legalSituationsGridConfig";
import ImFormInput from "../../components/ImFormInput.vue";
import ImFormLabel from "../../components/ImFormLabel.vue";
import RobberVueGrid from "../../components/RVG/RobberVueGrid.vue";
import propertyUtils from "./propUtils";
import obligationsGridConfig from "../../misc/gridConfigs/propertyPage/propObligationsGridConfig";
import utilizationsGridConfig from "../../misc/gridConfigs/propertyPage/utilizationsGridConfig";
import addressesGridConfig from "../../misc/gridConfigs/propertyPage/addressesGridConfig";
import buildingsGridConfig from "../../misc/gridConfigs/propertyPage/buildingsGridConfig";
import partnersGridConfig from "../../misc/gridConfigs/propertyPage/partnersGridConfig";

const state = reactive({
  tab: 1,
  property: null,
  legalsitData: formdata.legalsitData,
  legalsitId: 0,
  obligationData: formdata.obligationData,
  obligationId: 0,
  utilizationData: formdata.utilizationData,
  utilizationId: 0,
  addressData: formdata.addressData,
  addressId: 0,
  buildingData: formdata.buildingData,
  buildingBasicData: formdata.buildingBasicData,
  buildingId: 0,
  partnerData: formdata.partnerData,
  partnerId: 0,
  isPerson: false,
  partnerEdit: false,
});

const { t } = useI18n();
const router = useRoute();
const propertyId = router.params.propertyId;

//PARTNERS
const partnersGridConfigComputed = computed(() => {
  return partnersGridConfig(state, t, setRemoveConfirmation);
});

const savePartner = () => {
  propertyUtils.savePartner(state, t);
};

const deletePartner = () => {
  propertyUtils.deletePartner(state.partnerId, t);
};

//BUILDINGS
const buildingsGridConfigComputed = computed(() => {
  return buildingsGridConfig(
    state,
    t,
    propertyId,
    setRemoveConfirmation,
    propertyUtils.toBuilding
  );
});

const saveBuilding = () => {
  propertyUtils.saveBuilding(state, t, propertyId);
};

const deleteBuilding = () => {
  propertyUtils.deleteBuilding(state.buildingId, t);
};

//ADDRESSES
const addressesGridConfigComputed = computed(() => {
  return addressesGridConfig(state, t, propertyId, setRemoveConfirmation);
});

const saveSecondaryAddress = () => {
  propertyUtils.saveSecondaryAddress(state, propertyId, t);
};

const deleteAddress = () => {
  propertyUtils.deleteAddress(state.addressId, t);
};

//UTILIZATIONS
const utilizationsGridConfigComputed = computed(() => {
  return utilizationsGridConfig(state, t, propertyId, setRemoveConfirmation);
});

const saveUtilization = () => {
  propertyUtils.saveUtilization(state, propertyId, t);
};

const deleteUtilization = () => {
  propertyUtils.deleteUtilization(state.utilizationId, t);
};

//OBLIGATIONS
const obligationsGridConfigComputed = computed(() => {
  return obligationsGridConfig(state, t, propertyId, setRemoveConfirmation);
});

const saveObligation = () => {
  propertyUtils.saveObligation(state, t, propertyId);
};

const deleteObligation = () => {
  propertyUtils.deleteObligation(state.obligationId, t);
};

//LEGAL SITUATIONS
const legalSituationsGridConfigComputed = computed(() => {
  return legalSituationsGridConfig(state, t, propertyId, setRemoveConfirmation);
});

const saveLegalsit = () => {
  propertyUtils.saveLegalsit(state, t, propertyId);
};

const deleteLegalSituation = () => {
  propertyUtils.deleteLegalSituation(state.legalsitId, t);
};

const setRemoveConfirmation = (id) => {
  if (state.tab == 6) {
    state.legalsitId = id;
  }
  if (state.tab == 7) {
    state.utilizationId = id;
  }
  if (state.tab == 8) {
    state.obligationId = id;
  }
  if (state.tab == 2) {
    state.addressId = id;
  }
  if (state.tab == 1) {
    state.buildingId = id;
  }
  if (state.tab == 3) {
    state.partnerId = id;
  }
  state.showRemoveConfirmation = true;
};

const getProperty = async () => {
  const response = await Utils.fetchPropertyById(propertyId);
  state.property = response;
};

function clearData(vArray) {
  vArray.forEach((element) => {
    element.value = "";
  });
}

onMounted(() => {
  getProperty();
  propertyUtils.setup(state)
});
</script>

<style scoped>
.cardBg {
  background-color: hsl(220, 17.647%, 20%);
}

.im-main-card {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  padding: 3rem;
  width: 100%;
  max-width: 100%;
  max-height: 75vh;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .im-main-card {
    margin-bottom: 30px;
  }
}

.im-main-card-starter {
  animation: 0.5s ease-out 0s 1 scaleupY;
  overflow: auto;
}

.im-main-card-starter div {
  animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
  0% {
    transform-origin: left top;
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-sidemenu-contanier {
  animation: 0.5s ease-out 0s 1 scaleupY;
  padding: 1rem;

  margin-right: 2rem;
  height: fit-content;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

.im-sidemenu-contanier div {
  animation: 1.5s ease-out 0s 1 fadein;
}

.im-card-form {
  padding-top: 2rem;
}

.fade-enter-active {
  transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
  transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  padding: 0;
  width: 0;
  opacity: 0;
}

.im-round-btn {
  border-radius: 9999px;
}

/* button */

.btn-circle {
  transition: width 0.5s;
}

.im-sidemenu-contanier:hover .btn-circle {
  width: 15rem;
}

@keyframes fadeinbtn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-btn-text {
  display: none;
}

.im-sidemenu-contanier:hover .im-btn-text {
  animation: 0.5s ease-out 0s 1 fadeinbtn;
  display: inline;
}

.im-btn-icon {
  display: inline;
}
.im-button-active {
  border-color: lighter hsl(var(--s));
}

.im-sidemenu-contanier:hover .im-btn-icon {
  display: none;
}
</style>
