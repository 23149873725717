import axios from '@/misc/axios.js';
import store from '../misc/vuex-store.js'
import TenantConfigHandler from '@/misc/TenantConfigHandler';

export default {
  async fetchDocumentById(id){
    const uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/document/${id}`;
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.fetchDocumentById(id);
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchPremiseById(id){
    const uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/premise/${id}`;
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.fetchPremiseById(id);
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchMeterById(id){
    const uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/meter/${id}`;
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.fetchMeterById(id);
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchUtilizationById(id){
    const uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/utilization/${id}`;
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.fetchUtilizationById(id);
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchContactById(id){
    const uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/partner/contact/${id}`;
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.fetchContactById(id);
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchLegalSituationById(id){
    const uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/legal-situation/${id}`;
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.fetchLegalSituationById(id);
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchBuildingById(id) {
    const uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/building/${id}`;
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.fetchBuildingById(id);
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchPropertyById(id) {
    const uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/property/${id}`;
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.fetchPropertyById(id);
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchPartners() {
    const uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner";
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.fetchPartners();
        },
      });
      return response.data.content;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
  async fetchPartnerById(id) {
    const uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/partner/${id}`;
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.fetchPartnerById(id);
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  },
  async storeAppConfig() {
    await axios.get(TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/appconfig', {
      onTokenRefreshed: () => {
        this.storeAppConfig();
      }
    })
    .then((response) => {
      store.commit('setAppConfig', response.data)
    })
    .catch((e) => {
      console.log(e)
    })
  },
  async storeSiteDesigns() {
    try {
      if(!store.getters.getSiteDesigns) {
        const siteDesigns = await axios.get(TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/site-design/', {
          onTokenRefreshed: () => {
            this.storeSiteDesigns();
          }
        });
        store.commit("setSiteDesigns", siteDesigns.data);
      }

      return true;
    } catch (err) {
      console.log("ERR", err);
      return false;
    }
  },
  getSiteDesignById(siteDesignId) {
    const siteDesigns = store.getters.getSiteDesigns;

    if (!siteDesigns) {
      return null;
    }

    var result = null;
    siteDesigns.forEach((siteDesign) => {
      if (siteDesign.id == siteDesignId) {
        result = siteDesign;
      }
    });

    return result;
  },
  parseContentDispositionToFileName (disposition) {
    const filenameField = disposition
          .split(';')
          .map((x) => x.trim())
          .filter((x) => x.startsWith('filename'))
          .sort()?.[0];
      const rightPart = filenameField?.split('=')?.at(-1).replaceAll('"', '');
      if (filenameField?.startsWith('filename=')) {
          return rightPart;
      } else if (filenameField?.startsWith('filename*=')) {
          return decodeURIComponent(rightPart?.split("'")?.at(-1));
      }
      return "default";
  },
  roundToNearest15(date = new Date()) {
    const minutes = 15;
    const ms = 1000 * 60 * minutes;
    return new Date(Math.floor(date.getTime() / ms) * ms);
  },
  roundToNearest60(date = new Date()) {
    const minutes = 60;
    const ms = 1000 * 60 * minutes;
    return new Date(Math.floor(date.getTime() / ms) * ms);
  },
  roundToNearest1440(date = new Date()) {
    const minutes = 1440;
    const ms = 1000 * 60 * minutes;
    return new Date(Math.floor(date.getTime() / ms) * ms);
  },
  isModuleEnabled(moduleName) {
    return  store.getters.isModuleEnabled(moduleName);
  },
  getPolyFillOpacityById(
  //  minMaxCount, polyCount
  ) {
    var colors = {
      lineColor: process.env.VUE_APP_POLYGON_LINE_COLOR,
      fillColor: process.env.VUE_APP_POLYGON_FILL_COLOR,
      inactiveLineColor: process.env.VUE_APP_INACTIVE_POLYGON_LINE_COLOR,
      inactiveFillColor: process.env.VUE_APP_INACTIVE_POLYGON_FILL_COLOR,
    };
    return colors.fill;

    // if(!isNaN(polyCount)) {
    //   return {
    //     fillOpacity: 0.1+((polyCount-minMaxCount.min)*((minMaxCount.max-minMaxCount.min)/100)/100*0.8),
    //     color: colors.fillColor,
    //     lineColor: colors.lineColor
    //   };
    // }
    // else {
    //   return {
    //     fillOpacity: 0.3,
    //     color: colors.inactiveFillColor,
    //     lineColor: colors.inactiveLineColor
    //   };
    // }
  },
  async fetchPolygons(siteDesignId) {
    if (!siteDesignId) return [];

    try {
      const response = await axios.get(TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/site-design/' + siteDesignId + '/zones_and_zone_groups', {
        onTokenRefreshed: () => {
          this.fetchPolygons(siteDesignId);
        }
      });
      return response.data;
    } catch (err) {
      console.log(err);
      return [];
    }
  },
  async fetchZones(siteDesignId) {
    return await this.fetchPolygons(siteDesignId);
  },
  transformPointsToMap(y, x, mapDimensionsX, mapDimensionsY, mapGeometry) {
    var xRatio = mapDimensionsX / mapGeometry.width;
    var yRatio = mapDimensionsY / mapGeometry.length;

    x = x * xRatio;
    y = y * yRatio;

    return [y, x];
  },
  transformPointsToServer(y, x, mapDimensionsX, mapDimensionsY, mapGeometry) {
    var xRatio = mapGeometry.width / mapDimensionsX;
    var yRatio = mapGeometry.length / mapDimensionsY;
    return [yRatio * y, xRatio * x];
  },
  showToast(type, message) {
    if (this.toastInterval) {
      window.clearTimeout(this.toastInterval);
    }

    this.toast = {
      type: type,
      message: message,
    };

    var vm = this;
    vm.toastInterval = window.setTimeout(() => {
      vm.toast = null;
    }, 3000);
  },
  getGistsByTypeAndId(type, id, callback) {
    let url = '';
    if(type == 'zone') {
      url = TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/zone/' + id + '/gists';
    } 

    if(type == 'site-design') {
      url = TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/site-design/' + id + '/gists';
    }

    if(type == 'zone-group') {
      url = TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/zone_group/' + id + '/gists';
    }

    if(type == 'system') {
      url = TenantConfigHandler.getVariable('ADMINV2_API_URL') + '/system/gists';
    }

    axios.get(url, {
      onTokenRefreshed: () => {
        this.getGistsByTypeAndId(type, id, callback)
      }
    }).then((res) => {
      if(callback) {
        callback(res);
      }
    })
    .catch((err) => {
      console.log(err)
    })
  },
  getAggregationsByTypeAndId(type, id, callback) { 
    let url = '';
    if(type == 'zone') {
      url = TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/zone/' + id + '/aggregations';
    } 

    if(type == 'site-design') {
      url = TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/site-design/' + id + '/aggregations';
    }

    if(type == 'zone-group') {
      url = TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/zone_group/' + id + '/aggregations';
    }

    axios.get(url, {
      onTokenRefreshed: () => {
        this.getAggregationsByTypeAndId(type, id, callback)
      }
    }).then((res) => {
      if(callback) {
        callback(res);
      }
    })
    .catch((err) => {
      console.log(err)
    })
  }
}
