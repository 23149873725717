import axios from "@/misc/axios.js";
import toast from "@/misc/toast.js";

import TenantConfigHandler from '@/misc/TenantConfigHandler';
import { watch } from "vue";

export default {
  getMainEnergetics: async function (state, t) {
    axios
      .get(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          `/rem/building/${state.value.buildingId}/energetics`,
        {
          onTokenRefreshed: () => {
            this.getMainEnergetics(state);
          },
        }
      )
      .then((resp) => {

        state.value.energeticsId = resp.data.energeticsId;
        for (const [key /*, value*/ ] of Object.entries(state.value.basicData)) {
           
            
            state.value.basicData[key].value = resp.data[key] == true ? true : false;
          watch(
            () => state.value.basicData[key].value,
            () => {
              this.saveMainEnergetics(state, t);
            }
          );
        }

   
        
      });
  },
  saveMainEnergetics: async function (state, t) {
    const hermes = {};

    for (const [key, value] of Object.entries(state.value.basicData)) {
      hermes[key] = value.value;
    }

    axios
      .patch(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          `/rem/building/${state.value.buildingId}/energetics`,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveMainEnergetics(state);
          },
        }
      )
      .then((resp) => {
     
        
        if (resp.status != 200) {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
  },
  saveUniversal: async function (state, formdata, endpoint, tab, t) {

    const hermes = {};

    state.value[formdata ].forEach((e) => {
      hermes[e.model] = e.value;
    });
    

    if(state.value[formdata + "Id"] != 0){
        console.log(hermes);
        
      await axios.patch(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          `/rem/building/energetics/${endpoint}/${state.value[formdata + "Id"]}`,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveContact(state, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 200) {
            toast.success(t("message.successfulSave"));
            state.value[formdata].forEach((e) => {
              e.value = "";
            });

            state.value.tab = tab;
            state.value[formdata + "Id"] = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }else{
      await axios.post(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/building/${state.value.buildingId}/energetics/${endpoint}`,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveContact(state, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 201) {
            state.value[formdata ].forEach((e) => {
              e.value = "";
            });
            toast.success(t("message.successfulSave"));
            state.value.tab = tab;
            state.value[formdata + "Id"] = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },
};
