import Axios from "@/misc/axios";
import toast from "@/misc/toast.js";
//import { watchEffect } from "vue";

import TenantConfigHandler from "@/misc/TenantConfigHandler";

export default {
  // setup scripts

  setup: (/*state*/) => {
    /*watchEffect(() => {
      if (state.value.partnerData[1].value) {
        setTimeout(() => {
          state.value.partnerData[2].value =
            "CHANGECODE###" + state.value.partnerData[1].value;
        }, 700);

      }

      if (state.value.partnerData[3].value) {
        //console.log("isperson");

        setTimeout(() => {
          state.value.isPerson =
            state.value.partnerData[3].value == "true" ? true : false;
          if (state.value.isPerson == true) {
            state.value.partnerData[5].value = "";
          } else {
            state.value.partnerData[4].value = "";
          }
        }, 700);
      }

      if (state.value.addressData[1].value) {
        //console.log("postal");
        setTimeout(() => {
          state.value.addressData[3].value =
            "CHANGECODE###" + state.value.addressData[1].value;
          state.value.addressData[4].value =
            "CHANGECODE###" + state.value.addressData[1].value;
        }, 700);
      }
    });*/
  },
  getOptions: async function (
    source,
    target,
    schema,
    isThereWeirdFormattingForOption
  ) {
    schema.forEach((e) => {
      if (e.type == "label") {
        target.push(e);
        return;
      }

      const hermes = e;
      if (hermes.type == "option" && isThereWeirdFormattingForOption == true) {
        if (source[e.model + "Id"]) {
          hermes.value = source[e.model + "Id"];
          hermes.valueLabel = source[e.model + "Value"];
        }
      } else {
        hermes.value = source[e.model];
      }
      target.push(hermes);
    });
  },

  //savers

  savePartner: async function (state, t) {
    const hermes = {
      partnerMainType: 0,
      partnerSubType: 0,
      name: "",
      taxId: "",
      taxNumber: "",
      partnerAddresses: [],
    };

    state.value.partnerData.forEach((e) => {
      if (e.model != "") {
        hermes[e.model] = e.value;
      }
    });

    if (state.value.partnerId != 0) {
      hermes.id = state.value.partnerId;
    }

    this.patchPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/partner/" +
        state.value.partnerId,
      hermes,
      "partner",
      1,
      state,
      t
    );
  },
  saveAddress: async function (state, t) {
    if (state.value.partnerId == 0) return;

    const hermes = {};

    (hermes.addressType = "POSTAL"),
      state.value.addressData.forEach((e) => {
        hermes[e.model] = e.value;
      });
    hermes.partnerId = state.value.partnerId;

    this.patchPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/partner/partner-address",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/partner/partner-address/" +
        state.value.addressId,
      hermes,
      "address",
      3,
      state,
      t
    );
  },
  saveContact: async function (state, t) {
    if (state.value.partnerId == 0) return;

    const hermes = {};
    hermes.partnerId = state.value.partnerId;

    state.value.contactData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    this.patchPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/partner/contact",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/partner/contact/" +
        state.value.contactId,
      hermes,
      "contact",
      5,
      state,
      t
    );
  },
  saveSettlement: async function (state, t) {
    const hermes = {};

    state.value.settlementData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    this.patchPost(
      TenantConfigHandler.getVariable("ADMINV2_API_URL") +
        "/rem/partner/settlement",
      TenantConfigHandler.getVariable("ADMINV2_API_URL") +
        "/rem/partner/settlement/" +
        state.value.settlementId,
      hermes,
      "settlement",
      7,
      state,
      t
    );
  },

  patchPost: async function (
    postendpoint,
    patchendpoint,
    hermes,
    schema,
    tab,
    state,
    t
  ) {
    if (state.value[`${schema}Id`] != 0) {
      try {
        await Axios.patch(patchendpoint, hermes, {
          onTokenRefreshed: () => {
            this.patchPost(
              postendpoint,
              patchendpoint,
              hermes,
              schema,
              tab,
              state,
              t
            );
          },
        }).then((resp) => {
          if (resp && resp.status == 200) {
            toast.success(t("message.successfulSave"));
            state.value[`${schema}Data`].forEach((e) => {
              e.value = "";
            });

            state.value.tab = tab;
            state.value[`${schema}Id`] = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        });
      } catch (e) {
        toast.error(t("message.notSuccessfulSave"));
      }
    } else {
      try {
        await Axios.post(postendpoint, hermes, {
          onTokenRefreshed: () => {
            this.patchPost(
              postendpoint,
              patchendpoint,
              hermes,
              schema,
              tab,
              state,
              t
            );
          },
        }).then((resp) => {
          if (resp && resp.status == 201) {
            state.value[`${schema}Data`].forEach((e) => {
              e.value = "";
            });

            state.value.tab = tab;
            state.value[`${schema}Id`] = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        });
      } catch (e) {
        toast.error(t("message.notSuccessfulSave"));
      }
    }
  },
};
