import Axios from "@/misc/axios.js";
import toast from "@/misc/toast.js";
import TenantConfigHandler from '@/misc/TenantConfigHandler';


let uniqueField = "partnerGrid";



export default (premiseId, state, t, add) => {
  return {
    endpoint:
      add == true
        ? TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/partner`
        : TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          `/rem/premise/${premiseId}/partners`,
    idkey: "id",
    defaultOrderKey: "id",
    defaultOrderDirection: "desc",
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      possiblePageSizes: [10, 20, 50, 100],
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: true,
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: "server", // server or local/null
      serverGridKey: "worksGridColumns",
    },
    xlsxExport: {
      active: true,
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [
        {
          key: "30sec",
          refreshInterval: 30,
          title: t("message.rvg_automatic_list_refresh_30_sec"),
          default: true,
        },
        {
          key: "1min",
          refreshInterval: 60,
          title: t("message.rvg_automatic_list_refresh_1_min"),
        },
        {
          key: "5min",
          refreshInterval: 300,
          title: t("message.rvg_automatic_list_refresh_5_min"),
        },
      ],
    },
    mapping: {
      $operations: {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: true,
        width: "50px",
        formatter: {
          type: "Operations",
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              // edit
              if (add == false) {
                buttons.push({
                  title: "",
                  tooltip: t("message.open"),
                  icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12 3L2 12H5V20H10V14H14V15.11L19.43 9.68L12 3M21.04 11.14C20.9 11.14 20.76 11.2 20.65 11.3L19.65 12.3L21.7 14.35L22.7 13.35C22.91 13.14 22.91 12.79 22.7 12.58L21.42 11.3C21.32 11.2 21.18 11.14 21.04 11.14M19.06 12.88L13 18.94V21H15.06L21.11 14.93L19.06 12.88Z" /></svg>',
                  classList:
                    "btn btn-secondary btn-circle btn-sm hover:!opacity-80",
                  testValueAttribute: "propertyEditButton",
                  dropdowned: false,
                  //disabled: record.endDate ? false : true,
                  onClick: () => {
                    state.value.partnerId = record.id;
                    state.value.partnerData.forEach((e) => {
                      if (e.type == "option") {
                        e.value = record[e.model + "Id"];
                        e.valueLabel = record[e.model + "Value"];
                      } else {
                        e.value = record[e.model];
                      }
                    });
                    state.value.tab = 10;
                  },
                });
              }

              // add to partner
              if (add == true) {
                buttons.push({
                  title: "",
                  tooltip: "Edit property",
                  icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-add" viewBox="0 0 16 16"><path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/><path d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"/></svg>',
                  classList:
                    "btn btn-secondary btn-circle btn-sm hover:!opacity-80",
                  testValueAttribute: "propertyEditButton",
                  dropdowned: false,
                  disabled: state.value.partnerList.includes(record.id),
                  onClick: () => {
                    const hermesAdd = {
                      partnerIds: state.value.partnerList,
                    };
                    
                    hermesAdd.partnerIds.push(record.id);
                    Axios.patch(
                      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
                        "/rem/premise/" +
                        premiseId,
                      hermesAdd,
                      {
                        onTokenRefreshed: () => {
                          this.asyncSavePremise(state, premiseId, t);
                        },
                      }
                    ).then(function (resp) {
                      if (resp) {
                        if (resp.status == 200) {
                          toast.success(t("message.successfulSave"));
                          state.value.tab = 9;
                          state.value.partnerId = 0;
                        } else {
                          toast.error(t("message.notSuccessfulSave"));
                        }
                        //console.log(response);
                      } else {
                        toast.error(t("message.notSuccessfulSave"));
                      }
                    });
                  },
                });
              }

              // remove from partner
              if (add == false) {
                buttons.push({
                  title: "",
                  tooltip: "Edit property",
                  icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/><path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8"/> <svg>',
                  classList:
                    "btn btn-secondary btn-circle btn-sm hover:!opacity-80",
                  testValueAttribute: "propertyEditButton",
                  dropdowned: false,
                  //disabled: state.value.partnerList.includes(record.id),
                  onClick: () => {
                    const hermesAdd = {
                      partnerIds: state.value.partnerList,
                    };
                    
                    hermesAdd.partnerIds = hermesAdd.partnerIds.filter((e) => e != record.id);
                    console
                    Axios.patch(
                      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
                        "/rem/premise/" +
                        premiseId,
                      hermesAdd,
                      {
                        onTokenRefreshed: () => {
                          this.asyncSavePremise(state, premiseId, t);
                        },
                      }
                    ).then(function (resp) {
                      if (resp) {
                        if (resp.status == 200) {
                          toast.success(t("message.successfulSave"));
                          state.value.tab = 9;
                          state.value.partnerId = 0;
                        } else {
                          toast.error(t("message.notSuccessfulSave"));
                        }
                        //console.log(response);
                      } else {
                        toast.error(t("message.notSuccessfulSave"));
                      }
                    });
                  },
                });
              }

              return buttons;
            },
          },
        },
      },
      id: {
        title: "id",
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      name: {
        title: t("message.name"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.name ? record.name : "N/A";
        },
      },
      partnerMainTypeValue: {
        title: t("message.mainType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          if (add == true) {
            return record.partnerMainType.id
              ? record.partnerMainType.value
              : "N/A";
          } else {
            return record.partnerMainTypeId
              ? record.partnerMainTypeValue
              : "N/A";
          }
        },
      },
      partnerSubTypeValue: {
        title: t("message.subType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          if (add == true) {
            return record.partnerSubType
              ? record.partnerSubType.value
              : "N/A";
          } else {
            return record.partnerSubTypeId ? record.partnerSubTypeValue : "N/A";
          }
        },
      },
      
    },
  };
};
